import { Link, styled } from "@mui/material"
import { colors } from "../../utils"

interface IProps {
  disabled?: boolean
  transparent?: boolean
}

export const ClickableLabel = styled(Link)<IProps>`
  text-decoration: underline;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: pointer;
  color: ${colors.black2};
  background-color: ${({ transparent }) =>
    transparent ? "transparent" : colors.white};
`

import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  TableCell,
  Collapse,
  Button,
  Typography,
  Box,
  TextField,
  CircularProgress,
  debounce,
  type TextFieldProps,
} from "@mui/material"
import dayjs from "dayjs"
import {
  CellWrapper,
  CollapsibleRow,
  CustomAutocomplete,
  CustomPaper,
  CustomRow,
  DocPreviewWrapper,
} from "./styled"
import { useTranslation } from "react-i18next"
import {
  AttachEmail,
  CalendarMonthOutlined,
  Delete,
  Email,
  ExpandMore,
  Plagiarism,
  Storage,
  Tune,
} from "@mui/icons-material"
import { colors, determineFileType } from "../../../../../../../../utils"
import {
  deleteGlobalInboxEmailAPI,
  getGlobalInboxAttachmentsAPI,
  getSearchClaimsWithDetailsAPI,
  postAssignCaseAPI,
} from "../../../../../../../../services"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import DocViewer, {
  DocViewerRenderers,
  type IDocument,
} from "@cyntler/react-doc-viewer"
import { EmailBodyAndActions } from "./components"
import { useToast } from "../../../../../../../../contexts"
import { ConfirmActionModal } from "../../../../../../../../components"

interface IProps {
  rowDetails: IGlobalInboxItemDetail
  emailDetails: IGlobalInboxItemEmail
  isExpanded: boolean
  isDisabled: boolean
  isEditingDisabled?: boolean
  onRowClick: () => void
  onRowClose: () => void
}

export const EnhancedTableRow = (props: IProps) => {
  const {
    rowDetails,
    emailDetails,
    isExpanded,
    isDisabled,
    isEditingDisabled,
    onRowClick,
    onRowClose,
  } = props
  const { t } = useTranslation()
  const { attachmentCount } = rowDetails
  const { folderPath, id } = emailDetails
  const toast = useToast()
  const queryClient = useQueryClient()

  const [searchQuery, setSearchQuery] = useState<string>("")
  const [assignCaseOpen, setAssignCaseOpen] = useState<boolean>(false)
  const [selectedCase, setSelectedCase] =
    useState<ISearchClaimsWithDetails | null>(null)
  const [isDeleteEmailModalOpen, setIsDeleteEmailModalOpen] =
    useState<boolean>(false)

  const {
    data: attachments,
    isPending,
    isRefetching,
    refetch: refetchAttachments,
  } = useQuery({
    queryKey: ["global-inbox-attachments"],
    queryFn: () => getGlobalInboxAttachmentsAPI({ folderPath }),
    enabled: false,
  })

  const {
    data: caseSearchResults,
    isLoading: isSearchingCaseResults,
    isRefetching: isRefetchingCaseResults,
    refetch: refetchCaseSearchResults,
  } = useQuery<ISearchClaimsWithDetails[]>({
    queryKey: ["search-assign-case"],
    queryFn: () => getSearchClaimsWithDetailsAPI({ search: searchQuery }),
    enabled: false,
  })

  const { mutate: assignCase, isPending: isPostAssignCasePending } =
    useMutation({
      mutationFn: postAssignCaseAPI,
      onSuccess: () => {
        void queryClient.setQueryData(
          ["global-inbox"],
          (prev: IGetGlobalInboxResponse) => {
            const items = prev?.items?.map((item) => {
              if (item.email.id === id) {
                return {
                  email: {
                    ...item.email,
                    caseId: selectedCase?.az,
                    rubrum: selectedCase?.rubrum,
                    wegen: selectedCase?.wegen,
                  },
                  details: {
                    ...item.details,
                    caseNumber: selectedCase?.az,
                  },
                }
              }
              return item
            })

            return { ...prev, items }
          },
        )
        toast.show(t("caseSuccessfullyAssigned"), "success")
      },
    })

  const { mutate: mutateDeleteEmail, isPending: isEmailDeleting } = useMutation(
    {
      mutationFn: deleteGlobalInboxEmailAPI,
      onSuccess: () => {
        void queryClient.refetchQueries({ queryKey: ["global-inbox"] })

        setIsDeleteEmailModalOpen(false)
        onRowClose()
        toast.show(t("attachmentDeletedSuccessfully"), "success")
      },
    },
  )

  const handleDeleteDocument = useCallback(() => {
    mutateDeleteEmail(id)
  }, [id])

  const handleAttachmentPreviewClick = useCallback(
    (doc: IGlobalInboxAttachment) => {
      const { url, name } = doc
      setDocuments([
        { uri: url, fileName: name, fileType: determineFileType(name) },
      ])
    },
    [],
  )

  const [documents, setDocuments] = React.useState<IDocument[]>([])

  useEffect(() => {
    if (emailDetails.caseId) {
      setSelectedCase({
        az: emailDetails.caseId,
        rubrum: emailDetails.rubrum ?? "",
        wegen: emailDetails.wegen ?? "",
        nr: 0,
      })
    }
  }, [emailDetails.caseId, emailDetails.rubrum, emailDetails.wegen, isExpanded])

  useEffect(() => {
    if (searchQuery) {
      void refetchCaseSearchResults()
    }
  }, [searchQuery])

  useEffect(() => {
    if (isExpanded && attachmentCount > 0) {
      void refetchAttachments()
    }
  }, [isExpanded])

  useEffect(() => {
    if (
      isExpanded &&
      attachments &&
      attachments?.inboxAttachments?.length > 0
    ) {
      handleAttachmentPreviewClick(attachments.inboxAttachments[0])
    } else {
      setDocuments([])
    }
  }, [attachments, isExpanded])

  const handleUpdateDocuments = useCallback((documents: IDocument[]) => {
    setDocuments(documents)
  }, [])

  const handleSelect = (
    _: React.SyntheticEvent,
    value: ISearchClaimsWithDetails | null,
  ) => {
    if (value) {
      setAssignCaseOpen(false)
      if (!emailDetails.folderPath) {
        toast.show(t("errorAssigningCase"), "error")
      }
      if (emailDetails.folderPath) {
        assignCase({
          caseNumber: value.az,
          documentRelationId: emailDetails.folderPath,
        })
      }
    }
  }

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter" && filteredOptions.length === 1) {
      handleSelect(event, filteredOptions[0])
    }
  }

  const handleInputChange = (_: React.SyntheticEvent, value: string) => {
    if (value !== selectedCase?.az) {
      debouncedSearch(value)
    }
    setAssignCaseOpen(!!value)
  }

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearchQuery(query)
    }, 350),
    [],
  )

  const filteredOptions = useMemo(
    () => (caseSearchResults || []).filter((option) => option?.az !== null),
    [caseSearchResults],
  )

  const SourceIcon = useMemo(() => {
    switch (emailDetails.channelType) {
      case 1:
        if (attachmentCount > 0) return <AttachEmail />
        else return <Email />
      case 2:
        return <Storage />
      case 3:
        return <Tune />
      default:
        return <Email />
    }
  }, [attachmentCount, emailDetails])

  return (
    <React.Fragment>
      <CustomRow
        onClick={onRowClick}
        open={isExpanded}
        disabled={isDisabled}
        className={isDisabled ? "disabled-effect" : ""}
      >
        <TableCell className="word-break-all">
          <CellWrapper>
            <>{SourceIcon}</>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginRight="4px"
            >
              {emailDetails.createdAt
                ? dayjs(emailDetails?.createdAt).format("DD.MM.YYYY")
                : "-"}
            </Typography>
            <CalendarMonthOutlined fontSize="small" />
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.createdAt
                ? dayjs(emailDetails?.createdAt).format("HH:mm:ss")
                : "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {rowDetails.sender ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            {isExpanded ? (
              <CustomAutocomplete
                options={filteredOptions}
                getOptionLabel={(option: any) => option?.az}
                filterOptions={(options) => options}
                loading={
                  isSearchingCaseResults ||
                  isRefetchingCaseResults ||
                  isPostAssignCasePending
                }
                disabled={isEditingDisabled || isPostAssignCasePending}
                value={selectedCase}
                onInputChange={(event, value) => {
                  handleInputChange(event, value)
                }}
                onChange={(event, value) => {
                  event.stopPropagation()
                  setSelectedCase(value as ISearchClaimsWithDetails)
                  handleSelect(event, value as ISearchClaimsWithDetails)
                }}
                onKeyDown={(event) => {
                  handleKeyPress(event)
                }}
                open={assignCaseOpen && !selectedCase}
                popupIcon={
                  isSearchingCaseResults || isRefetchingCaseResults ? (
                    <CircularProgress size="16px" />
                  ) : (
                    <ExpandMore />
                  )
                }
                renderInput={(params) => (
                  <TextField
                    {...(params as TextFieldProps)}
                    placeholder={t("search")}
                    fullWidth
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                      onClick: (event) => event.stopPropagation(),
                    }}
                  />
                )}
                PaperComponent={(props: any) => <CustomPaper {...props} />}
                renderOption={(props, option: any) => (
                  <li {...props} key={option.az}>
                    <Box display="flex" flexDirection="column">
                      <Typography variant="regularMedium">
                        {option.az}
                      </Typography>
                      <Typography variant="small">
                        <b>{t("rubrum")}:</b> {option.rubrum}
                      </Typography>
                      <Typography variant="small">
                        <b>{t("reasoning")}:</b> {option.wegen}
                      </Typography>
                    </Box>
                  </li>
                )}
              />
            ) : (
              <Typography
                variant="small"
                className="line-clamp-1"
                color={colors.gray9}
                marginLeft="4px"
              >
                {emailDetails.caseId?.trim() ?? "-"}
              </Typography>
            )}
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.rubrum ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell className="word-break-all">
          <CellWrapper>
            <Typography
              variant="small"
              className="line-clamp-1"
              color={colors.gray9}
              marginLeft="4px"
            >
              {emailDetails.wegen ?? "-"}
            </Typography>
          </CellWrapper>
        </TableCell>
        <TableCell>
          <Typography
            variant="smallBold"
            className="line-clamp-1"
            color={colors.gray9}
            marginRight="24px"
            align="right"
          >
            M
          </Typography>
        </TableCell>
      </CustomRow>
      <CollapsibleRow open={isExpanded}>
        <TableCell colSpan={8}>
          <Collapse in={isExpanded} timeout="auto" unmountOnExit>
            <Box
              display="flex"
              justifyContent="space-between"
              height="75vh"
              gap="16px"
              marginBottom="12px"
            >
              <EmailBodyAndActions
                rowDetails={rowDetails}
                emailDetails={emailDetails}
                attachments={attachments!}
                documents={documents}
                isLoading={isPending || isRefetching}
                isEditingDisabled={isEditingDisabled}
                onUpdateDocuments={handleUpdateDocuments}
              />
              {attachmentCount > 0 && (
                <DocPreviewWrapper>
                  {documents.length > 0 ? (
                    <DocViewer
                      key={`${documents[0].uri}-${Math.random()
                        .toString(36)
                        .substr(2, 9)}`}
                      initialActiveDocument={documents[0] ?? undefined}
                      documents={documents}
                      pluginRenderers={DocViewerRenderers}
                      className="doc-preview-overscroll"
                      config={{
                        header: {
                          disableHeader: true,
                          retainURLParams: true,
                        },
                        pdfVerticalScrollByDefault: true,
                      }}
                    />
                  ) : (
                    <Box
                      height="100%"
                      display="flex"
                      flexDirection="column"
                      gap="16px"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Plagiarism htmlColor={colors.gray9} fontSize="large" />
                      {t("selectFileToPreview")}
                    </Box>
                  )}
                </DocPreviewWrapper>
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              borderTop={`1px solid ${colors.gray5}`}
              paddingTop="8px"
            >
              <Button
                size="small"
                color="error"
                variant="text"
                startIcon={<Delete />}
                disabled={isEditingDisabled}
                onClick={() => setIsDeleteEmailModalOpen(true)}
              >
                {t("delete")}
              </Button>
              <Button onClick={onRowClose} size="small">
                {t("close")}
              </Button>
            </Box>
          </Collapse>
        </TableCell>
      </CollapsibleRow>
      <ConfirmActionModal
        isOpen={isDeleteEmailModalOpen}
        title={t("deleteEmail")}
        body={t("confirmDeleteEmail")}
        submitText={t("delete")}
        closeText={t("cancel")}
        submitColor="error"
        submitIcon={<Delete />}
        isLoading={isEmailDeleting}
        onClose={() => setIsDeleteEmailModalOpen(false)}
        onSubmit={handleDeleteDocument}
      />
    </React.Fragment>
  )
}

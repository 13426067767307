import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { FilterList } from "@mui/icons-material"
import { CustomDatePicker, ElevatedPopper } from "./styled"
import { colors } from "../../../../../../utils"
import { type IInboxFilters } from "../../../../utils"
import { getFiltersCount } from "../../../TabPanel/utils"
import dayjs from "dayjs"

interface IProps {
  filters: IInboxFilters
  onFilterChange: (filter: IInboxFilters) => void
}

const blankFilters: IInboxFilters = {
  source: "",
  startDate: "",
  endDate: "",
  from: "",
  caseNumber: "",
  description: "",
  reasoning: "",
  workflow: "",
}

export const FilterComponent = (props: IProps) => {
  const { filters, onFilterChange } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [localFilters, setLocalFilters] = useState(filters)

  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))
  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"))

  const anchorRef = useRef<HTMLDivElement>(null)

  const filtersCount = useMemo(() => {
    return getFiltersCount(filters)
  }, [filters])

  const handleClearFilters = useCallback(() => {
    onFilterChange(blankFilters)
    setOpenFilterPopup(false)
  }, [])

  const handleOpenPopup = useCallback(() => {
    setOpenFilterPopup(true)
  }, [])

  const handleClosePopup = useCallback(() => {
    setLocalFilters(filters)
    setOpenFilterPopup(false)
  }, [filters])

  const handleUpdateFilters = useCallback(() => {
    onFilterChange(localFilters)
    setOpenFilterPopup(false)
  }, [localFilters])

  useEffect(() => {
    if (openFilterPopup) {
      setLocalFilters(filters)
    }
  }, [openFilterPopup])

  return (
    <>
      <Box display="flex" alignItems="center">
        <ButtonGroup ref={anchorRef}>
          <Badge color="primary" badgeContent={filtersCount}>
            <Button
              variant="outlined"
              startIcon={<FilterList />}
              onClick={handleOpenPopup}
            >
              {t("filter")}
            </Button>
          </Badge>
        </ButtonGroup>
      </Box>
      <ElevatedPopper
        open={openFilterPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={isSmallerThanMD ? "bottom-end" : "left-end"}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseUp"
            onClickAway={handleClosePopup}
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: isSmallerThanLg ? "top left" : "top right",
              }}
            >
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={isGreaterThanSM ? "450px" : "80vw"}
                >
                  <Box
                    padding="16px"
                    marginBottom="8px"
                    bgcolor={colors.secondary}
                  >
                    <Typography variant="regularBold">
                      {t("inboxFilters")}
                    </Typography>
                  </Box>
                  <Box
                    className="scroll"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    padding="16px"
                  >
                    <Typography variant="small">{t("date")}:</Typography>
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                      marginBottom="16px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        disableFuture
                        value={
                          localFilters.startDate
                            ? dayjs(localFilters?.startDate)
                            : null
                        }
                        onChange={(value) => {
                          setLocalFilters({
                            ...localFilters,
                            startDate: (value as dayjs.Dayjs)?.format(
                              "YYYY-MM-DDTHH:mm:ss",
                            ),
                          })
                        }}
                        maxDate={
                          localFilters.endDate
                            ? dayjs(localFilters?.endDate)
                            : undefined
                        }
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          localFilters.endDate
                            ? dayjs(localFilters?.endDate)
                            : null
                        }
                        onChange={(value) => {
                          setLocalFilters({
                            ...localFilters,
                            endDate: (value as dayjs.Dayjs)?.format(
                              "YYYY-MM-DDTHH:mm:ss",
                            ),
                          })
                        }}
                        minDate={
                          localFilters.startDate
                            ? dayjs(localFilters?.startDate)
                            : undefined
                        }
                        label={t("to")}
                      />
                    </Box>
                    <TextField
                      label={t("caseNumber")}
                      value={localFilters.caseNumber}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          caseNumber: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isSmallerThanMD ? "column" : "row"}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    padding="16px"
                    gap="8px"
                  >
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleClearFilters}
                    >
                      {t("clearFilters")}
                    </Button>
                    <Box flex={1} />
                    <Box display="flex" gap="8px">
                      <Button variant="outlined" onClick={handleClosePopup}>
                        {t("close")}
                      </Button>
                      <Button
                        disabled={
                          localFilters === filters ||
                          localFilters === blankFilters
                        }
                        onClick={handleUpdateFilters}
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ElevatedPopper>
    </>
  )
}

import {
  Box,
  Button as ButtonMui,
  styled,
  Card,
  CardContent,
  Accordion,
  AccordionSummary,
  Typography,
} from "@mui/material"
import { colors } from "../../../../../../utils"
import { Link } from "@mui/icons-material"

interface IButtonProps {
  isSelected: boolean
}

export const Wrapper = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
`

export const CardContainer = styled(Box)`
  flex-direction: row;
  display: flex;
  width: 100%;
  align-items: center;
`

export const Button = styled(ButtonMui)<IButtonProps>`
  padding: 0;
  border-radius: 8px;
  text-transform: none;
  background-color: ${(props: IButtonProps) =>
    props.isSelected ? colors.green2 : "#f9f9f9"};
  transition:
    transform 0.2s,
    background-color 0.2s;

  &:hover {
    transform: scale(1.009);
    background-color: ${(props: IButtonProps) =>
      props.isSelected ? colors.green : "#f0f0f0"};
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 280px;
  height: 100px;
  border: 1px solid #ddd;
`

interface IStylingProps {
  key: string
  id: string
  selected: boolean | undefined
  onClick: (e: any) => any
}

export const CustomizedCard = styled(Card)<IStylingProps>`
  height: 85px;
  padding-top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 7.5px;
  border: 1.5px
    ${(props: IStylingProps) => (props.selected ? "solid" : "dashed")}
    ${colors.primary};
  background-color: ${(props: IStylingProps) =>
    props.selected ? colors.blue6 : "white"};
  box-shadow: none;
`

export const CustomizedCardContent = styled(CardContent)`
  display: flex;
  align-items: center;
  flex-direction: row;
  pr: 0;
`

export const controllLabelStyle = {
  width: "100%",
  textAlign: "left",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  pt: 1,
}

export const LoadingBox = styled(Box)`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

interface IAccordionProps {
  key?: string
  expanded: boolean
  onClick: () => void
  id?: string
  expandIcon?: any
  "aria-controls"?: any
  numOfSelectedFields?: number
}

export const CustomizedAccordion = styled(Accordion)<IAccordionProps>`
  width: 100%;
  box-shadow: none;
  border: ${(props) =>
    `1.5px solid ${props.expanded ? colors.primary : colors.gray4}`};
  min-height: 50px;
  border-radius: 10px !important;
  overflow: hidden;
`

export const CustomizedAccordionSummary = styled(
  AccordionSummary,
)<IAccordionProps>`
  overflow: hidden;
  height: 60px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px !important;
  width: 100%;
  align-items: center;
  margin-bottom: ${(props) => (props.expanded ? "16px" : 0)};
  border-bottom-left-radius: ${(props) =>
    props.expanded ? "0px !important" : ""};
  border-bottom-right-radius: ${(props) =>
    props.expanded ? "0px !important" : ""};
  background-color: ${(props) =>
    props.numOfSelectedFields
      ? colors.blue6
      : props.expanded
      ? colors.gray6
      : "white"};
`

export const SummaryWrapper = styled(Box)`
  width: 40%;
  display: flex;
  justify-content: left;
  align-items: center;
`

export const StyledLink = styled(Link)(({ theme }) => ({
  alignSelf: "center",
  fontSize: "50px",
  color: colors.black,
  marginRight: theme.spacing(2),
}))

export const TitleBox = styled(Box)`
  width: 50%;
  display: flex;
  align-items: center;
  font-weight: 700;
`

export const SelectedBox = styled(Box)`
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const CustomTypography = styled(Typography)`
  margin-bottom: 25px;
  display: flex;
  justify-content: flex-start;
`

export const REASONS = [
  "Close casefile",
  "Access to casefile/casefile inspection",
  "Placing order accomplished",
  "To process!",
  "EMA check received? ( EMA = Residents registration office )",
  "Court decision",
  "Opponent reported",
  "Opponent payed ",
  "Arrest warant/detention order received",
  "Holder information received",
  "Info main representative received",
  "Info client received",
  "Cost assessment order received",
  "Create lawsuit draft",
  "Call client",
  "Client payed?",
  "Dunning notice issued, court costs received",
  "Process new mails",
  "Seizure and transaction order issued",
  "Opponent Mail",
  "Client Mail",
  "Legal insurance granted",
  "Legal insurance payed",
  "Confirmation of repair cost coverage received",
  "Check cashed",
  "Expert opinion received",
  "Prepare appointment",
  "Disclosure of assets done",
  "Request enforcement order",
  "Enforcement order received",
  "Compensation invoice payed / Call opponent",
  "Enforceables received",
  "Payment",
  "Enforcement status",
]

export const PRIORITIES = [
  {
    label: "High",
    imageSrc: "https://wk24.atlassian.net/images/icons/priorities/high.svg",
  },

  {
    label: "Medium",
    imageSrc: "https://wk24.atlassian.net/images/icons/priorities/medium.svg",
  },
  {
    label: "Low",
    imageSrc: "https://wk24.atlassian.net/images/icons/priorities/low.svg",
  },
]

export const DUE_DATE_OPTIONS = [
  "Next Day",
  "In a week",
  "In two weeks",
  "In a month",
  "Custom",
]

export const TIME_SPANS = ["day/s", "week/s", "month/s"]

export enum ENonWorkingDay {
  LastWorkingDay,
  NextWorkingDay,
}

import { useCallback, useEffect, useMemo, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { type Node, useEdgesState, useNodesState } from "reactflow"
import { useQuery, useMutation } from "@tanstack/react-query"
import { transformStepToNode, getTransformedLayout, createEdges } from "./utils"
import {
  getWorkflowAPI,
  getWorkflowDefinitionAPI,
  postRerunWorkflowAPI,
} from "../../services"
import { ArrowBackIosNew } from "@mui/icons-material"
import { WorkflowStatusChip, ConfirmActionModal } from "../../components"
import CachedIcon from "@mui/icons-material/Cached"
import { CanvasMap, ExecutionLogsTable } from "./components"

import { useToast } from "../../contexts"

import {
  CustomTab,
  Header,
  InformationBox,
  RerunButton,
  StatusWrapper,
  TabsWrapper,
  Wrapper,
} from "./styled"
import {
  Box,
  CircularProgress,
  IconButton,
  Tabs,
  Typography,
  Tooltip,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"

export const WorkflowPreview = () => {
  const { definitionId, workflowId, version } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState<"stepsPreview" | "executionLogs">(
    "stepsPreview",
  )

  const [isRerunModalOpen, setIsRerunModalOpen] = useState<boolean>(false)

  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const toast = useToast()

  const {
    data: workflowDefinitionData,
    isPending: isWorkflowDefinitionPending,
    isRefetching: isWorkflowDefinitionRefetching,
  } = useQuery({
    queryKey: ["workflow-instance-definition"],
    queryFn: () =>
      getWorkflowDefinitionAPI(definitionId!, { version: version! }),
    enabled: !!definitionId,
    refetchOnMount: true,
  })

  const {
    data: workflowInstanceData,
    isPending: isWorkflowInstancePending,
    isRefetching: isWorkflowInstanceRefetching,
  } = useQuery({
    queryKey: ["workflow-instance"],
    queryFn: () => getWorkflowAPI(workflowId!),
    enabled: !!workflowId,
    refetchOnMount: true,
  })

  const { mutate: confirmRerun, isPending: isRerunning } = useMutation({
    mutationFn: (id: string) => {
      return postRerunWorkflowAPI(id)
    },
    onSuccess: () => {
      setIsRerunModalOpen(false)
      toast.show(t("workflowRetrySuccessful"), "success")
      navigate("/workflow-overview")
    },
    onError: () => {
      setIsRerunModalOpen(false)
      toast.show(t("workflowRetryUnsuccessful"), "error")
    },
  })

  const initialNodes = useMemo(() => {
    const nodes: Node[] = []

    workflowDefinitionData?.steps?.forEach((step) => {
      nodes.push(
        ...transformStepToNode(
          step,
          workflowInstanceData?.executionPointers,
          workflowInstanceData?.data,
        ),
      )
    })

    return nodes
  }, [workflowDefinitionData, workflowInstanceData])

  const initialEdges = useMemo(() => {
    if (workflowDefinitionData?.steps)
      return createEdges(workflowDefinitionData?.steps)
  }, [workflowDefinitionData])

  const handleTabChange = useCallback(
    (
      _event: React.SyntheticEvent,
      newValue: "stepsPreview" | "executionLogs",
    ) => {
      setActiveTab(newValue)
    },
    [],
  )

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getTransformedLayout(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  const isLoading =
    isWorkflowDefinitionPending ||
    isWorkflowInstancePending ||
    isWorkflowDefinitionRefetching ||
    isWorkflowInstanceRefetching

  return (
    <Wrapper>
      <Header>
        <InformationBox>
          <IconButton
            size="small"
            onClick={() => navigate("/workflow-overview")}
          >
            <ArrowBackIosNew fontSize="inherit" />
          </IconButton>
          <Box display="flex" flexDirection="column" alignItems="flex-start">
            <Typography
              variant="regularMedium"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {workflowDefinitionData?.id}
            </Typography>
            <Typography
              variant="extraSmall"
              color="gray"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              {workflowDefinitionData?.description}
            </Typography>
          </Box>
        </InformationBox>
        <TabsWrapper>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <CustomTab
              disabled={isLoading}
              label={t("stepsPreview")}
              value="stepsPreview"
            />
            <CustomTab
              disabled={isLoading}
              label={t("executionHistory")}
              value="executionLogs"
            />
          </Tabs>
        </TabsWrapper>
        <Box display="flex" alignItems="center">
          <Tooltip title={t("retryWorkflow")}>
            <RerunButton onClick={() => setIsRerunModalOpen(true)}>
              <CachedIcon />
            </RerunButton>
          </Tooltip>
          <StatusWrapper>
            <WorkflowStatusChip status={workflowInstanceData?.status} />
          </StatusWrapper>
        </Box>
      </Header>
      {isLoading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {activeTab === "stepsPreview" && (
            <CanvasMap
              nodeOptions={{ nodes, onNodesChange, setNodes }}
              edgeOptions={{ edges, onEdgesChange, setEdges }}
            />
          )}
          {activeTab === "executionLogs" && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
              height="100%"
              padding="24px"
              bgcolor={colors.white2}
              className="scroll"
            >
              <Box
                flex={1}
                display="flex"
                flexDirection="column"
                width="100%"
                maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  marginBottom="24px"
                  gap="12px"
                >
                  <Typography variant="h4" marginRight="auto">
                    {t("executionHistory")}
                  </Typography>
                </Box>
                <Box display="grid">
                  <ExecutionLogsTable
                    executionHistory={workflowInstanceData?.executionHistory!}
                  />
                </Box>
              </Box>
            </Box>
          )}
        </>
      )}

      <ConfirmActionModal
        isOpen={isRerunModalOpen && !!workflowInstanceData}
        title={t("retryWorkflow")}
        body={t("workflowRerunConfirmation")}
        submitColor="primary"
        submitText={t("continue")}
        isLoading={isRerunning}
        closeText={t("cancel")}
        onSubmit={() => confirmRerun(workflowInstanceData?.workflowId!)}
        onClose={() => setIsRerunModalOpen(false)}
      />
    </Wrapper>
  )
}

import React, { useCallback, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { Search } from "@mui/icons-material"

import {
  Box,
  Button,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material"

import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import { ActiveFiltersBar, DefinitionsTable, FilterPopup } from "./components"
import { blankFilters, EDefinitionType } from "./utils"
import {
  getDefinitionTypeName,
  getFiltersCount,
  type IFilters,
} from "./utils/functions"

export const WorkflowDefinitionsListingPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [activeTab, setActiveTab] = useState(EDefinitionType.User)
  const [searchKey, setSearchKey] = useState("")
  const [filters, setFilters] = useState<IFilters>(blankFilters)

  const handleTabChange = useCallback((newTab: EDefinitionType) => {
    setActiveTab(newTab)
  }, [])

  const handleSearch = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKey(event.target.value)
    },
    [],
  )

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        paddingBottom="24px"
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom="12px"
          padding="24px 24px 0 24px"
        >
          <Typography variant="h4">{t("definitionsListing")}</Typography>
          <Button
            onClick={() => navigate("/workflow-configurator/newDefinition/0")}
          >
            {t("newDefinition")}
          </Button>
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          padding="0 24px 12px 24px"
        >
          <Typography
            variant="regular"
            color={colors.gray3}
            className="white-space-pre-line"
            sx={{ whiteSpace: "pre-line" }}
          >
            {t("workflowDefinitionsDescription")}
          </Typography>
          <Box display="flex" alignItems="center" gap="8px">
            <FilterPopup filters={filters} onFilterChange={setFilters} />
            <TextField
              variant="outlined"
              placeholder={t("search")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              onChange={handleSearch}
            />
          </Box>
        </Box>
        <Box
          borderBottom={`1px solid ${colors.gray4}`}
          margin="0 24px 8px 24px"
        >
          <Tabs
            variant="scrollable"
            value={activeTab}
            onChange={(_, value) => handleTabChange(value)}
          >
            <Tab
              label={t(getDefinitionTypeName(EDefinitionType.User))}
              value={EDefinitionType.User}
            />
            <Tab
              label={t(getDefinitionTypeName(EDefinitionType.Custom))}
              value={EDefinitionType.Custom}
            />
            <Tab
              label={t(getDefinitionTypeName(EDefinitionType.System))}
              value={EDefinitionType.System}
            />
          </Tabs>
        </Box>
        {getFiltersCount(filters) > 0 && <ActiveFiltersBar filters={filters} />}
        <DefinitionsTable
          searchKey={searchKey}
          tab={activeTab}
          filters={filters}
        />
      </Box>
    </Box>
  )
}

import { LoadingButton } from "@mui/lab"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"
import { type ReactNode } from "react"

interface IProps {
  isOpen: boolean
  title: string
  body: string
  submitText: string
  closeText: string
  submitColor: "primary" | "error"
  submitIcon?: ReactNode
  isLoading: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmActionModal = (props: IProps) => {
  const {
    isOpen,
    title,
    body,
    submitText,
    closeText,
    submitColor,
    submitIcon,
    isLoading,
    onClose,
    onSubmit,
  } = props

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{body}</DialogContent>
      <DialogActions>
        <Button
          size="small"
          variant="outlined"
          disabled={isLoading}
          onClick={onClose}
        >
          {closeText}
        </Button>
        <LoadingButton
          size="small"
          color={submitColor}
          loading={isLoading}
          startIcon={submitIcon}
          onClick={onSubmit}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import {
  Box,
  IconButton,
  Skeleton,
  TableBody,
  TableCell,
  TableFooter,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material"
import {
  CustomRow,
  EmptyRow,
  EnhancedTable,
  EnhancedTableContainer,
  NoDataRow,
} from "./styled"
import { EnhancedTableHead } from "./components"
import React, {
  useCallback,
  useEffect,
  useState,
  type ChangeEventHandler,
} from "react"
import { useQuery } from "@tanstack/react-query"
import { getWorkflowDefinitionsAPI } from "../../../../services"
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined"
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined"
import { useTranslation } from "react-i18next"
import { colors } from "../../../../utils"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { AccountTreeOutlined } from "@mui/icons-material"
import { type IFilters } from "../../utils"

interface IProps {
  tab: number
  searchKey: string
  filters: IFilters
}

export const DefinitionsTable = (props: IProps) => {
  const { searchKey, tab, filters } = props
  const { t } = useTranslation()

  const [tableConfig, setTableConfig] = useState({ pageSize: 10, page: 0 })
  const [sortBy, setSortBy] = useState("Status")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const {
    data: definitions,
    isPending,
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ["workflow-definitions"],
    queryFn: () =>
      getWorkflowDefinitionsAPI({
        ...filters,
        activeVersionOnly: true,
        page: tableConfig.page + 1,
        pageSize: tableConfig.pageSize,
        searchQuery: searchKey,
        sortBy,
        sortDesc: sortOrder === "desc",
        definitionType: tab,
        active: filters.status,
      }),
    refetchOnMount: true,
    enabled: false,
  })

  const handleSort = useCallback(
    (column: string) => {
      setSortBy(column)
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    },
    [sortOrder],
  )

  const onPageChange = useCallback(
    (_: unknown, page: number) => setTableConfig((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (event) =>
      setTableConfig((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )

  const isDataLoading = isPending || isRefetching

  useEffect(() => {
    if (searchKey) {
      const handler = setTimeout(() => {
        void refetch()
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    } else {
      void refetch()
    }
  }, [tableConfig, searchKey, sortBy, sortOrder, tab, filters])

  useEffect(() => {
    if (
      definitions?.totalItems &&
      definitions?.totalItems > 0 &&
      definitions.currentPage > definitions.totalPages
    ) {
      setTableConfig((prev) => ({ ...prev, page: 0 }))
    }
  }, [definitions])

  return (
    <Box display="grid">
      <EnhancedTableContainer>
        <EnhancedTable>
          <EnhancedTableHead
            sort={sortBy}
            order={sortOrder}
            onSort={handleSort}
          />
          <TableBody>
            {isDataLoading ? (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                {Array.from({ length: tableConfig.pageSize }).map(
                  (_, index) => (
                    <React.Fragment key={index}>
                      <CustomRow>
                        {Array.from({ length: 5 }).map((_, index) =>
                          index === 2 || index === 3 ? (
                            <TableCell key={index}>
                              <Box
                                display="flex"
                                flexDirection="column"
                                justifyContent="center"
                                paddingX="8px"
                                paddingY="6px"
                              >
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="4px"
                                >
                                  <PeopleOutlineOutlinedIcon fontSize="small" />
                                  -
                                  <Skeleton width="60%" />
                                </Box>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  marginTop="4px"
                                  gap="4px"
                                >
                                  <CalendarMonthOutlinedIcon fontSize="small" />
                                  -
                                  <Skeleton width="60%" />
                                </Box>
                              </Box>
                            </TableCell>
                          ) : (
                            <TableCell key={index}>
                              <Skeleton sx={{ mr: "12px" }} />
                            </TableCell>
                          ),
                        )}
                        <TableCell>
                          <Skeleton
                            variant="rounded"
                            width="14px"
                            sx={{ ml: "auto" }}
                          />
                        </TableCell>
                      </CustomRow>
                      <EmptyRow>
                        <TableCell colSpan={10} height="16px" />
                      </EmptyRow>
                    </React.Fragment>
                  ),
                )}
              </>
            ) : definitions?.totalItems === 0 ? (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                <NoDataRow>
                  <TableCell colSpan={10}>
                    <Typography>{t("noData")}</Typography>
                  </TableCell>
                </NoDataRow>
              </>
            ) : (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                {definitions?.items?.map((definition, index) => (
                  <React.Fragment key={index}>
                    <CustomRow>
                      <TableCell>
                        <Box display="flex" flexDirection="column">
                          <Typography>
                            {definition.name && definition.name !== ""
                              ? definition.name
                              : definition.id}
                          </Typography>
                          <Typography fontSize={12} color={colors.gray3}>
                            {t("version")}: {definition.version}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box paddingX="14px" display="flex" alignItems="center">
                          <Box
                            width="8px"
                            height="8px"
                            borderRadius="50%"
                            mr={1.5}
                            border={`1px solid ${
                              definition.active ? colors.primary : colors.gray1
                            } `}
                            bgcolor={
                              definition.active ? colors.primary : colors.white
                            }
                          />
                          <Typography>
                            {definition.active ? t("active") : t("inactive")}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="column"
                          paddingX="8px"
                          paddingY="6px"
                        >
                          <Box
                            paddingX="6px"
                            display="flex"
                            alignItems="center"
                          >
                            <PeopleOutlineOutlinedIcon fontSize="small" />
                            <Typography
                              variant="small"
                              className="line-clamp-1"
                              color={colors.gray9}
                              marginLeft="4px"
                            >
                              {definition.createdBy !== null &&
                              definition.createdBy
                                ? definition.createdBy.name +
                                  " " +
                                  definition.createdBy.surname
                                : "/"}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            marginTop="4px"
                            paddingX="6px"
                          >
                            <CalendarMonthOutlinedIcon fontSize="small" />
                            <Typography
                              variant="small"
                              className="line-clamp-1"
                              color={colors.gray9}
                              marginLeft="4px"
                            >
                              {definition?.createdAt
                                ? dayjs(definition.createdAt).format(
                                    "DD.MM.YYYY, HH:mm",
                                  )
                                : "/"}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Box
                          display="flex"
                          flexDirection="column"
                          paddingX="8px"
                          paddingY="6px"
                        >
                          <Box
                            paddingX="6px"
                            display="flex"
                            alignItems="center"
                          >
                            <PeopleOutlineOutlinedIcon fontSize="small" />
                            <Typography
                              variant="small"
                              className="line-clamp-1"
                              color={colors.gray9}
                              marginLeft="4px"
                            >
                              {definition.updatedBy !== null &&
                              definition.updatedBy
                                ? definition.updatedBy.name +
                                  " " +
                                  definition.updatedBy.surname
                                : "/"}
                            </Typography>
                          </Box>
                          <Box
                            display="flex"
                            alignItems="center"
                            marginTop="4px"
                            paddingX="6px"
                          >
                            <CalendarMonthOutlinedIcon fontSize="small" />
                            <Typography
                              variant="small"
                              className="line-clamp-1"
                              color={colors.gray9}
                              marginLeft="4px"
                            >
                              {definition?.updatedAt
                                ? dayjs(definition.updatedAt).format(
                                    "DD.MM.YYYY, HH:mm",
                                  )
                                : "/"}
                            </Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="small"
                          className="line-clamp-2"
                          paddingX="6px"
                        >
                          <Box paddingX="8px" paddingY="6px">
                            {definition.description}
                          </Box>
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          to={`/workflow-configurator/${definition.id}/${definition.version}`}
                        >
                          <IconButton>
                            <AccountTreeOutlined fontSize="small" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </CustomRow>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                  </React.Fragment>
                ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={definitions?.totalItems ?? 0}
                page={tableConfig.page}
                rowsPerPage={tableConfig.pageSize}
                onPageChange={onPageChange}
                onRowsPerPageChange={onPageSizeChange}
                labelRowsPerPage={t("rowsPerPage")}
              />
            </TableRow>
          </TableFooter>
        </EnhancedTable>
      </EnhancedTableContainer>
    </Box>
  )
}

import { Box, FormControl, Select, styled, TextField } from "@mui/material"
import { colors } from "../../../../utils"

export const CustomFormControl = styled(FormControl)`
  label {
    font-size: 14px;

    &.MuiFormLabel-root.MuiInputLabel-shrink {
      margin-top: 0;
    }
  }
`

export const SearchTextField = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.gray2};
  }

  & .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${colors.primary};
  }
`

export const ToggleWrapper = styled(Box)`
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  @media (max-width: 650px) {
    flex-direction: column-reverse;
  }
`

export const FilteringWrapper = styled(Box)`
  display: flex;
  gap: 8px;
  align-items: center;

  @media (max-width: 1440px) {
    flex-wrap: wrap;
    justify-content: flex-end;
  }
`

export const SelectDropdown = styled(Select)`
  width: 180px;
`

export const ItemBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

import {
  Autocomplete,
  Box,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  type TextFieldProps,
  type SelectChangeEvent,
  Typography,
  CircularProgress,
} from "@mui/material"
import {
  type UseFormReturn,
  type FieldValues,
  useFieldArray,
  Controller,
  useWatch,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  camelCaseStringToSentence,
  colors,
  generatePythonExpression,
  IF_ELSE_CONFIG_INITIAL_DATA,
} from "../../../../../../utils"
import AddIcon from "@mui/icons-material/Add"
import { useCallback, useEffect, useMemo } from "react"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import { CustomSelect, RemoveButton } from "./styled"
import { type Node } from "reactflow"
import { getStepData } from "../../utils"
import { useQuery } from "@tanstack/react-query"
import { getChannelConfigurationsAPI } from "../../../../../../services"
import { defineType } from "../DocumentTemplateConfig/utils"
import { Check } from "@mui/icons-material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  methods: UseFormReturn<FieldValues, any, undefined>
  selectedAction: INodeItem
  nodes: Node[]
  ids: Record<string, string>
}

export const IfElseStepConfig = (props: IProps) => {
  const { handleChange, methods, selectedAction, nodes, ids } = props
  const { t } = useTranslation()
  const { control } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "Condition.data",
  })

  const { data: allFields, isPending: isAllFieldsFetching } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: async () => {
      const response = await getChannelConfigurationsAPI("Interface")
      const chosenApiSetupDetails = response
        ?.find((ch) => ch.apiSetup?.id === ids.ApiSetupId.replaceAll("'", ""))
        ?.apiSetup?.apiSetupDetails.find(
          (sd) => sd.id === ids.ApiSetupDetailsId.replaceAll("'", ""),
        )

      return JSON.parse(chosenApiSetupDetails!.jsonResponseSchema)
    },

    refetchOnMount: true,
  })

  const condition = useWatch({ control, name: "Condition" })

  // Object containing all selected fields from GetDataStep
  const selectedFields = useMemo(() => {
    if (nodes?.length) {
      const stepData = getStepData(nodes, "GetDataStep")
      let selFields = stepData?.inputs?.SelectedFields
      selFields = Object.values(selFields ?? {})?.reduce(
        (acc: any, field: any) => {
          return { ...acc, ...field }
        },
        {},
      )
      if (selFields) {
        return selFields
      } else {
        return undefined
      }
    }

    return []
  }, [nodes])

  // List containing all fields that can be used in the condition
  const fieldsList = useMemo(() => {
    // Check if selectedFields has steps that are actively selected
    return Object.keys(selectedFields ?? {})?.some(
      (o) => selectedFields[o]?.selected,
    )
      ? // If yes, return only the selected fields
        Object.keys(selectedFields)
          .map((key) => ({
            ...selectedFields[key],
            type: defineType(selectedFields[key].type),
            value: key,
          }))
          .filter((f) => f.selected)
      : // If there are no selected fields, return all fields
        Object.keys(allFields ?? []).map((key) => ({
          ...allFields[key],
          type: defineType(allFields[key].type),
          value: key,
        }))
  }, [selectedFields, allFields])

  useEffect(() => {
    const validConditions = condition?.data
      ?.filter((c: any) => !!c.prop && !!c.condition)
      .map((c: any) => {
        let value = c?.value

        const selectedKeys = Object.keys(selectedFields)
        const regex = new RegExp(`\\b(${selectedKeys.join("|")})\\b`, "g")

        const propMatch = c.prop.match(
          /context\.Workflow\.Data\.ResponseGetData\['(.*?)'\]/,
        )

        const innerProp = propMatch ? propMatch[1] : c.prop
        const propType = fieldsList?.find((f) => f.value === innerProp)?.type

        if (typeof value === "string") {
          if (propType === "Number") {
            const parts = value.split(" ").filter((part) => part)
            value = parts
              .map((part) => {
                const wrappedRegex =
                  /context\.Workflow\.Data\.ResponseGetData\['(.*?)'\]/
                const innerMatch = part.match(wrappedRegex)

                if (innerMatch) {
                  return part
                }

                const keyMatch = part.replace(/['"]/g, "")
                if (selectedKeys.includes(keyMatch)) {
                  return `context.Workflow.Data.ResponseGetData['${keyMatch}']`
                }

                return part
              })
              .join(" ")
          } else {
            value = value.replace(
              regex,
              (matchedKey) =>
                `context.Workflow.Data.ResponseGetData['${matchedKey}']`,
            )
          }
        }

        return {
          prop: c.prop,
          condition: c.condition,
          value,
        }
      })

    if (validConditions?.length) {
      const expression = generatePythonExpression(
        validConditions,
        condition.logic,
      )

      handleChange(selectedAction, "input", expression, "Condition")
    }
  }, [condition])

  const onAddClick = useCallback(
    () => append(IF_ELSE_CONFIG_INITIAL_DATA.data),
    [],
  )

  const onRemoveClick = useCallback((index: number) => remove(index), [])

  return isAllFieldsFetching ? (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      height="100%"
      gap="24px"
    >
      <CircularProgress />
      <Typography variant="large">{t("loading")}</Typography>
    </Box>
  ) : (
    <Box display="flex" flexDirection="column">
      <Controller
        control={control}
        name="Condition.logic"
        render={({ field: { value, onChange } }) => (
          <RadioGroup
            row
            defaultValue="any"
            value={value ?? "any"}
            onChange={onChange}
          >
            <FormControlLabel
              value="any"
              name="matchAny"
              control={<Radio />}
              label={t("matchAny")}
            />
            <FormControlLabel
              value="all"
              name="matchAll"
              control={<Radio />}
              label={t("matchAll")}
            />
          </RadioGroup>
        )}
      />

      <Box marginTop="8px" display="flex" flexDirection="column" gap="16px">
        {fields?.map((field, index) => (
          <Box
            key={field.id}
            position="relative"
            display="flex"
            flexDirection="column"
            padding="8px"
            border={`1px solid ${colors.gray10}`}
            borderRadius="8px"
          >
            {fields.length > 1 && (
              <RemoveButton
                size="small"
                color="error"
                onClick={() => onRemoveClick(index)}
              >
                <DeleteOutlineIcon />
              </RemoveButton>
            )}
            <Typography marginBottom="-4px" variant="regularBold">
              {t("if")}
            </Typography>
            <Controller
              control={control}
              name={`Condition.data.${index}.prop`}
              render={({ field: { value, onChange } }) => (
                <FormControl fullWidth margin="normal" size="medium">
                  <InputLabel>{t("prop")}</InputLabel>
                  <Select
                    value={value ?? ""}
                    onChange={(event: SelectChangeEvent<any>) => {
                      const isEventBoolean =
                        fieldsList?.find((f) => f.value === event.target.value)
                          ?.type === "True/False"
                      const isEventDate =
                        fieldsList?.find((f) => f.value === event.target.value)
                          ?.type === "Date"

                      onChange(event)

                      methods.setValue(
                        `Condition.data.${index}.condition`,
                        isEventBoolean || isEventDate ? "isEqual" : "",
                      )
                      methods.setValue(`Condition.data.${index}.value`, "")
                    }}
                    label={t("prop")}
                    renderValue={(value) => camelCaseStringToSentence(value)}
                  >
                    {fieldsList?.map((field) => (
                      <MenuItem key={field.value} value={field.value}>
                        <Box
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          width="100%"
                        >
                          <Box display="flex" flexDirection="column">
                            <Typography variant="large">
                              {field.title}
                            </Typography>
                            <Typography variant="regular">
                              <Typography variant="regularBold">
                                {t("type")}:{" "}
                              </Typography>
                              {field.type}
                            </Typography>
                            <Typography variant="regular">
                              <Typography variant="regularBold">
                                {t("exampleValue")}:{" "}
                              </Typography>
                              {field.example}
                            </Typography>
                          </Box>
                          {value === field.value && <Check color="primary" />}
                        </Box>
                      </MenuItem>
                    ))}
                    {fieldsList?.length === 0 && (
                      <Typography paddingLeft={3}>
                        {t("noChannelSelectedInGetDataStep")}
                      </Typography>
                    )}
                  </Select>
                </FormControl>
              )}
            />
            <Box display="flex" alignItems="center" gap="16px" width="100%">
              <Controller
                control={control}
                name={`Condition.data.${index}.condition`}
                render={({ field: { value, onChange } }) => {
                  const propType =
                    fieldsList?.find(
                      (f) => f.value === condition?.data?.[index]?.prop,
                    )?.type ?? ""

                  const isConditionDisabled =
                    propType === "True/False" || propType === "Date"

                  const menuItems = []

                  if (propType === "Number") {
                    menuItems.push(
                      <MenuItem key="isEqual" value="isEqual">
                        {t("isEqualTo")}
                      </MenuItem>,
                      <MenuItem key="isGreater" value="isGreater">
                        {t("isGreater")}
                      </MenuItem>,
                      <MenuItem key="isLess" value="isLess">
                        {t("isLess")}
                      </MenuItem>,
                    )
                  }

                  if (propType === "Text") {
                    menuItems.push(
                      <MenuItem key="isEqual" value="isEqual">
                        {t("isEqualTo")}
                      </MenuItem>,
                      <MenuItem key="isEmpty" value="isEmpty">
                        {t("isEmpty")}
                      </MenuItem>,
                    )
                  }

                  if (propType === "True/False" || propType === "Date") {
                    menuItems.push(
                      <MenuItem key="isEqual" value="isEqual">
                        {t("is")}
                      </MenuItem>,
                    )
                  }

                  return (
                    <FormControl fullWidth margin="normal" size="medium">
                      <InputLabel>{t("condition")}</InputLabel>
                      <CustomSelect
                        value={isConditionDisabled ? "isEqual" : value ?? ""}
                        disabled={isConditionDisabled}
                        onChange={(event: SelectChangeEvent<any>) => {
                          onChange(event)
                          if (event.target.value === "isEmpty") {
                            methods.setValue(
                              `Condition.data.${index}.value`,
                              null,
                            )
                          }
                        }}
                        label={t("condition")}
                      >
                        {menuItems}
                      </CustomSelect>
                    </FormControl>
                  )
                }}
              />
              <Controller
                control={control}
                name={`Condition.data.${index}.value`}
                render={({ field: { value, onChange } }) => {
                  const propType =
                    fieldsList?.find(
                      (f) => f.value === condition?.data?.[index]?.prop,
                    )?.type ?? ""

                  const parts =
                    typeof value === "string"
                      ? value.split(" ").filter((part) => part)
                      : [String(value)]

                  const extractContextKey = (part: string) => {
                    const match = part.match(
                      /context\.Workflow\.Data\.ResponseGetData\['(.*?)'\]/,
                    )
                    return match ? match[1] : part
                  }

                  const firstValue = extractContextKey(parts[0] || "")
                  const operation = parts[1] || ""
                  const secondValue = extractContextKey(parts[2] || "")

                  switch (propType) {
                    case "Number":
                      return (
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="16px"
                          width="250%"
                        >
                          <Autocomplete
                            fullWidth
                            freeSolo
                            sx={{ mt: "8px" }}
                            options={
                              fieldsList
                                .filter((f) => f.type === "Number")
                                .map((i) => i.value) ?? []
                            }
                            value={firstValue ?? ""}
                            onChange={(_, newValue) => {
                              const newExpression = `${
                                newValue ?? 0
                              } ${operation} ${secondValue}`
                              onChange(newExpression.trim())
                            }}
                            getOptionLabel={(option) => {
                              if (fieldsList?.some((f) => f.value === option)) {
                                return camelCaseStringToSentence(option)!
                              }
                              return option
                            }}
                            filterOptions={(options, params) => {
                              const filtered = options.filter(
                                (option) =>
                                  camelCaseStringToSentence(option)
                                    ?.toLowerCase()
                                    .includes(params.inputValue.toLowerCase()),
                              )

                              if (params.inputValue !== "") {
                                filtered.push(params.inputValue)
                              }

                              return filtered
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...(params as TextFieldProps)}
                                label="First Value"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                value={
                                  firstValue
                                    ? camelCaseStringToSentence(firstValue)
                                    : ""
                                }
                              />
                            )}
                          />

                          <FormControl fullWidth margin="normal" size="medium">
                            <InputLabel>{t("operation")}</InputLabel>
                            <Select
                              label={t("operation")}
                              value={operation || ""}
                              onChange={(event) => {
                                const newOperation = event.target.value
                                const newExpression = `${firstValue} ${newOperation} ${
                                  newOperation === "" ? "" : secondValue
                                }`
                                onChange(newExpression.trim())
                              }}
                            >
                              <MenuItem value="">{t("none")}</MenuItem>
                              <MenuItem value="+">{t("addition")} (+)</MenuItem>
                              <MenuItem value="-">
                                {t("subtraction")} (-)
                              </MenuItem>
                              <MenuItem value="*">
                                {t("multiplication")} (*)
                              </MenuItem>
                              <MenuItem value="/">{t("division")} (/)</MenuItem>
                            </Select>
                          </FormControl>
                          <Autocomplete
                            fullWidth
                            freeSolo
                            sx={{ mt: "8px" }}
                            options={
                              fieldsList
                                .filter((f) => f.type === "Number")
                                .map((i) => i.value) ?? []
                            }
                            value={secondValue ?? ""}
                            onChange={(_, newValue) => {
                              const newExpression = `${firstValue} ${operation} ${
                                newValue ?? 0
                              }`
                              onChange(newExpression.trim())
                            }}
                            getOptionLabel={(option) => {
                              if (fieldsList?.some((f) => f.value === option)) {
                                return camelCaseStringToSentence(option)!
                              }
                              return option
                            }}
                            filterOptions={(options, params) => {
                              const filtered = options.filter(
                                (option) =>
                                  camelCaseStringToSentence(option)
                                    ?.toLowerCase()
                                    .includes(params.inputValue.toLowerCase()),
                              )

                              if (params.inputValue !== "") {
                                filtered.push(params.inputValue)
                              }

                              return filtered
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...(params as TextFieldProps)}
                                label="Second Value"
                                fullWidth
                                InputProps={{
                                  ...params.InputProps,
                                  disableUnderline: true,
                                }}
                                value={
                                  secondValue
                                    ? camelCaseStringToSentence(secondValue)
                                    : ""
                                }
                              />
                            )}
                            disabled={!operation}
                          />
                        </Box>
                      )
                    case "True/False":
                      return (
                        <Box marginX="8px" marginTop="8px" width="100%">
                          <RadioGroup
                            row
                            value={value ?? ""}
                            onChange={onChange}
                          >
                            <FormControlLabel
                              value="true"
                              control={<Radio />}
                              label={t("true")}
                            />
                            <FormControlLabel
                              value="false"
                              control={<Radio />}
                              label={t("false")}
                            />
                          </RadioGroup>
                        </Box>
                      )
                    case "Text":
                      return (
                        <Autocomplete
                          fullWidth
                          freeSolo
                          sx={{ mt: "8px" }}
                          options={fieldsList.map((i) => i.value) ?? []}
                          value={value ?? ""}
                          onChange={(_, newValue) => {
                            if (newValue) {
                              onChange(newValue)
                            }
                          }}
                          getOptionLabel={(option) => {
                            if (fieldsList?.some((f) => f.value === option)) {
                              return camelCaseStringToSentence(option)
                            }

                            return option
                          }}
                          filterOptions={(options, params) => {
                            const filtered = options.filter(
                              (option) =>
                                camelCaseStringToSentence(option)
                                  ?.toLowerCase()
                                  .includes(params.inputValue.toLowerCase()),
                            )

                            if (params.inputValue !== "") {
                              filtered.push(params.inputValue)
                            }

                            return filtered
                          }}
                          renderOption={(props, option) => (
                            <li {...props} key={option}>
                              <Box
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                width="100%"
                              >
                                {Object.keys(selectedFields).includes(
                                  option,
                                ) ? (
                                  <Box display="flex" flexDirection="column">
                                    <Typography variant="large">
                                      {camelCaseStringToSentence(option)}
                                    </Typography>
                                    <Typography variant="regular">
                                      <Typography variant="regularBold">
                                        {t("type")}:{" "}
                                      </Typography>
                                      {
                                        fieldsList?.find(
                                          (f) => f.value === option,
                                        )?.type
                                      }
                                    </Typography>
                                    <Typography variant="regular">
                                      <Typography variant="regularBold">
                                        {t("exampleValue")}:{" "}
                                      </Typography>
                                      {
                                        fieldsList?.find(
                                          (f) => f.value === option,
                                        )?.example
                                      }
                                    </Typography>
                                  </Box>
                                ) : (
                                  <Typography variant="large">
                                    {option}
                                  </Typography>
                                )}
                                {value === option && <Check color="primary" />}
                              </Box>
                            </li>
                          )}
                          disabled={
                            condition?.data?.[index]?.condition === "isEmpty"
                          }
                          renderInput={(params) => (
                            <TextField
                              {...(params as TextFieldProps)}
                              label="Value"
                              fullWidth
                              InputProps={{
                                ...params.InputProps,
                                disableUnderline: true,
                              }}
                              value={
                                value ? camelCaseStringToSentence(value) : ""
                              }
                            />
                          )}
                        />
                      )
                    case "Date":
                      return (
                        <DatePicker
                          sx={{ mt: "8px" }}
                          format="DD.MM.YYYY"
                          value={value ? dayjs(value, "DD.MM.YYYY") : null}
                          onChange={(date: any) => {
                            if (date?.isValid()) {
                              onChange(date.format("DD.MM.YYYY"))
                            } else {
                              onChange(null)
                            }
                          }}
                          label="Value"
                        />
                      )
                    default:
                      return (
                        <TextField
                          value={value ?? ""}
                          onChange={onChange}
                          label="Value"
                          fullWidth
                          sx={{ mt: "8px" }}
                        />
                      )
                  }
                }}
              />
            </Box>
          </Box>
        ))}
      </Box>
      <Box marginTop="16px" display="flex" justifyContent="flex-end">
        <IconButton onClick={onAddClick} color="primary">
          <AddIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

import { axiosWorkflow } from "../lib"

export const getWorkflowDefinitionsAPI = (
  params: IGetWorkflowDefinitionsParams,
): Promise<IGetWorkflowDefinitionsResponse> =>
  axiosWorkflow.get("/Definition", { params })

export const getWorkflowDefinitionAPI = (
  id: string,
  params: Record<string, string>,
): Promise<IWorkflowDefinitionRes> =>
  axiosWorkflow.get(`/Definition/${id}`, { params })

export const postWorkflowDefinitionAPI = (
  body: IWorkflowDefinition,
): Promise<IWorkflowDefinitionRes> => axiosWorkflow.post("/Definition", body)

export const postActivateDefinitionVersionAPI = (
  id: string,
  version: number,
): Promise<any> => axiosWorkflow.post(`/Definition/${id}/${version}/activate`)

export const postUpdateWorkflowDefinitionAPI = (
  body: IWorkflowDefinition,
): Promise<IWorkflowDefinitionRes> =>
  axiosWorkflow.post(`/Definition/${body.id}/update`, body)

export const deleteWorkflowDefinitionAPI = (
  id: string,
  params: Record<string, number>,
): Promise<any> => axiosWorkflow.delete(`/Definition/${id}`, { params })

export const getHasWorkflowInstancesAPI = (
  id: string,
  version: number,
): Promise<boolean> =>
  axiosWorkflow.get(`/Workflow/${id}/${version}/has-workflow-instances`)

export const getWorkflowsAPI = (
  params: IGetWorkflowsParams,
): Promise<IGetWorkflowsResponse> => axiosWorkflow.get("/Workflow", { params })

export const getWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.get(`/Workflow/${id}`)

export const postWorkflowAPI = (
  requestParams: IPostWorkflowRequest,
): Promise<IWorkflow> =>
  axiosWorkflow.post(
    `/Workflow/${requestParams.definitionId}`,
    requestParams.body,
  )

export const putSuspendWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.put(`/Workflow/${id}/suspend`)

export const postResumeWorkflowAPI = (id: string): Promise<IWorkflow> =>
  axiosWorkflow.post(`/Workflow/${id}/resume`)

export const postWorkflowEventAPI = (
  requestParams: IPostEventRequest,
): Promise<void> =>
  axiosWorkflow.post(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
    requestParams.body,
  )

export const getWorkflowEventAPI = (
  requestParams: IGetEventRequest,
): Promise<IGetEventResponse> =>
  axiosWorkflow.get(
    `/Event/${requestParams.eventName}/${requestParams.eventKey}`,
  )

export const getCrashmateEventListAPI = (): Promise<ICrashmateEvent[]> =>
  axiosWorkflow.get("/Event/crashmate-events")

export const getTemplateListAPI = (): Promise<any> => {
  return axiosWorkflow.get("/File/templates")
}

export const postRerunWorkflowAPI = (id: string): Promise<any> =>
  axiosWorkflow.post(`/Workflow/${id}/retry`)

export const postRerunStepFromWorkflowAPI = (
  requestParams: IPostStepRerunRequest,
): Promise<any> =>
  axiosWorkflow.post(
    `/Workflow/${requestParams.workflowId}/step/${requestParams.executionPointerId}/retry`,
  )

export const getAdvowareUsersAPI = (): Promise<
  IGetAdvowareUsersResponseObject[]
> => axiosWorkflow.get("/Info/advowareUsers")

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
  type SelectChangeEvent,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  ClearFiltersButton,
  CustomDatePicker,
  ElevatedPopper,
  FilterCount,
  Select,
} from "./styled"
import { Check, FilterAlt, FilterAltOutlined } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { colors } from "../../../../utils"
import {
  getDefinitionTypeName,
  getFiltersCount,
  type IFilters,
} from "../../utils"
import { getStatusText } from "../../../../components/WorkflowStatusChip/utils"
import { WorkflowStatusChip } from "../../../../components"

interface IProps {
  filters: IFilters
  onFilterChange: (filter: IFilters) => void
}

const blankFilters: IFilters = {
  name: "",
  status: -1,
  caseNumber: "",
  createdBy: "",
  definitionType: undefined,
  createdStartDate: "",
  createdEndDate: "",
}

export const FilterPopup = (props: IProps) => {
  const { filters, onFilterChange } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [localFilters, setLocalFilters] = useState(filters)

  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))
  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"))

  const anchorRef = useRef<HTMLDivElement>(null)

  const filtersCount = useMemo(() => {
    return getFiltersCount(filters)
  }, [filters])

  const handleClearFilters = useCallback(() => {
    onFilterChange(blankFilters)
    setOpenFilterPopup(false)
  }, [])

  const handleOpenPopup = useCallback(() => {
    setOpenFilterPopup(true)
  }, [])

  const handleClosePopup = useCallback(() => {
    setLocalFilters(filters)
    setOpenFilterPopup(false)
  }, [filters])

  const handleUpdateFilters = useCallback(() => {
    onFilterChange(localFilters)
    setOpenFilterPopup(false)
  }, [localFilters])

  const handleStatusChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event
    setLocalFilters({
      ...localFilters,
      status: typeof value === "string" ? value.split(",") : value,
    })
  }

  useEffect(() => {
    if (openFilterPopup) {
      setLocalFilters(filters)
    }
  }, [openFilterPopup])

  return (
    <>
      <Box display="flex" alignItems="center">
        {filtersCount > 0 && (
          <ClearFiltersButton
            variant="text"
            color="inherit"
            onClick={handleClearFilters}
          >
            {t("clearFilters")}
            <FilterCount>{filtersCount}</FilterCount>
          </ClearFiltersButton>
        )}
        <ButtonGroup ref={anchorRef}>
          <IconButton onClick={handleOpenPopup}>
            {filtersCount > 0 ? <FilterAlt /> : <FilterAltOutlined />}
          </IconButton>
        </ButtonGroup>
      </Box>
      <ElevatedPopper
        open={openFilterPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={isSmallerThanMD ? "bottom-start" : "left-end"}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseUp"
            onClickAway={handleClosePopup}
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: isSmallerThanLg ? "top left" : "top right",
              }}
            >
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={isSmallerThanMD ? "70vw" : "450px"}
                >
                  <Box
                    padding="16px"
                    marginBottom="8px"
                    bgcolor={colors.secondary}
                  >
                    <Typography variant="regularBold">
                      {t("workflowFilters")}
                    </Typography>
                  </Box>
                  <Box
                    className="scroll"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    padding="16px"
                  >
                    <TextField
                      label={t("name")}
                      value={localFilters.name}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          name: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label={t("caseNumber")}
                      value={localFilters.caseNumber}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          caseNumber: e.target.value,
                        })
                      }
                    />
                    <Box display="flex" flexDirection="column" marginTop="16px">
                      <Typography variant="small" marginBottom="8px">
                        {t("workflowType")}:
                      </Typography>
                      <Select
                        value={localFilters.definitionType}
                        onChange={(e) =>
                          setLocalFilters((prev) => ({
                            ...prev,
                            definitionType: e.target.value as number,
                          }))
                        }
                        displayEmpty
                        renderValue={(selected: any) => {
                          if (selected === undefined) {
                            return (
                              <Typography color={colors.gray2} variant="large">
                                {t("selectWorkflowType")}
                              </Typography>
                            )
                          }

                          return (
                            <Typography variant="regular">
                              {t(getDefinitionTypeName(selected))}
                            </Typography>
                          )
                        }}
                      >
                        <MenuItem value={undefined}>
                          <i>{t("all")}</i>
                        </MenuItem>
                        {[0, 1, 2].map((name) => (
                          <MenuItem key={name} value={name}>
                            <Box
                              display="flex"
                              width="100%"
                              height="20px"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {t(getDefinitionTypeName(name))}
                              {localFilters.definitionType === name && (
                                <Check
                                  htmlColor={colors.primary}
                                  fontSize="small"
                                />
                              )}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                    <Typography marginTop="16px" variant="small">
                      {t("createdDate")}:
                    </Typography>
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        disableFuture
                        value={
                          localFilters.createdStartDate
                            ? dayjs(localFilters?.createdStartDate)
                            : null
                        }
                        onChange={(value) =>
                          setLocalFilters({
                            ...localFilters,
                            createdStartDate: (
                              value as dayjs.Dayjs
                            )?.toString(),
                          })
                        }
                        maxDate={
                          localFilters.createdEndDate
                            ? dayjs(localFilters?.createdEndDate)
                            : undefined
                        }
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          localFilters.createdEndDate
                            ? dayjs(localFilters?.createdEndDate)
                            : null
                        }
                        onChange={(value) => {
                          setLocalFilters({
                            ...localFilters,
                            createdEndDate: (value as dayjs.Dayjs)?.toString(),
                          })
                        }}
                        minDate={
                          localFilters.createdStartDate
                            ? dayjs(localFilters?.createdStartDate)
                            : undefined
                        }
                        label={t("to")}
                      />
                    </Box>
                    <Box display="flex" flexDirection="column" marginTop="16px">
                      <Typography variant="small" marginBottom="8px">
                        {t("status")}:
                      </Typography>
                      <Select
                        value={localFilters.status ?? []}
                        onChange={handleStatusChange}
                        displayEmpty
                        renderValue={(selected: any) => {
                          if (selected === -1) {
                            return (
                              <Box display="flex">
                                <Typography
                                  color={colors.gray2}
                                  variant="large"
                                >
                                  {t("selectStatus")}
                                </Typography>
                              </Box>
                            )
                          }

                          return (
                            <Box display="flex" flexWrap="wrap" gap="4px">
                              <WorkflowStatusChip
                                key={selected}
                                status={parseInt(selected)}
                              />
                            </Box>
                          )
                        }}
                      >
                        {[0, 1, 2, 3].map((name) => (
                          <MenuItem key={name} value={name}>
                            <Box
                              display="flex"
                              width="100%"
                              height="20px"
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              {t(getStatusText(name))}
                              {localFilters.status === name && (
                                <Check
                                  htmlColor={colors.primary}
                                  fontSize="small"
                                />
                              )}
                            </Box>
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isSmallerThanMD ? "column" : "row"}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    padding="16px"
                    gap="8px"
                  >
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleClearFilters}
                    >
                      {t("clearFilters")}
                    </Button>
                    <Box flex={1} />
                    <Box display="flex" gap="8px">
                      <Button variant="outlined" onClick={handleClosePopup}>
                        {t("close")}
                      </Button>
                      <Button
                        disabled={
                          localFilters === filters ||
                          localFilters === blankFilters
                        }
                        onClick={handleUpdateFilters}
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ElevatedPopper>
    </>
  )
}

import { type ChangeEventHandler } from "react"
import {
  Box,
  Button,
  FormControlLabel,
  InputAdornment,
  Switch,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import SearchIcon from "@mui/icons-material/Search"
import AddIcon from "@mui/icons-material/Add"

interface IProps {
  filters: Pick<IGetVehiclesParams, "allVehicles">
  onNewVehicleClick: () => void
  onSearchChange?: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onShowAllChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void
}

export const VehicleHeader = (props: IProps) => {
  const { filters, onNewVehicleClick, onSearchChange, onShowAllChange } = props
  const { t } = useTranslation()
  const { breakpoints } = useTheme()
  const isSmallerThanLg = useMediaQuery(breakpoints.down("lg"))

  return (
    <Box
      display="flex"
      flexDirection="column"
      paddingX="24px"
      paddingTop="24px"
      gap="8px"
    >
      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="space-between"
        gap={isSmallerThanLg ? "16px" : "24px"}
      >
        <Typography variant="h4">{t("vehicleManagement")}</Typography>
        <Box
          display="flex"
          justifyContent="space-between"
          gap={isSmallerThanLg ? "8px" : "16px"}
        >
          <Box>
            <TextField
              variant="outlined"
              placeholder={t("search")}
              fullWidth={isSmallerThanLg}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={onSearchChange}
            />
          </Box>
          <Button
            className="whitespace-no-wrap"
            onClick={onNewVehicleClick}
            startIcon={<AddIcon />}
          >
            {t("newVehicle")}
          </Button>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start">
        <FormControlLabel
          control={
            <Switch checked={filters.allVehicles} onChange={onShowAllChange} />
          }
          label={t("showAll")}
          labelPlacement="end"
        />
      </Box>
    </Box>
  )
}

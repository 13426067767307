import { Box, IconButton, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CARD_HEIGHT, CARD_WIDTH } from "../../../../utils/constants"
import { getNodeIcon } from "../../../../../WorkflowConfigurator/utils"
import { getExecutionStatusColor } from "../../../../utils"
import {
  getExecutionPointerStatusColor,
  getExecutionStatusName,
} from "../../../utils"
import { useState, useCallback } from "react"
import { colors } from "../../../../../../utils/colors"
import { LogsModal } from "../../../../components"
import { InteractionButton } from "../InterractionCard/styled"
import { DocumentModal } from "./DocumentModal"
import CachedIcon from "@mui/icons-material/Cached"
import { postRerunStepFromWorkflowAPI } from "../../../../../../services"
import { useToast } from "../../../../../../contexts"
import { useMutation, useQuery } from "@tanstack/react-query"
import { ConfirmActionModal } from "../../../../../../components"
import { useParams } from "react-router-dom"

interface IProps {
  id: string
  type: string
  action: string
  label: string
  icon: string
  status: number
  workflowId?: string
  executionPointerId?: string
}

export const CardInfo = (props: IProps) => {
  const { id, type, label, icon, status, workflowId, executionPointerId } =
    props
  const { t } = useTranslation()

  const [isHovered, setIsHovered] = useState(false)
  const [showLogModal, setShowLogModal] = useState(false)
  const [showDocViewerModal, setShowDocViewerModal] = useState(false)
  const [isRerunModalOpen, setIsRerunModalOpen] = useState(false)
  const toast = useToast()
  const params = useParams()

  const { refetch } = useQuery<IGetWorkflowsResponse>({
    queryKey: ["workflows-listing"],
  })

  const { mutate: retryStep, isPending: isRerunning } = useMutation({
    mutationFn: (ids: IPostStepRerunRequest) =>
      postRerunStepFromWorkflowAPI({
        workflowId: (ids?.workflowId || params?.workflowId)!,
        executionPointerId: ids.executionPointerId,
      }),
    onSuccess: () => {
      toast.show(t("stepRetrySuccessful"), "success")
      setIsRerunModalOpen(false)
      void refetch()
    },
    onError: () => {
      toast.show(t("stepRetryUnsuccessful"), "error")
    },
  })

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true)
  }, [])

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false)
  }, [])

  const handleStatusClick = useCallback(() => {
    setShowLogModal(true)
  }, [])

  const retryButtonExist = !!executionPointerId

  return (
    <Box
      display="flex"
      position="relative"
      alignItems="center"
      padding="19px"
      bgcolor="white"
      borderRadius="10px"
      width={CARD_WIDTH}
      height={CARD_HEIGHT}
      border={`1px solid ${getExecutionStatusColor(status)}`}
      gap="10px"
      id={id}
    >
      <Box
        width="40px"
        height="40px"
        borderRadius="8px"
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgcolor={getExecutionStatusColor(status)}
      >
        {getNodeIcon(icon, type)}
      </Box>
      <Box display="flex" flexDirection="column" marginLeft="10px">
        <Typography margin={0} variant="regular" color="gray">
          {type === "genericstep" ? t("action") : t(type)}
        </Typography>
        <Typography margin={0} variant="largeMedium">
          {label}
        </Typography>
      </Box>
      <Typography
        variant="smallBold"
        fontStyle="oblique"
        margin="8px 12px"
        position="absolute"
        top={0}
        right={0}
        style={{ cursor: "pointer" }}
      >
        <Box display="flex" alignItems="center" gap="4px">
          {retryButtonExist && (
            <Tooltip title={t("retryStep")}>
              <IconButton
                size="small"
                onClick={() => setIsRerunModalOpen(true)}
              >
                <CachedIcon
                  htmlColor={getExecutionPointerStatusColor(status)}
                  fontSize="small"
                />
              </IconButton>
            </Tooltip>
          )}
          <u
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleStatusClick}
            style={{
              color: isHovered
                ? colors.orangeDot
                : getExecutionPointerStatusColor(status),
            }}
          >
            {t(getExecutionStatusName(status))}
          </u>
        </Box>
      </Typography>
      {props.action === "TemplateBuilderStep" && props.status === 3 && (
        <InteractionButton
          color="success"
          onClick={() => {
            setShowDocViewerModal(true)
          }}
        >
          {t("view")}
        </InteractionButton>
      )}
      <LogsModal
        open={showLogModal}
        data={props}
        onClose={() => setShowLogModal(false)}
      />
      <DocumentModal
        open={showDocViewerModal}
        data={props}
        onClose={() => setShowDocViewerModal(false)}
      />

      <ConfirmActionModal
        isOpen={isRerunModalOpen}
        title={t("retryStep", {
          stepLabel: label,
        })}
        body={t("stepRerunConfirmation", {
          stepLabel: label,
        })}
        submitColor="primary"
        submitText={t("continue")}
        isLoading={isRerunning}
        closeText={t("cancel")}
        onSubmit={() =>
          retryStep({
            workflowId: workflowId!,
            executionPointerId: executionPointerId!,
          })
        }
        onClose={() => setIsRerunModalOpen(false)}
      />
    </Box>
  )
}

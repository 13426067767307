import {
  TextField,
  Grid,
  Autocomplete,
  type TextFieldProps,
  Typography,
  // FormControl,
  // RadioGroup,
  // FormControlLabel,
  // Radio,
  InputAdornment,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"

import { type Control, Controller, type FieldValues } from "react-hook-form"
import { ExpandMore } from "@mui/icons-material"
import { getAdvowareUsersAPI } from "../../../../../../services"
import {
  DUE_DATE_OPTIONS,
  PRIORITIES,
  REASONS,
  TIME_SPANS,
} from "./utils/constants"
import { useState, useEffect, useMemo } from "react"
// import { ENonWorkingDay } from "../../../../utils"
import { colors } from "../../../../../../utils"
import { useTranslation } from "react-i18next"

interface IResubmissionStepConfigProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string | string[],
    key: string,
    onChange?: (input: any) => void,
  ) => void
  control: Control<FieldValues, any>
  selectedAction: INodeItem
  formValues: Record<string, any>
}

export const ResubmissionStepConfig = ({
  handleChange,
  control,
  selectedAction,
  formValues,
}: IResubmissionStepConfigProps) => {
  const { data: users, isPending: isUsersListPending } = useQuery({
    queryKey: ["advoware-users"],
    queryFn: () => getAdvowareUsersAPI(),
  })

  const { t } = useTranslation()
  const configuration = selectedAction?.inputs?.Configuration

  const [dateHandle, setDateHandle] = useState<Record<string, any>>({
    dueDateOption: selectedAction?.inputs?.Configuration?.DueDateOption || "",
    value: selectedAction?.inputs?.Configuration?.TimespanInterval || "",
    unit: selectedAction?.inputs?.Configuration?.TimespanType || "",
  })

  const customDate = useMemo(
    () => formValues?.DueDateOption && formValues?.DueDateOption === "Custom",
    [formValues?.DueDateOption],
  )

  useEffect(() => {
    handleChange(selectedAction, "input", "1", "IfNonWorkingDay")

    const option = configuration?.DueDateOption

    let value
    let unit

    switch (option) {
      case "Next Day":
        value = "1"
        unit = "day/s"
        break
      case "In a week":
        value = "1"
        unit = "week/s"
        break
      case "In two weeks":
        value = "2"
        unit = "week/s"
        break
      case "In a month":
        value = "1"
        unit = "month/s"
        break
      default:
        value = dateHandle.value || ""
        unit = dateHandle.unit || ""
    }

    if (value !== undefined || unit !== undefined) {
      setDateHandle({
        dueDateOption: option || "",
        value,
        unit,
      })
    }
    if (option) {
      handleChange(selectedAction, "input", value, "TimespanInterval")
    }
  }, [selectedAction?.inputs?.Configuration?.DueDateOption])

  useEffect(() => {
    if (
      selectedAction?.inputs?.Configuration?.TimespanInterval &&
      !configuration?.TimespanType
    ) {
      handleChange(selectedAction, "input", dateHandle.unit, "TimespanType")
    }
  }, [selectedAction?.inputs?.Configuration?.TimespanInterval])

  return (
    <Grid container spacing={2} maxWidth="600px" alignSelf="center">
      <Grid item xs={12}>
        <Controller
          control={control}
          name="LawyerInitials"
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              fullWidth
              multiple
              limitTags={3}
              loading={isUsersListPending}
              loadingText={t("loading")}
              options={users ?? []}
              getOptionLabel={(option) => option.name}
              disabled={isUsersListPending}
              popupIcon={<ExpandMore />}
              value={
                users?.filter((option) => value?.includes(option.shortName)) ??
                []
              }
              sx={{
                "& .MuiFilledInput-root": {
                  maxHeight: "unset",
                },
              }}
              onChange={(_, selectedOptions) => {
                const mappedValue = selectedOptions?.map((o) => o.shortName)
                handleChange(
                  selectedAction,
                  "input",
                  mappedValue,
                  "LawyerInitials",
                  onChange,
                )
                onChange(mappedValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  label={t("Lawyer")}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Controller
          control={control}
          name="Reason"
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              fullWidth
              loadingText={t("loading")}
              options={REASONS.map((reason) => t(`${reason}`)) ?? []}
              getOptionLabel={(option) => option}
              popupIcon={<ExpandMore />}
              value={value}
              onChange={(_, newValue) => {
                handleChange(
                  selectedAction,
                  "input",
                  newValue,
                  "Reason",
                  onChange,
                )
                onChange(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  label={t("Reason")}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid width="100%" item xs={12}>
        <Controller
          control={control}
          name="Priority"
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              fullWidth
              loadingText={t("loading")}
              options={
                PRIORITIES.map((priority) => ({
                  ...priority,
                  label: t(priority.label),
                })) ?? []
              }
              getOptionLabel={(option) => option.label}
              popupIcon={<ExpandMore />}
              value={PRIORITIES.find((option) => option.label === value)}
              renderOption={(props, option) => (
                <li {...props}>
                  <img width="20px" height="20px" src={option.imageSrc} />
                  <Typography ml={1}>{option.label}</Typography>
                </li>
              )}
              onChange={(_, newValue) => {
                handleChange(
                  selectedAction,
                  "input",
                  newValue?.label || "",
                  "Priority",
                  onChange,
                )
                onChange(newValue?.label)
              }}
              renderInput={(params) => (
                <TextField
                  label={t("Priority")}
                  {...(params as TextFieldProps)}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                    startAdornment: value && (
                      <InputAdornment position="start">
                        <img
                          width="20px"
                          height="20px"
                          src={
                            PRIORITIES.find((option) => option.label === value)
                              ?.imageSrc || ""
                          }
                          alt={value}
                          style={{ marginBottom: "15px" }}
                        />
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h5">Due Date</Typography>
      </Grid>
      <Grid item xs={12}>
        <Controller
          control={control}
          name="DueDateOption"
          render={({ field: { value, onChange } }) => (
            <Autocomplete
              fullWidth
              options={DUE_DATE_OPTIONS.map((option) => t(option))}
              getOptionLabel={(option) => option}
              value={value}
              onChange={(_, newValue) => {
                handleChange(
                  selectedAction,
                  "input",
                  newValue,
                  "DueDateOption",
                  onChange,
                )
                setDateHandle((prev) => ({ ...prev, dueDateOption: newValue }))

                onChange(newValue)
              }}
              renderInput={(params) => (
                <TextField
                  {...(params as TextFieldProps)}
                  label={t("dueDateOptions")}
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true,
                  }}
                />
              )}
            />
          )}
        />
      </Grid>

      <Grid container spacing={1} mt={0.5} ml={1}>
        <Grid item md={4} xs={12}>
          <Controller
            control={control}
            name="TimespanInterval"
            render={({ field: { onChange } }) => (
              <TextField
                type="number"
                label={t("value")}
                value={
                  selectedAction?.inputs?.Configuration?.TimespanInterval ||
                  dateHandle.value
                }
                disabled={!customDate}
                onChange={(event) => {
                  const newValue = event.target.value
                  setDateHandle((prev) => ({ ...prev, value: newValue }))
                  handleChange(
                    selectedAction,
                    "input",
                    newValue,
                    "TimespanInterval",
                    onChange,
                  )
                  onChange(newValue)
                }}
              />
            )}
          />
        </Grid>

        <Grid item md={8} xs={12}>
          <Controller
            control={control}
            name="TimespanType"
            render={({ field: { onChange } }) => (
              <Autocomplete
                options={TIME_SPANS.map((span) => t(span))}
                getOptionLabel={(option) => option}
                disabled={!customDate}
                value={
                  selectedAction?.inputs?.Configuration?.TimespanType ||
                  dateHandle.unit
                }
                onChange={(_, newValue) => {
                  setDateHandle((prev) => ({ ...prev, unit: newValue }))
                  onChange(newValue)
                  handleChange(
                    selectedAction,
                    "input",
                    newValue,
                    "TimespanType",
                  )
                }}
                renderInput={(params) => (
                  <TextField
                    {...(params as TextFieldProps)}
                    label={t("Unit")}
                    InputProps={{
                      ...params.InputProps,
                      disableUnderline: true,
                    }}
                    fullWidth
                  />
                )}
              />
            )}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        {selectedAction?.inputs?.Configuration?.DueDateOption && (
          <Typography color={colors.red}>
            If the final calculated date falls on a non-working day, it will be
            moved to the next working day by default !
          </Typography>
        )}
      </Grid>

      {/* <Grid item xs={12}>
        <Controller
          control={control}
          name="IfNonWorkingDay"
          render={({ field: { value, onChange } }) => (
            <FormControl>
              <Typography variant="regularSemiBold">
                If the selected date is a non-working day, please choose when
                you would like the resubmission to occur:
              </Typography>
              <RadioGroup
                sx={{ display: "flex", flexDirection: "row" }}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={value}
                onChange={(_, newValue) => {
                  handleChange(
                    selectedAction,
                    "input",
                    newValue,
                    "IfNonWorkingDay",
                  )
                  onChange(newValue)
                }}
              >
                <FormControlLabel
                  value={ENonWorkingDay.LastWorkingDay}
                  control={<Radio />}
                  label="Last working day"
                />
                <FormControlLabel
                  value={ENonWorkingDay.NextWorkingDay}
                  control={<Radio />}
                  label="Next working day"
                />
              </RadioGroup>
            </FormControl>
          )}
        />
      </Grid> */}

      <Grid item xs={12}>
        <Controller
          control={control}
          name="Notes"
          render={({ field: { value, onChange } }) => (
            <TextField
              label={t("notes")}
              fullWidth
              multiline
              rows={5}
              sx={{ height: "200px" }}
              value={value ?? ""}
              onChange={(event) => {
                handleChange(
                  selectedAction,
                  "input",
                  event.target.value,
                  "Notes",
                  onChange,
                )
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  )
}

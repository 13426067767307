import {
  Box,
  DialogContent,
  DialogTitle,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Close } from "@mui/icons-material"
import { ContentWrapper, CustomDialog } from "./styled"

import { colors } from "../../../../utils"
import { useCallback } from "react"

interface IProps {
  open: boolean
  data: any
  onClose: () => void
}

export const LogsModal = (props: IProps) => {
  const { open, data, onClose } = props
  const { t } = useTranslation()

  const generateLogColor = useCallback((log: string) => {
    let color
    switch (log) {
      case "Error":
        color = colors.red
        break
      case "Information":
        color = colors.gray3
        break
      case "Warning":
        color = colors.orange
    }
    return color
  }, [])

  return (
    <CustomDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {t("logHistory")}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {data.executionLogs && data.executionLogs.length > 0 ? (
          <ContentWrapper>
            <TableContainer component={Paper} sx={{ width: "100%" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="regularBold">{t("time")}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="regularBold">
                        {t("logLevel")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="regularBold">
                        {t("message")}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.executionLogs.map((log: any, index: number) => (
                    <TableRow key={index}>
                      <TableCell>{log.Time}</TableCell>
                      <TableCell>
                        <Box
                          component="span"
                          sx={{
                            fontWeight: "bold",
                            color: generateLogColor(log.LogLevel),
                          }}
                        >
                          {log.LogLevel}
                        </Box>
                      </TableCell>
                      <TableCell sx={{ width: "100%" }}>
                        <Box
                          component="span"
                          dangerouslySetInnerHTML={{
                            __html: log.Message.replace(/\n/g, "<br />"),
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </ContentWrapper>
        ) : (
          <Box
            width="100%"
            height="100%"
            textAlign="center"
            alignContent="center"
          >
            {t("noLogs")}
          </Box>
        )}
      </DialogContent>
    </CustomDialog>
  )
}

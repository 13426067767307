import React, { useState, useEffect } from "react"
import {
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  DialogActions,
  Typography,
  Skeleton,
  FormControlLabel,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { useQuery, useMutation } from "@tanstack/react-query"
import { getMotorVehicleExpertsAPI, allowExpertsAPI } from "../../services"
import { colors } from "../../utils"
import CheckIcon from "@mui/icons-material/Check"
import { useToast } from "../../contexts"
import { StyledCheckbox } from "../UpdateGroupModal/styled"

export const ExpertSettings: React.FC<{
  organizationData: any
  onClose: () => void
  isOrganization: boolean
}> = ({ organizationData, isOrganization, onClose }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [selectedExperts, setSelectedExperts] = useState<number[]>([])
  const [isOverwritingSettings, setIsOverwritingSettings] =
    useState<boolean>(false)

  const {
    data: motorVehicleExperts,
    isLoading: areMotorVehicleExpertsLoading,
    isRefetching,
  } = useQuery({
    queryKey: ["motor-vehicle-organization"],
    queryFn: () =>
      getMotorVehicleExpertsAPI({
        id: organizationData.id,
        isShowingAll: true,
      }),
    refetchOnMount: true,
  })

  useEffect(() => {
    if (motorVehicleExperts) {
      const initiallySelected = motorVehicleExperts
        .filter((expert) => expert.isSelected)
        .map((expert) => expert.id)
      setSelectedExperts(initiallySelected)

      const allSettings = motorVehicleExperts.map(
        (expert) => expert.isOverwritingSettings,
      )
      setIsOverwritingSettings(
        allSettings.some((setting) => setting) &&
          !allSettings.every((setting) => !setting),
      )
    }
  }, [motorVehicleExperts])

  const handleToggle = (id: number) => {
    setSelectedExperts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((expertId) => expertId !== id)
        : [...prevSelected, id],
    )
  }

  const { mutate: saveExperts, isPending: isSaving } = useMutation({
    mutationFn: (data: IAllowExpertsRequest) => allowExpertsAPI(data),
    onSuccess: () => {
      toast.show(t("expertsSaved"), "success")
      onClose()
    },
    onError: () => {
      toast.show(t("errorSavingExperts"), "error")
    },
  })

  const handleSubmit = (event: any) => {
    event.preventDefault()
    saveExperts({
      id: organizationData.id,
      isOverwritingSettings: isOrganization ? false : isOverwritingSettings,
      expertIds: selectedExperts,
    })
  }

  const areSelectedExpertsLoading =
    areMotorVehicleExpertsLoading || isRefetching

  if (areSelectedExpertsLoading)
    return (
      <Box display="flex" flexDirection="column" gap="16px" padding="16px">
        {[...Array(3)].map((_, index) => (
          <Skeleton
            key={index}
            variant="rounded"
            height={50}
            sx={{ marginBottom: "16px" }}
          />
        ))}
      </Box>
    )

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="16px"
      component="form"
      onSubmit={handleSubmit}
    >
      {!isOrganization && (
        <FormControlLabel
          control={
            <StyledCheckbox
              checked={isOverwritingSettings}
              onChange={(e) => setIsOverwritingSettings(e.target.checked)}
            />
          }
          label={t("overwriteOrganizationSettings")}
          labelPlacement="end"
        />
      )}

      {(isOrganization || isOverwritingSettings) && (
        <List>
          {motorVehicleExperts?.map((expert) => (
            <ListItem
              key={expert.id}
              disablePadding
              sx={{
                padding: "8px",
                mb: 1,
                borderRadius: "5px",
                border: `1px solid ${
                  selectedExperts.includes(expert.id)
                    ? `${colors.primary}`
                    : `${colors.gray2}`
                }`,
              }}
            >
              <ListItemButton onClick={() => handleToggle(expert.id)}>
                <Typography
                  flex={1}
                  variant="regularMedium"
                  className="line-clamp-2"
                >
                  {expert.name}
                </Typography>
                {selectedExperts.includes(expert.id) && (
                  <CheckIcon color="primary" />
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}

      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {t("cancel")}
        </Button>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={isSaving}
          disabled={areMotorVehicleExpertsLoading}
        >
          {t("save")}
        </LoadingButton>
      </DialogActions>
    </Box>
  )
}

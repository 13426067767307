import { useTranslation } from "react-i18next"
import { ItemBox, SelectDropdown } from "./styled"
import { MenuItem } from "@mui/material"
import { Check } from "@mui/icons-material"

interface IProps {
  value: string[]
  options: IChannelConfiguration[]
  onChange: (event: any) => void
}

export const ChannelDropdown = (props: IProps) => {
  const { value, options, onChange } = props
  const { t } = useTranslation()

  return (
    <SelectDropdown
      multiple
      value={value}
      onChange={onChange}
      label={t("switchChannel")}
      renderValue={(selected: any) => {
        return selected
          ?.map(
            (channelId: string) =>
              options.find((channel) => channel.id === channelId)?.channelName,
          )
          ?.join(", ")
      }}
    >
      {options.length === 0 ? (
        <MenuItem disabled>{t("pleaseSelectChannelTypeFirst")}</MenuItem>
      ) : (
        <MenuItem value={undefined}>
          <i>{t("all")}</i>
        </MenuItem>
      )}
      {options.map((channel) => (
        <MenuItem key={channel.id} value={channel.id}>
          <ItemBox>
            {channel.channelName}
            {value.includes(channel.id) && (
              <Check color="primary" fontSize="small" />
            )}
          </ItemBox>
        </MenuItem>
      ))}
    </SelectDropdown>
  )
}

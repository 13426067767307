export const getFiltersCount = (filters: any) => {
  let count = 0

  if (filters.source) count++
  if (filters.startDate || filters.endDate) count++
  if (filters.from) count++
  if (filters.caseNumber) count++
  if (filters.description) count++
  if (filters.reasoning) count++
  if (filters.workflow) count++

  return count
}

export const getChannelType = (channelType: string) => {
  switch (channelType) {
    case "Email":
      return 1
    case "Database":
      return 2
    case "Interface":
      return 3
    default:
      return undefined
  }
}

import { TableContainer, TableRow, styled } from "@mui/material"
import { colors } from "../../../../utils"

interface IRowProps {
  selected: boolean
}

export const TableContainerStyled = styled(TableContainer)({
  overflowY: "scroll",
  maxHeight: "650px",
  borderBottom: 0,
  ".MuiTableHead-root": {
    ".MuiTableRow-root": {
      ".MuiTableCell-root": {
        padding: "8px",
        borderBottom: 0,
      },
    },
  },
  ".MuiTableRow-root": {
    ".MuiTableCell-root": {
      borderRight: "none !important",
    },
  },
})

export const TableRowStyled = styled(TableRow)<IRowProps>(({ selected }) => ({
  backgroundColor: selected ? `${colors.blue5} !important` : undefined,

  "&:hover": {
    cursor: "pointer !important",
  },
}))

import { Box, Select, styled } from "@mui/material"

export const SelectDropdown = styled(Select)`
  width: 180px;
`

export const ItemBox = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`

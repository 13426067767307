import React, { useMemo, useState } from "react"
import { Box, Button, Typography, TextField, Tooltip } from "@mui/material"
import { colors } from "../../../../../../utils"
import { Search } from "@mui/icons-material"
import { defineType } from "./utils"

interface FieldsProp {
  handleClick: (key: string) => void
  selectedFields: Record<string, any>
  activeTab: "general" | "logic"
  model: Record<string, any>
}

export const Fields: React.FC<FieldsProp> = (props: FieldsProp) => {
  const { handleClick, selectedFields, activeTab, model } = props

  const [fieldSearch, setFieldSearch] = useState<string>("")

  const selectedKeys = Object.keys(selectedFields)
  const filterSearchedFields = useMemo(
    () =>
      fieldSearch
        ? selectedKeys.filter((key) => {
            if (selectedFields[key]?.selected === undefined) {
              return key.toLowerCase().includes(fieldSearch.toLowerCase())
            } else
              return (
                key.toLowerCase().includes(fieldSearch.toLowerCase()) &&
                selectedFields[key]?.selected === true
              )
          })
        : selectedKeys.filter(
            (field) =>
              selectedFields[field]?.selected ||
              selectedFields[field]?.selected === undefined,
          ),
    [selectedFields, fieldSearch],
  )

  return (
    <Box sx={{ position: "relative", pt: 1, pl: 2, mb: 1 }}>
      <Typography sx={{ fontSize: 17.5, fontWeight: 700 }}>
        Field Type
      </Typography>
      <TextField
        sx={{
          position: "absolute",
          right: 15,
          top: 5,
          color: colors.gray1,
        }}
        placeholder="Search"
        variant="outlined"
        size="small"
        onChange={(e) => setFieldSearch(e.target.value)}
        InputProps={{
          startAdornment: <Search htmlColor={colors.gray1} />,
        }}
      />
      <Box
        sx={{
          maxHeight: activeTab === "general" ? "120px" : "80px",
          overflow: "auto",
          mt: 4,
        }}
      >
        {filterSearchedFields.map((_key) => {
          const type = defineType(model[_key].type)
          return (
            <Tooltip
              title={`Type: ${type}, Example: ${model[_key]}`}
              arrow
              placement="top"
              enterDelay={500}
              leaveDelay={200}
              key={_key}
            >
              <Button
                onClick={() => handleClick(_key)}
                sx={{
                  padding: "3px 8px !important",
                  color: colors.gray1,
                  fontSize: 15,
                  fontWeight: 300,
                  border: `1.5px solid ${colors.primary}`,
                  backgroundColor: "white",
                  boxShadow: "none",
                  mr: 1,
                  mb: 1,
                  "&:hover": {
                    color: colors.white,
                  },
                }}
              >
                {_key}
              </Button>
            </Tooltip>
          )
        })}
      </Box>
    </Box>
  )
}

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { CustomStack, Item } from "./styled"
import { type IFilters } from "../../utils"
import { colors } from "../../../../utils"

interface IProps {
  filters: IFilters
}

export const ActiveFiltersBar = (props: IProps) => {
  const { filters } = props
  const { t } = useTranslation()

  return (
    <CustomStack
      direction="row"
      spacing={4}
      rowGap={2}
      useFlexGap
      flexWrap="wrap"
      alignItems="center"
      maxWidth="100%"
    >
      {!!filters?.name && (
        <Item>
          <Typography variant="regularSemiBold">{t("name")}: </Typography>
          <Typography>{filters.name}</Typography>
        </Item>
      )}
      {!!filters.version && (
        <Item>
          <Typography variant="regularSemiBold">{t("version")}: </Typography>
          <Typography>{filters.version}</Typography>
        </Item>
      )}
      {filters?.status !== undefined && (
        <Item>
          <Typography variant="regularSemiBold">{t("status")}:</Typography>
          <Box
            display="flex"
            alignItems="center"
            borderRadius="24px"
            width="fit-content"
            paddingX="8px"
            border={`1px solid ${
              filters.status ? colors.primary : colors.gray1
            } `}
          >
            {" "}
            <Box
              width="8px"
              height="8px"
              borderRadius="50%"
              mr="8px"
              border={`1px solid ${
                filters.status ? colors.primary : colors.gray1
              } `}
              bgcolor={filters.status ? colors.primary : colors.white}
            />
            <Typography>
              {filters.status ? t("active") : t("inactive")}
            </Typography>
          </Box>
        </Item>
      )}
      {!!filters?.createdBy && (
        <Item>
          <Typography variant="regularSemiBold">{t("createdBy")}: </Typography>
          <Typography>{filters.createdBy}</Typography>
        </Item>
      )}
      {(!!filters?.createdAtStart || !!filters?.createdAtEnd) && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("createdDate")}:{" "}
          </Typography>
          <Typography>
            {filters.createdAtStart
              ? dayjs(filters.createdAtStart).format("DD.MM.YYYY")
              : "♾️"}{" "}
            -{" "}
            {filters.createdAtEnd
              ? dayjs(filters.createdAtEnd).format("DD.MM.YYYY")
              : "♾️"}
          </Typography>
        </Item>
      )}
      {!!filters?.updatedBy && (
        <Item>
          <Typography variant="regularSemiBold">{t("updatedBy")}: </Typography>
          <Typography>{filters.updatedBy}</Typography>
        </Item>
      )}
      {(!!filters?.updatedAtStart || !!filters?.updatedAtEnd) && (
        <Item>
          <Typography variant="regularSemiBold">
            {t("updatedDate")}:{" "}
          </Typography>
          <Typography>
            {filters.updatedAtStart
              ? dayjs(filters.updatedAtStart).format("DD.MM.YYYY")
              : "♾️"}{" "}
            -{" "}
            {filters.updatedAtEnd
              ? dayjs(filters.updatedAtEnd).format("DD.MM.YYYY")
              : "♾️"}
          </Typography>
        </Item>
      )}
    </CustomStack>
  )
}

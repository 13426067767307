import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material"
import { useCallback, useEffect } from "react"
import { Controller, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { postActivateDefinitionVersionAPI } from "../../../../services"

interface IFormData {
  name: string
  description: string
}

interface IProps {
  isOpen: boolean
  onClose: () => void
  onClickSubmit: (definitionDetails: IFormData, buttonClicked: string) => void
}

export const CreateDefinitionModal = (props: IProps) => {
  const { isOpen, onClose, onClickSubmit } = props
  const { t } = useTranslation()

  const { control, getValues, reset } = useForm<IFormData>({
    defaultValues: {
      name: "",
      description: "",
    },
  })

  const onSubmit = useCallback(
    (data: IFormData, buttonClicked: string) => {
      onClickSubmit(data, buttonClicked)
      onClose()
      if (buttonClicked === "Create and activate") {
        void postActivateDefinitionVersionAPI(data.name, 1)
      }
    },

    [onClickSubmit],
  )

  useEffect(() => {
    reset()
  }, [isOpen])

  return (
    <Dialog
      component="form"
      open={isOpen}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle>{t("newDefinition")}</DialogTitle>
      <DialogContent>
        <Controller
          control={control}
          name="name"
          rules={{ required: t("required") }}
          render={({ field: { value, onChange }, formState: { errors } }) => (
            <TextField
              fullWidth
              label={t("name")}
              value={value}
              onChange={onChange}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        <Box height="12px" />
        <Controller
          control={control}
          name="description"
          render={({ field: { value, onChange } }) => (
            <TextField
              label={t("description")}
              value={value}
              onChange={onChange}
              fullWidth
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Box width="100%" display="flex" justifyContent="space-between">
          <Button variant="outlined" onClick={onClose}>
            {t("cancel")}
          </Button>
          <Box display="flex" gap="12px">
            <Button
              onClick={(event: any) =>
                onSubmit(getValues(), event.target?.innerText)
              }
              variant="text"
            >
              {t("saveAsDraft")}
            </Button>
            <Button
              onClick={(event: any) =>
                onSubmit(getValues(), event.target?.innerText)
              }
            >
              {t("createAndActivate")}
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  )
}

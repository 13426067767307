import { useMemo, type Dispatch, type SetStateAction } from "react"
import { type IInboxFilters, type IQuickFilters } from "../../utils"
import {
  CircularProgress,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  type SelectChangeEvent,
  Switch,
} from "@mui/material"
import {
  CustomFormControl,
  FilteringWrapper,
  ItemBox,
  SearchTextField,
  SelectDropdown,
  ToggleWrapper,
} from "./styled"
import { useTranslation } from "react-i18next"
import { Check, Search } from "@mui/icons-material"
import {
  FilterComponent,
  ChannelDropdown,
  ExecutionTypeDropdown,
} from "./components/"
import { useQuery } from "@tanstack/react-query"
import { getAllChannelConfigurationsAPI } from "../../../../services"

interface IProps {
  quickFilters: IQuickFilters
  filters: IInboxFilters
  onQuickFiltersChange: Dispatch<SetStateAction<IQuickFilters>>
  onFiltersChange: Dispatch<SetStateAction<IInboxFilters>>
}

export const TableActions = (props: IProps) => {
  const { quickFilters, filters, onQuickFiltersChange, onFiltersChange } = props
  const { t } = useTranslation()

  const { data: channelConfigurations, isPending: isPendingConfigurations } =
    useQuery({
      queryKey: ["channel-configurations"],
      queryFn: () => getAllChannelConfigurationsAPI(),
      refetchOnMount: true,
    })

  const channelTypes = useMemo(() => {
    const uniqueChannelTypes = new Set(
      channelConfigurations?.map((config) => config.channelType) ?? [],
    )
    return Array.from(uniqueChannelTypes)
  }, [channelConfigurations])

  const channelIds = useMemo(
    () =>
      channelConfigurations?.filter(
        (config) => config.channelType === quickFilters.channelType,
      ) ?? [],
    [quickFilters.channelType, channelConfigurations],
  )

  return (
    <ToggleWrapper>
      <FormControlLabel
        label={t("multipleMessages")}
        control={
          <Switch
            checked={quickFilters.showMultipleMessages}
            onChange={() =>
              onQuickFiltersChange((prev) => ({
                ...prev,
                showMultipleMessages: !prev.showMultipleMessages,
              }))
            }
          />
        }
      />
      <FilteringWrapper>
        <CustomFormControl>
          <InputLabel>{t("channelType")}</InputLabel>
          <SelectDropdown
            label={t("channelType")}
            name="channelType"
            value={quickFilters?.channelType}
            onChange={(e: SelectChangeEvent<any>) => {
              onQuickFiltersChange((prev) => ({
                ...prev,
                channelIds: [],
                channelType: e.target.value,
              }))
            }}
            renderValue={(selected: any) => selected}
          >
            {isPendingConfigurations ? (
              <MenuItem disabled>
                <CircularProgress sx={{ mr: "4px" }} size="12px" />
                <i>{t("loading")}</i>
              </MenuItem>
            ) : (
              <MenuItem value={undefined}>
                <i>{t("all")}</i>
              </MenuItem>
            )}
            {channelTypes.map((channelType) => (
              <MenuItem key={channelType} value={channelType}>
                <ItemBox>
                  {t(channelType)}
                  {quickFilters.channelType === channelType && (
                    <Check color="primary" fontSize="small" />
                  )}
                </ItemBox>
              </MenuItem>
            ))}
          </SelectDropdown>
        </CustomFormControl>
        <CustomFormControl>
          <InputLabel>{t("switchChannel")}</InputLabel>
          <ChannelDropdown
            value={quickFilters?.channelIds}
            options={channelIds}
            onChange={(e: SelectChangeEvent<any>) => {
              const {
                target: { value },
              } = e
              if (value?.includes(undefined)) {
                onQuickFiltersChange((prev) => ({
                  ...prev,
                  channelIds: [],
                }))
              } else {
                onQuickFiltersChange((prev) => ({
                  ...prev,
                  channelIds:
                    typeof value === "string" ? value.split(",") : value,
                }))
              }
            }}
          />
        </CustomFormControl>
        <CustomFormControl>
          <InputLabel>{t("executionType")}</InputLabel>
          <ExecutionTypeDropdown
            value={quickFilters?.executionType}
            onChange={(e: SelectChangeEvent<any>) => {
              const {
                target: { value },
              } = e
              if (value?.includes(undefined)) {
                onQuickFiltersChange((prev) => ({
                  ...prev,
                  executionType: [],
                }))
              } else {
                onQuickFiltersChange((prev) => ({
                  ...prev,
                  executionType:
                    typeof value === "string"
                      ? value.split(",").map((o) => +o)
                      : value,
                }))
              }
            }}
          />
        </CustomFormControl>
        <SearchTextField
          variant="outlined"
          placeholder={t("search")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          onChange={(event) => {
            onQuickFiltersChange((prev) => ({
              ...prev,
              search: event.target.value,
            }))
          }}
        />
        <FilterComponent filters={filters} onFilterChange={onFiltersChange} />
      </FilteringWrapper>
    </ToggleWrapper>
  )
}

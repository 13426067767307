import { Box } from "@mui/material"
import { BlacklistedPanel, GlobalPanel, TrashPanel } from "./components"
import { type IInboxFilters, type IQuickFilters } from "../../utils"
import { type Dispatch, type SetStateAction } from "react"

interface IProps {
  activeTab: string
  tablePage: number
  tableFilters: { filters: IInboxFilters; quickFilters: IQuickFilters }
  onChangeTablePage: Dispatch<SetStateAction<number>>
}
export const TabPanel = (props: IProps) => {
  const { activeTab, tablePage, tableFilters, onChangeTablePage } = props

  return (
    <Box role="tabpanel">
      {activeTab === "global" && (
        <GlobalPanel
          tablePage={tablePage}
          tableFilters={tableFilters}
          onChangeTablePage={onChangeTablePage}
        />
      )}
      {activeTab === "blacklisted" && (
        <BlacklistedPanel
          tablePage={tablePage}
          tableFilters={tableFilters}
          onChangeTablePage={onChangeTablePage}
        />
      )}
      {activeTab === "trash" && (
        <TrashPanel
          tablePage={tablePage}
          tableFilters={tableFilters}
          onChangeTablePage={onChangeTablePage}
        />
      )}
    </Box>
  )
}

import Box from "@mui/material/Box"
import { ClickableLabel } from "../ClickableLabel"
import { useTranslation } from "react-i18next"

interface IProps {
  transparent?: boolean
}

export const LegalFooter = (props: IProps) => {
  const { transparent } = props
  const { t } = useTranslation()

  const links = [
    { href: "https://www.werner-kollegen.com/agb/", labelKey: "terms" },
    {
      href: "https://www.werner-kollegen.com/datenschutz/",
      labelKey: "privacyPolicy",
    },
    {
      href: "https://www.werner-kollegen.com/impressum/",
      labelKey: "impressum",
    },
  ]

  return (
    <Box display="flex" gap="4px">
      {links.map((link, index) => (
        <ClickableLabel
          key={link.href}
          href={link.href}
          variant="smallBold"
          target="_blank"
          transparent={transparent}
        >
          {t(link.labelKey)}
          {index < links.length - 1 && (
            <Box marginLeft="4px" component="span">
              |
            </Box>
          )}
        </ClickableLabel>
      ))}
    </Box>
  )
}

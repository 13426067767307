import { axios } from "../lib"

export const getClaimTemplateAPI = (
  params: IGetClaimTemplateParams,
): Promise<IClaimTemplate> => axios.get("/ClaimTemplate/metadata", { params })

export const getClaimsListingAPI = (
  params: IGetClaimsParams,
): Promise<IClaimsTotal> => axios.get("/Claim/paged", { params })

export const createClaimAPI = (
  requestParams: ICreateClaimRequestParams,
): Promise<IClaimDetails> =>
  axios.post("/Claim", requestParams.body, { params: requestParams.params })

export const getClaimAPI = (
  requestParams: IGetClaimRequestParams,
): Promise<IClaimDetails> =>
  axios.get(`Claim/${requestParams.id}`, {
    params: requestParams.params,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...requestParams.headers,
    },
  })

export const updateClaimAPI = (
  requestParams: IUpdateClaimRequestParams,
): Promise<IClaimDetails> =>
  axios.patch(`Claim/${requestParams.id}`, requestParams.body, {
    params: requestParams?.params,
  })

export const getClaimDocuments = (claimId: string): Promise<IClaimFile[]> =>
  axios.get(`Document/${claimId}/documents`)

export const uploadClaimDocumentsAPI = (
  requestParams: IClaimDocumentUploadRequestParams,
): Promise<IClaimDocument[]> =>
  axios.post(
    `Document/${requestParams.claimId}/documents`,
    requestParams.body,
    {
      params: requestParams.params,
      headers: {
        "Content-Type": "multipart/form-data",
        ...requestParams.headers,
      },
    },
  )

export const deleteDocumentAPI = (body: IDeleteDocumentBody): Promise<void> =>
  axios.delete(`Document/${body.claimId}/documents/${body.documentId}`)

export const syncAdwovareAPI = (
  requestParams: ISyncAdwovareRequestParams,
): Promise<string> =>
  axios.post(`Claim/sync-adwovare/${requestParams.id}`, undefined, {
    params: requestParams.params,
  })

export const updateSearchFiltersAPI = (
  requestParams: IUpdateSearchFiltersParams,
): Promise<IClaimListingFilters> =>
  axios.put("/Group/update-search-filters", requestParams.body, {
    params: requestParams.params,
  })

export const deleteClaimAPI = (
  requestParams: IDeleteClaimRequestParams,
): Promise<void> =>
  axios.delete(`Claim/${requestParams.id}/delete`, {
    params: requestParams.params,
  })

export const closeCaseAPI = (
  requestParams: ICloseClaimRequestParams,
): Promise<void> =>
  axios.put(`Claim/${requestParams.id}/close`, undefined, {
    params: requestParams.params,
  })

export const getClaimCostsAPI = (id: string): Promise<IClaimCosts[]> =>
  axios.get(`Claim/${id}/costs`)

export const putClaimCostsAPI = (
  requestParams: IClaimCostsRequestParams,
): Promise<IClaimCosts[]> =>
  axios.put(`Claim/${requestParams.id}/costs`, requestParams.body)

export const updateLiabilityAPI = (
  requestParams: IUpdateLiabilityRequestParams,
): Promise<void> =>
  axios.put(`Claim/${requestParams.id}/liability`, undefined, {
    params: requestParams.params,
  })

export const updateStatusAPI = (
  requestParams: IUpdateStatusRequestParams,
): Promise<TStatus[]> =>
  axios.put(
    `Claim/${requestParams.id}/status/${requestParams.status}`,
    undefined,
    {
      params: requestParams.params,
    },
  )

export const getClaimComparisonAPI = (
  requestParams: string,
): Promise<IClaimDetails> => axios.get(`Claim/comparison/${requestParams}`)

export const postClaimAnkundAPI = (id: string): Promise<IPostAnkundResponse> =>
  axios.post(`Claim/${id}/ankund`, {
    id,
  })

export const postClaimAnspruchAPI = (
  id: string,
): Promise<IPostAnkundResponse> =>
  axios.post(`Claim/${id}/anspruch`, {
    id,
  })

export const generateDocumentAPI = (
  requestParams: IGenerateDocumentRequestParams,
): Promise<IDocumentTemplate> =>
  axios.post("document/prefill-template", requestParams.body, {
    params: requestParams.params,
    headers: {
      "Content-Type": "multipart/form-data",
      ...requestParams.headers,
    },
  })

export const generateDocumentExternalAPI = (
  requestParams: IGenerateDocumentRequestParams,
): Promise<IDocumentTemplate> =>
  axios.post("document/prefill-template-magiclink", requestParams.body, {
    params: requestParams.params,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  })

export const sendMagicLinkAPI = (
  requestParams: ISendMagicLinkRequestParams,
): Promise<string> =>
  axios.post("document/send-magic-link", undefined, {
    params: requestParams.params,
    headers: requestParams.headers,
  })

export const extendLockTimeAPI = (
  params: IExtendLockTimeParams,
): Promise<string> =>
  axios.post("Claim/extend-lock-time", undefined, {
    params,
  })

export const getVehiclesAPI = (
  params: IGetVehiclesParams,
): Promise<IVehicles> => axios.get("Vehicle/paged", { params })

export const createVehicleAPI = (body: ICreateVehicleBody): Promise<IVehicle> =>
  axios.post("Vehicle", body)

export const updateVehicleAPI = (
  requestParams: IUpdateVehicleRequestParams,
): Promise<IVehicle> =>
  axios.put(`Vehicle/${requestParams.id}`, requestParams.body)

export const deleteVehicleAPI = (id: string): Promise<string> =>
  axios.delete(`Vehicle/${id}`)

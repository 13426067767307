import { axios } from "../lib"

export const getStatisticsAPI = (
  params: IGetStatisticsParams,
): Promise<IStatistics> => axios.get("/Dashboard/statistics", { params })

export const getActivitySummaryAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/activity-summary", { params })

export const getClaimActivitySummaryAPI = (
  claimId: string,
): Promise<IDashboardTableData> =>
  axios.get(`/Dashboard/activity-summary/${claimId}`)

export const getOpenActivitiesAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/open-activities", { params })

export const getClaimsOutOfSyncAPI = (
  params: IGetDashboardTableParams,
): Promise<IDashboardTable> =>
  axios.get("/Dashboard/claims-out-of-sync", { params })

export const getLatestNewsAPI = (): Promise<INews[]> =>
  axios.get("/Dashboard/news")

export const getEditWidgetsAPI = (
  groupId: string,
): Promise<DashboardWidgetVisibility> =>
  axios.get("/Dashboard/widget-editor", { params: { groupId } })

export const updateWidgetVisibilityAPI = (
  groupId: string,
  visibility: DashboardWidgetVisibility,
): Promise<DashboardWidgetVisibility> =>
  axios.post("/Dashboard/widget-editor", visibility, { params: { groupId } })

export const getSearchResultsAPI = (
  params: IGetSearchParams,
): Promise<ISearchResults> => axios.get("/Dashboard/search", { params })

export const getStatusChangesAPI = (
  params: IGetDashboardChangesParams,
): Promise<IStatusChange[]> =>
  axios.get(`/Dashboard/status-changes/${params.claimId}`, { params })

export const getLiabilityChangesAPI = (
  params: IGetDashboardChangesParams,
): Promise<ILiabilityChange[]> =>
  axios.get(`/Dashboard/liability-changes/${params.claimId}`, { params })

export const getCostTableChangesAPI = (
  params: IGetDashboardChangesParams,
): Promise<ICostTableChange[]> =>
  axios.get(`/Dashboard/cost-table-changes/${params.claimId}`, { params })

export const getClaimChangesAPI = (
  params: IGetDashboardChangesParams,
): Promise<IClaimDataChange[]> =>
  axios.get(`/Dashboard/claim-changes/${params.claimId}`, { params })

export const getMalfunctionsAPI = (): Promise<IMalfunctions> =>
  axios.get("/Dashboard/malfunctions")

export const allowExpertsAPI = (params: IAllowExpertsRequest): Promise<void> =>
  axios.post("Expert/allow-experts", params.expertIds, {
    params: {
      id: params.id,
      isOverwritingSettings: params.isOverwritingSettings,
    },
  })

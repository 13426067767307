import { memo, useCallback, useMemo, useState } from "react"
import { determineFileType } from "../../../../utils"
import { useToast } from "../../../../contexts"
import { useQuery } from "@tanstack/react-query"
import {
  getInsuranceCompaniesAPI,
  getLessorsAPI,
  getManufacturersAPI,
  getMotorVehicleExpertsAPI,
} from "../../../../services"
import { Box } from "@mui/material"
import { type ISectionsProps } from "../../types"
import { SectionItem } from "../SectionItem"
import { DocPreviewModal } from "../../../../components"

interface IDocPreviewProps {
  show: boolean
  file: IClaimFile
}

export const SectionsList = memo((props: ISectionsProps) => {
  const { templateSections, template } = props

  const sections = useMemo(
    () =>
      templateSections ||
      template?.steps
        ?.flat()
        ?.map((s) => s.sections)
        ?.flat(),
    [templateSections, template],
  )

  const toast = useToast()

  const {
    data: liabilityInsuranceCompanies,
    isLoading: areLiabilityInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["liability-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "Liability" }),
  })

  const {
    data: legalExpensesInsuranceCompanies,
    isLoading: areLegalExpensesInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["legal-expenses-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "LegalExpenses" }),
  })

  const {
    data: motorVehicleExperts,
    isLoading: areMotorVehicleExpertsLoading,
  } = useQuery({
    queryKey: ["motor-vehicle-expert"],
    queryFn: () =>
      getMotorVehicleExpertsAPI({
        id: props.claim.groupId,
        isShowingAll: false,
      }),
    refetchOnMount: true,
  })

  const { data: manufacturers, isLoading: areManufacturersLoading } = useQuery({
    queryKey: ["manufacturers"],
    queryFn: () => getManufacturersAPI(),
  })

  const { data: lessorsLeasing, isLoading: areLessorsLeasingLoading } =
    useQuery({
      queryKey: ["lessors-leasing"],
      queryFn: () => getLessorsAPI({ isLeasing: true }),
    })

  const { data: lessorsFunding, isLoading: areLessorsFundingLoading } =
    useQuery({
      queryKey: ["lessors-funding"],
      queryFn: () => getLessorsAPI({ isLeasing: false }),
    })

  const liabilityInsuranceCompanyOptions = useMemo(
    () =>
      liabilityInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [liabilityInsuranceCompanies],
  )

  const legalExpensesInsuranceCompanyOptions = useMemo(
    () =>
      legalExpensesInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [legalExpensesInsuranceCompanies],
  )

  const motorVehicleExpertOptions = useMemo(
    () =>
      motorVehicleExperts?.map((expert) => ({
        name: expert.name,
        value: expert.name,
      })),
    [motorVehicleExperts],
  )

  const [docPreviewModal, setDocPreviewModal] = useState<IDocPreviewProps>({
    show: false,
    file: {
      id: "",
      name: "",
      url: "",
    },
  })

  const onFilePreviewClick = useCallback((file: IClaimFile) => {
    if (determineFileType(file.name) !== "unsupported") {
      setDocPreviewModal({
        show: true,
        file,
      })
    } else {
      toast.show("Unsupported file type, please download to view.", "error")
    }
  }, [])

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      gap="24px"
      className="scroll"
      paddingX="24px"
    >
      <DocPreviewModal
        open={docPreviewModal.show}
        file={docPreviewModal.file}
        fileType={determineFileType(docPreviewModal.file.name)}
        onClose={() => {
          setDocPreviewModal({
            show: false,
            file: { id: "", name: "", url: "" },
          })
        }}
      />
      <Box />
      {sections?.map((se) => (
        <SectionItem
          key={se.id}
          {...props}
          section={se}
          onFilePreviewClick={onFilePreviewClick}
          legalExpensesInsuranceCompanyOptions={
            legalExpensesInsuranceCompanyOptions
          }
          lessorsLeasingOptions={lessorsLeasing}
          lessorsFundingOptions={lessorsFunding}
          manufacturersOptions={manufacturers}
          motorVehicleExpertOptions={motorVehicleExpertOptions}
          liabilityInsuranceCompanyOptions={liabilityInsuranceCompanyOptions}
          areLegalExpensesInsuranceCompanyOptionsLoading={
            areLegalExpensesInsuranceCompaniesLoading
          }
          areLessorsLeasingOptionsLoading={areLessorsLeasingLoading}
          areLiabilityInsuranceCompanyOptionsLoading={
            areLiabilityInsuranceCompaniesLoading
          }
          areManufacturersOptionsLoading={areManufacturersLoading}
          areMotorVehicleExpertOptionsLoading={areMotorVehicleExpertsLoading}
          areLessorsFundingOptionsLoading={areLessorsFundingLoading}
        />
      ))}
      <Box />
    </Box>
  )
})

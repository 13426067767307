import { useEffect, useMemo, useState } from "react"
import {
  Controller,
  type UseFormReturn,
  type Control,
  type FieldValues,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { fetchFile, initFieldMap } from "./utils"
import { Box, Typography } from "@mui/material"
import { Description } from "@mui/icons-material"
import EditIcon from "@mui/icons-material/Edit"
import { WordTemplateModal } from "./WordTemplateModal"
import MenuItemFileUpload from "./MenuItemFileUpload"
import { useToast } from "../../../../../../contexts"
import { getTemplateListAPI } from "../../../../../../services/workflow"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { colors } from "../../../../../../utils"
import { getStepData } from "../../utils"
import { type Node } from "reactflow"
import {
  UploadBox,
  ControllerBox,
  ControllerBoxWrapper,
  TemplateSelectButton,
  EditButton,
} from "./styled"

interface ITemplateProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string | Record<any, any>,
    key: string,
    onChange: (input: any) => void,
  ) => void
  control: Control<FieldValues, any>
  selectedAction: INodeItem
  nodes: Node[]
  methods: UseFormReturn<FieldValues, any, undefined>
  fields: Record<string, any>
}

export const TemplateConfig = (props: ITemplateProps) => {
  const { handleChange, control, selectedAction, nodes, fields } = props
  const { t } = useTranslation()
  const [showDialog, setShowDialog] = useState(false)
  const [fieldMap, setFieldMap] = useState<Record<string, string | null>>({})
  const [changedFieldMap, setChangedFieldMap] = useState<
    Record<string, string | null>
  >({})
  const toast = useToast()

  const BASE_TEMPLATE_URL =
    "https://westdevelopment.blob.core.windows.net/workflow-templates/"

  const getDataInputs = getStepData(nodes, "GetDataStep")?.inputs

  const dummyFields: Record<string, any> = useMemo(() => {
    let selFields: any =
      getDataInputs?.SelectedFields !== undefined
        ? Object.values(getDataInputs?.SelectedFields).reduce(
            (acc: any, el: any) => {
              return { ...acc, ...el }
            },
            {},
          )
        : {}

    if (
      Object.keys(selFields).length === 0 ||
      !Object.keys(selFields).some((field: any) => selFields[field]?.selected)
    ) {
      selFields = fields
    }

    return selFields
  }, [nodes])

  const fileUrl = useMemo(() => {
    const templateInputs = getStepData(nodes, "TemplateBuilderStep")?.inputs
    const u =
      templateInputs?.TemplateFileUrl?.slice(1, -1)?.replace(/'/g, "") ?? ""

    return u
  }, [nodes])

  const templateFields = useMemo(() => {
    const templateInputs = getStepData(nodes, "TemplateBuilderStep")?.inputs

    if (
      templateInputs?.TemplateFields !== undefined &&
      templateInputs?.TemplateFields !== null
    ) {
      return templateInputs?.TemplateFields
    }
    return {}
  }, [nodes])

  const [templateUrl, setTemplateUrl] = useState(fileUrl)
  const [document, updateDocument] = useState([{ uri: templateUrl }])
  const [templateName, setTemplateName] = useState(
    templateUrl.split("/").pop() || "",
  )

  const { data: templates } = useQuery({
    queryKey: ["workflow-file-templates"],
    queryFn: () => getTemplateListAPI(),
  })

  const queryClient = useQueryClient()

  useEffect(() => {
    if (templateUrl) {
      fetchFile(templateUrl)
        .then((zip) => {
          initFieldMap(zip)
            .then((newMap) => {
              setFieldMap(newMap)
              setChangedFieldMap(newMap)
              updateDocument([{ uri: templateUrl }])
              setTemplateName(templateUrl.split("/").pop() || "")
            })
            .catch((error) => {
              toast.show(`Failed to extract placeholders: ${error}`, "error")
            })
        })
        .catch((error) => {
          toast.show(error.message || t("failedLoadingFile"), "error")
        })
    }
  }, [templateUrl, templateName])

  const handleSave = () => {
    handleChange(
      selectedAction,
      "input",
      changedFieldMap,
      "TemplateFields",
      () => {},
    )
  }

  const handleUpload = (link: string) => {
    handleChange(
      selectedAction,
      "input",
      "'" + link + "'",
      "TemplateFileUrl",
      () => {},
    )
    setTemplateName(link.split("/").pop() || "")
    setTemplateUrl(link)
  }

  const handleTemplateSelect = (name: string) => {
    handleUpload(BASE_TEMPLATE_URL.concat(name))
  }

  const handleFileUpload = (link: string) => {
    const name = link.split("/").pop() || ""
    uploadMutation.mutate(decodeURIComponent(name))
  }

  const uploadMutation = useMutation({
    mutationFn: (name: string) => {
      return new Promise<string>((resolve) => {
        handleUpload(name)
        resolve(name)
      })
    },
    onSuccess: () => {
      queryClient.setQueryData(
        ["workflow-file-templates"],
        (oldTemplates: any[] = []) => oldTemplates,
      )
    },
  })

  return (
    <>
      <Typography variant="large" mb="12px">
        Select an existing or upload your custom template
      </Typography>
      {Object.keys(dummyFields).length === 0 && (
        <Typography sx={{ ml: 1 }} color="error">
          {t("noDummyDataError")}
        </Typography>
      )}

      <UploadBox>
        <MenuItemFileUpload
          allowMultiple={false}
          allowedExtensions={[".docx"]}
          handleUpload={handleFileUpload}
        />
      </UploadBox>
      <Typography sx={{ fontSize: 17, ml: 1.5, mt: 5, mb: 1 }}>
        Select Template
      </Typography>
      <Controller
        control={control}
        name="TemplateType"
        render={() => (
          <ControllerBox>
            {templates?.map((name: any, index: number) => (
              <ControllerBoxWrapper
                templateName={templateName}
                name={name}
                key={index}
              >
                <TemplateSelectButton
                  onClick={() => handleTemplateSelect(name)}
                  templateName={templateName}
                  name={name}
                >
                  <Description
                    style={{ fontSize: "70px", color: colors.primary }}
                  />
                  <Typography
                    variant="smallBold"
                    sx={{ marginTop: 1, wordWrap: "break-word" }}
                  >
                    {name}
                  </Typography>
                </TemplateSelectButton>
                {templateName === name && (
                  <EditButton
                    templateName={templateName}
                    name={name}
                    size="small"
                    onClick={() => setShowDialog(!showDialog)}
                    disabled={
                      Object.keys(fieldMap).length === 0 ||
                      Object.keys(dummyFields).length === 0
                    }
                  >
                    <EditIcon sx={{ width: "15%", marginRight: 1 }} />
                    <Box sx={{ width: "85%", fontSize: 11 }} component="span">
                      {t("editTemplateFields")}
                    </Box>
                  </EditButton>
                )}
              </ControllerBoxWrapper>
            ))}
          </ControllerBox>
        )}
      />
      {showDialog && (
        <WordTemplateModal
          open={showDialog}
          data={{
            changedFieldMap,
            templateFields,
            document,
            dummyFields,
            setChangedFieldMap,
          }}
          onUpdateFields={handleSave}
          onClose={() => setShowDialog(false)}
        />
      )}
    </>
  )
}

import React, { useState, useEffect, useMemo } from "react"
import { Dialog, DialogTitle, DialogContent, Tabs, Tab } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CommunicationSettings } from "../CommunicationSettings"
import { OrganizationGeneralSettings } from "./components"
import { useQuery } from "@tanstack/react-query"
import { ExpertSettings } from "../ExpertSettings/ExpertSettings"

interface UpdateOrganizationModalProps {
  open: boolean
  onClose: () => void
  organizationData?: any
}

export const EditOrganizationModal: React.FC<UpdateOrganizationModalProps> = ({
  open,
  onClose,
  organizationData,
}) => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = useState(0)
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const handleTabChange = (_: any, newValue: number) => {
    setActiveTab(newValue)
  }

  const hasPermissionForCommunicationSettings = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "EMAIL_CONFIG" && p.access === "Delete",
      ),
    [user],
  )

  const hasPermissionForExpertSettings = useMemo(
    () =>
      user?.permissions?.some(
        (p) => p.action === "EXPERTS_CONFIG" && p.access === "Delete",
      ),
    [user],
  )

  useEffect(() => {
    if (!open) {
      setActiveTab(0)
    }
  }, [open])

  return (
    <Dialog open={open} onClose={onClose} sx={{ maxWidth: "100%" }}>
      <DialogTitle>{t("editOrganization")}</DialogTitle>
      {(hasPermissionForCommunicationSettings ||
        hasPermissionForExpertSettings) && (
        <>
          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            sx={{ marginX: "20px" }}
          >
            <Tab label={t("generalSettings")} />
            <Tab label={t("communicationSettings")} />
            <Tab label={t("expert")} />
          </Tabs>
        </>
      )}
      <DialogContent>
        {activeTab === 0 && (
          <OrganizationGeneralSettings
            organizationData={organizationData}
            onClose={onClose}
            open={open}
          />
        )}
        {activeTab === 1 && hasPermissionForCommunicationSettings && (
          <CommunicationSettings
            organizationData={organizationData}
            isOrganization={true}
            onClose={onClose}
          />
        )}
        {activeTab === 2 && hasPermissionForExpertSettings && (
          <ExpertSettings
            organizationData={organizationData}
            isOrganization={true}
            onClose={onClose}
          />
        )}
      </DialogContent>
    </Dialog>
  )
}

import { Box } from "@mui/material"
import { memo, useMemo } from "react"
import { MagicLinkAlert } from "../MagicLinkAlert/MagicLinkAlert"

interface IProps {
  claim: IClaim
  disabled: boolean
  subsection: IClaimTemplateSection
}

export const MagicLinkAlerts = memo((props: IProps) => {
  const { claim, subsection } = props

  const magicEmailLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "Email",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  const magicSmsLinkInfo = useMemo(() => {
    return claim?.magicLinkInformation
      ?.filter(
        (i) =>
          i.documentType === subsection?.sectionType &&
          !i.isUsedForSigning &&
          // dayjs(new Date()).isBefore(new Date(i.expiresOn)) &&
          i.communicationChannel === "SMS",
      )
      ?.sort(
        (a, b) =>
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime(),
      )
      ?.pop()
  }, [claim, subsection])

  if (!magicEmailLinkInfo && !magicSmsLinkInfo) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {!!magicEmailLinkInfo && (
        <MagicLinkAlert
          {...props}
          type="Email"
          magicLinkInfo={magicEmailLinkInfo}
        />
      )}
      {!!magicSmsLinkInfo && (
        <MagicLinkAlert
          {...props}
          type="SMS"
          magicLinkInfo={magicSmsLinkInfo}
        />
      )}
    </Box>
  )
})

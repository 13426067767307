import { Box, CircularProgress, ClickAwayListener } from "@mui/material"
import { TableContainerStyled, TableStyled } from "./styled"
import {
  type ChangeEventHandler,
  useCallback,
  useEffect,
  useState,
} from "react"
import {
  VehiclesTableBody,
  VehiclesTableFooter,
  VehiclesTableHead,
} from "./components"
import { useQuery } from "@tanstack/react-query"
import { getVehiclesAPI } from "../../../../services"
import { useAppContext } from "../../../../contexts"

interface IProps {
  filters: Pick<IGetVehiclesParams, "search" | "allVehicles">
  onEditVehicleClick?: (vehicle: IVehicle) => void
  onDeleteVehicleClick?: (vehicle: IVehicle) => void
}

export const VehiclesListTable = (props: IProps) => {
  const {
    filters: filtersProp,
    onEditVehicleClick,
    onDeleteVehicleClick,
  } = props
  const {
    state: { groupId },
  } = useAppContext()

  const initialFilters = {
    search: undefined as string | undefined,
    sortField: "createdAt" as TGetVehiclesSort,
    isDescending: true,
    page: 1,
    pageSize: 20,
    groupId,
    ...filtersProp,
  }

  const [filters, setFilters] = useState(initialFilters)

  const { data, isLoading, isRefetching, refetch } = useQuery({
    queryKey: ["vehicles"],
    queryFn: () => getVehiclesAPI(filters),
  })

  useEffect(() => {
    setFilters({
      ...filters,
      page: 1,
      ...filtersProp,
    })
  }, [filtersProp])

  useEffect(() => {
    void refetch()
  }, [filters])

  const handleSort = useCallback(
    (_: React.MouseEvent<unknown>, property: keyof IVehicle) =>
      setFilters((prev) => ({
        ...prev,
        page: 1,
        sortField: property as TGetVehiclesSort,
        isDescending:
          prev.sortField === (property as TGetVehiclesSort)
            ? !prev.isDescending
            : false,
      })),
    [],
  )

  const onPageChange = useCallback(
    (_: React.MouseEvent<HTMLButtonElement> | null, page: number) =>
      setFilters((prev) => ({ ...prev, page: page + 1 })),
    [],
  )

  const onPageSizeChange: ChangeEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = useCallback(
    (e) =>
      setFilters((prev) => ({
        ...prev,
        pageSize: parseInt(e.target.value, 10),
      })),
    [],
  )

  if (isLoading && !data) {
    return (
      <Box flex={1} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    )
  }

  return (
    <ClickAwayListener onClickAway={() => null}>
      <TableContainerStyled>
        <TableStyled>
          <VehiclesTableHead
            order={filters.isDescending ? "desc" : "asc"}
            orderBy={filters.sortField}
            onRequestSort={handleSort}
          />
          <VehiclesTableBody
            rows={data?.vehicles ?? []}
            isRefetching={isRefetching || (isLoading && !!data)}
            onEditVehicleClick={onEditVehicleClick}
            onDeleteVehicleClick={onDeleteVehicleClick}
          />
          <VehiclesTableFooter
            total={data?.totalCount ?? 0}
            page={filters.page - 1}
            pageSize={filters.pageSize}
            onPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />
        </TableStyled>
      </TableContainerStyled>
    </ClickAwayListener>
  )
}

import { useEffect, useMemo } from "react"
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { LoadingButton } from "@mui/lab"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useAppContext, useToast } from "../../../../contexts"
import {
  createVehicleAPI,
  getInsuranceCompaniesAPI,
  getManufacturersAPI,
  updateVehicleAPI,
} from "../../../../services"
import dayjs from "dayjs"
import { AutocompleteController, DateController } from "../../../../components"
import { VEHICLE_TYPE_OPTIONS } from "../../../../utils"

interface IProps {
  isVisible: boolean
  selectedVehicle?: IVehicle
  onClose: () => void
}

interface IForm {
  numberPlate: string | null
  vin: string | null
  manufacturer: string | null
  manufacturerType: string | null
  vehicleType: string | null
  registrationDate: string | null
  insuranceCompany: string | null
  vehicleTypeOther: string | null
}

export const CreateVehicleDialog = (props: IProps) => {
  const { isVisible, selectedVehicle, onClose } = props
  const { t } = useTranslation()
  const toast = useToast()
  const queryClient = useQueryClient()

  const {
    state: { groupId },
  } = useAppContext()

  const defaultValues = {
    numberPlate: null,
    vin: null,
    manufacturer: null,
    manufacturerType: null,
    vehicleType: null,
    registrationDate: null,
    insuranceCompany: null,
  }

  const methods = useForm<IForm>({
    defaultValues,
  })

  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods

  const vehicleTypeValue = watch("vehicleType")

  useEffect(() => {
    if (!isVisible) {
      reset(defaultValues)
    } else if (selectedVehicle) {
      reset({
        numberPlate: selectedVehicle?.numberPlate,
        vin: selectedVehicle?.vin,
        manufacturer: selectedVehicle?.manufacturer,
        manufacturerType: selectedVehicle?.manufacturerType,
        vehicleType: selectedVehicle?.vehicleType,
        vehicleTypeOther: selectedVehicle?.vehicleTypeOther,
        registrationDate: selectedVehicle?.registrationDate,
        insuranceCompany: selectedVehicle?.insuranceCompany,
      })
    }
  }, [isVisible, reset])

  const { mutate: createVehicle, isPending: isVehicleCreating } = useMutation({
    mutationFn: (body: ICreateVehicleBody) => createVehicleAPI(body),
    onSuccess: () => {
      toast.show(t("vehicleCreatedSuccessfullyMessage"), "success")

      void queryClient.refetchQueries({ queryKey: ["vehicles"] })
    },
    onSettled: () => {
      onClose()
    },
  })

  const { mutate: updateVehicle, isPending: isVehicleUpdating } = useMutation({
    mutationFn: (requestParams: IUpdateVehicleRequestParams) =>
      updateVehicleAPI(requestParams),
    onSuccess: (response) => {
      toast.show(t("vehicleUpdatedSuccessfullyMessage"), "success")

      queryClient.setQueryData(["vehicles"], (prev: IVehicles) => {
        const index = prev?.vehicles?.findIndex((v) => v.id === response.id)

        if (index !== -1) {
          return {
            ...prev,
            vehicles: [
              ...prev.vehicles.slice(0, index),
              { ...prev, ...response },
              ...prev.vehicles.slice(index + 1),
            ],
          }
        }

        return prev
      })
    },
    onSettled: () => {
      onClose()
    },
  })

  const { data: manufacturers, isLoading: areManufacturersLoading } = useQuery({
    queryKey: ["manufacturers"],
    queryFn: () => getManufacturersAPI(),
  })

  const {
    data: liabilityInsuranceCompanies,
    isLoading: areLiabilityInsuranceCompaniesLoading,
  } = useQuery({
    queryKey: ["liability-insurance-companies"],
    queryFn: () => getInsuranceCompaniesAPI({ type: "Liability" }),
  })

  const liabilityInsuranceCompanyOptions = useMemo(
    () =>
      liabilityInsuranceCompanies?.map((company) => ({
        name: company.name,
        value: company.name,
      })),
    [liabilityInsuranceCompanies],
  )

  const onFormSubmit = (data: FieldValues) => {
    const body: ICreateVehicleBody = {
      numberPlate: data.numberPlate || null,
      vin: data.vin || null,
      vehicleType: data.vehicleType || null,
      vehicleTypeOther:
        data.vehicleType === "Andere" ? data.vehicleTypeOther : null,
      manufacturer: data.manufacturer || null,
      manufacturerType: data.manufacturerType || null,
      registrationDate: data.registrationDate
        ? dayjs(data.registrationDate).toISOString()
        : null,
      insuranceCompany: data.insuranceCompany || null,
      groupId,
    }

    if (selectedVehicle) {
      updateVehicle({
        id: selectedVehicle.id,
        body,
      })
    } else {
      createVehicle(body)
    }
  }

  const isLoading = isSubmitting || isVehicleCreating || isVehicleUpdating

  return (
    <Dialog
      component="form"
      open={isVisible}
      onClose={onClose}
      onSubmit={handleSubmit(onFormSubmit)}
      disableEscapeKeyDown={isLoading}
    >
      <DialogTitle>
        {selectedVehicle ? t("editVehicle") : t("newVehicle")}
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px">
          <Box display="flex" flexDirection="column" gap="4px">
            <Controller
              name="vehicleType"
              control={control}
              rules={{
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography>{t("vehicleType")}: </Typography>
                  <Box display="flex" flexDirection="column" gap="4px">
                    <Box display="flex" flexWrap="wrap" flex={1} gap="10px">
                      {VEHICLE_TYPE_OPTIONS?.map((type, index) => (
                        <Box key={index} display="flex" gap="4px">
                          <Checkbox
                            checked={value === type.value}
                            onChange={() =>
                              onChange(value === type.value ? null : type.value)
                            }
                            disabled={isLoading}
                          />
                          <Typography>{t(type.label)}</Typography>
                        </Box>
                      ))}
                    </Box>
                    <FormHelperText sx={{ marginLeft: "14px" }} error={!!error}>
                      {error?.message}
                    </FormHelperText>
                  </Box>
                </Box>
              )}
            />
            {vehicleTypeValue === "Andere" && (
              <Controller
                name="vehicleTypeOther"
                control={control}
                rules={{
                  minLength: {
                    message: t("minLengthErrorMessage", { value: 2 }),
                    value: 2,
                  },
                }}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    value={field.value ?? ""}
                    label={t("vehicleType")}
                    error={!!error?.message}
                    helperText={error?.message}
                    fullWidth
                  />
                )}
              />
            )}
          </Box>
          <Box display="flex" gap="8px">
            <Controller
              name="numberPlate"
              control={control}
              rules={{
                required: t("required"),
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  onChange={(event) =>
                    field.onChange(event.target.value?.toUpperCase())
                  }
                  label={t("officialRegistrationNumber")}
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
            <Controller
              name="vin"
              control={control}
              rules={{
                required: t("required"),
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  label={t("chasisNumber")}
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box display="flex" gap="8px">
            <AutocompleteController
              formControl={control}
              rules={{
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              controlName="manufacturer"
              label={t("manufacturer")}
              options={manufacturers}
              areOptionsLoading={areManufacturersLoading}
            />
            <Controller
              name="manufacturerType"
              control={control}
              rules={{
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  value={field.value ?? ""}
                  label={t("manufacturerType")}
                  error={!!error?.message}
                  helperText={error?.message}
                  fullWidth
                />
              )}
            />
          </Box>
          <Box display="flex" gap="8px">
            <DateController
              formMethods={methods}
              controlName="registrationDate"
              label={t("initialRegistration")}
              isDisabled={isLoading}
              maxDate={dayjs()}
            />
            <AutocompleteController
              formControl={control}
              controlName="insuranceCompany"
              label={t("insurer")}
              options={liabilityInsuranceCompanyOptions}
              areOptionsLoading={areLiabilityInsuranceCompaniesLoading}
              rules={{
                minLength: {
                  message: t("minLengthErrorMessage", { value: 2 }),
                  value: 2,
                },
              }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose} disabled={isLoading}>
          {t("cancel")}
        </Button>
        <LoadingButton type="submit" loading={isLoading}>
          {selectedVehicle ? t("save") : t("create")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

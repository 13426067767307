import React, { useCallback, useEffect, useMemo, useState } from "react"
import {
  Box,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Collapse,
  IconButton,
  TableFooter,
  Button,
  TableSortLabel,
} from "@mui/material"
import { CalendarMonth } from "@mui/icons-material"
import { useQuery } from "@tanstack/react-query"
import { getWorkflowDefinitionAPI, getWorkflowsAPI } from "../../../../services"
import { colors } from "../../../../utils"
import dayjs from "dayjs"
import { WorkflowStatusChip } from "../../../../components"
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined"
import AccountTreeOutlinedIcon from "@mui/icons-material/AccountTreeOutlined"
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined"
import { useNavigate } from "react-router-dom"
import { type Node, useEdgesState, useNodesState } from "reactflow"
import {
  CollapsibleRow,
  CustomRow,
  CustomRowHeader,
  EmptyRow,
  EnhancedTable,
  EnhancedTableContainer,
  NoDataRow,
} from "./styled"
import { useTranslation } from "react-i18next"
import { getDefinitionTypeName, type IFilters } from "../../utils"
import {
  CanvasMap,
  ExecutionLogsTable,
} from "../../../WorkflowPreview/components"
import {
  createEdges,
  getTransformedLayout,
  transformStepToNode,
} from "../../../WorkflowPreview/utils"

interface IProps {
  searchKey: string
  sort?: string
  filters: IFilters
}

type TTab = "execution_logs" | "workflow_preview"

export const WorkflowsTable = (props: IProps) => {
  const { searchKey, filters } = props
  const { t } = useTranslation()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [expandedWorkflow, setExpandedWorkflow] = useState<string | undefined>()
  const [selectedTab, setSelectedTab] = useState<TTab>("workflow_preview")
  const navigate = useNavigate()
  const [definition, setDefinition] = useState<Record<string, string>>({
    id: "",
    version: "",
  })
  const [workflowId, setWorkflowId] = useState("")
  const [nodes, setNodes, onNodesChange] = useNodesState([])
  const [edges, setEdges, onEdgesChange] = useEdgesState([])
  const [sortBy, setSortBy] = useState("CreateTime")
  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("desc")

  const {
    data: workflows,
    isPending: isWorkflowsPending,
    isRefetching: isWorkflowsRefetching,
    refetch: refetchWorkflows,
  } = useQuery({
    queryKey: ["workflows-listing"],
    queryFn: () =>
      getWorkflowsAPI({
        page: page + 1,
        pageSize: rowsPerPage,
        createdFrom: filters.createdStartDate,
        createdTo: filters.createdEndDate,
        searchCaseNumber: filters.caseNumber,
        searchName: searchKey || filters.name,
        searchDefinitionType: filters.definitionType,
        sortBy,
        sortDesc: sortOrder === "desc",
        statusFilter: filters.status !== -1 ? filters.status : null,
      }),
    enabled: false,
  })

  const isDataLoading = isWorkflowsPending || isWorkflowsRefetching

  const handleChangePage = (_: unknown, newPage: number) => setPage(newPage)
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }

  const onRowClick = (workflow: any) => {
    setExpandedWorkflow(
      expandedWorkflow === workflow.id ? undefined : workflow.id,
    )
    setDefinition({
      id: workflow.workflowDefinitionId,
      version: workflow.version,
    })
    setWorkflowId(workflow.id)
  }

  const onTabClick = (tab: TTab) => setSelectedTab(tab)

  const collapseRow = useCallback(() => {
    setExpandedWorkflow(undefined)
  }, [])

  const { data: workflowDefinitionData } = useQuery({
    queryKey: ["workflow-instance-definition", definition.id],
    queryFn: () =>
      getWorkflowDefinitionAPI(definition.id, {
        version: definition.version,
      }),
    enabled: !!definition.id,
    refetchOnMount: true,
  })

  const initialNodes = useMemo(() => {
    const nodes: Node[] = []

    const workflow = workflows?.items?.find((value) => value.id === workflowId)

    const executionPointers = workflow?.executionPointers

    workflowDefinitionData?.steps?.forEach((step) => {
      nodes.push(
        ...transformStepToNode(step, executionPointers, workflow?.data),
      )
    })

    return nodes
  }, [workflowDefinitionData, workflows, workflowId])

  const initialEdges = useMemo(() => {
    if (workflowDefinitionData?.steps)
      return createEdges(workflowDefinitionData?.steps)
  }, [workflowDefinitionData])

  const handleSort = useCallback(
    (column: string) => {
      setSortBy(column)
      setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"))
    },
    [sortOrder],
  )

  useEffect(() => {
    if (initialNodes && initialEdges) {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getTransformedLayout(initialNodes, initialEdges)

      setNodes(layoutedNodes)
      setEdges(layoutedEdges)
    }
  }, [initialNodes, initialEdges])

  useEffect(() => {
    if (searchKey) {
      const handler = setTimeout(() => {
        void refetchWorkflows()
      }, 500)

      return () => {
        clearTimeout(handler)
      }
    } else {
      void refetchWorkflows()
    }
  }, [searchKey, filters, page, rowsPerPage, sortBy, sortOrder])

  useEffect(() => {
    if (expandedWorkflow) {
      setTimeout(() => {
        const targetElement = document.getElementById(`ste-${expandedWorkflow}`)
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
        } else {
          const targetElement = document.getElementById("first-empty-row")
          if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth", block: "end" })
          }
        }
      }, 400)
    }
  }, [expandedWorkflow])

  useEffect(() => {
    if (workflows && workflows.currentPage > workflows.totalPages) {
      setPage(0)
    }
  }, [workflows])

  return (
    <Box paddingBottom="24px" display="grid">
      <EnhancedTableContainer>
        <EnhancedTable>
          <TableHead onClick={collapseRow}>
            <CustomRowHeader>
              <TableCell width="10%">
                <TableSortLabel
                  active={sortBy === "CreateTime"}
                  direction={sortOrder}
                  onClick={() => handleSort("CreateTime")}
                >
                  {t("date")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="10%">{t("time")}</TableCell>
              <TableCell width="15%">
                <TableSortLabel
                  active={sortBy === "CaseNumber"}
                  direction={sortOrder}
                  onClick={() => handleSort("CaseNumber")}
                >
                  {t("caseNumber")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="15%">
                <TableSortLabel
                  active={sortBy === "WorkflowId"}
                  direction={sortOrder}
                  onClick={() => handleSort("WorkflowId")}
                >
                  {t("workflowId")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="10%">
                <TableSortLabel
                  active={sortBy === "Version"}
                  direction={sortOrder}
                  onClick={() => handleSort("Version")}
                >
                  {t("version")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="15%">
                <TableSortLabel
                  active={sortBy === "DefinitionType"}
                  direction={sortOrder}
                  onClick={() => handleSort("DefinitionType")}
                >
                  {t("type")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="15%">
                <TableSortLabel
                  active={sortBy === "Status"}
                  direction={sortOrder}
                  onClick={() => handleSort("Status")}
                >
                  {t("status")}
                </TableSortLabel>
              </TableCell>
              <TableCell width="10%">{t("duration")}</TableCell>
            </CustomRowHeader>
          </TableHead>
          <TableBody>
            {isDataLoading ? (
              <>
                <EmptyRow id="first-empty-row">
                  <TableCell colSpan={8} height="16px" />
                </EmptyRow>
                {Array.from({ length: rowsPerPage }).map((_, index) => (
                  <React.Fragment key={index}>
                    <CustomRow open={false} className="disabled">
                      {Array.from({ length: 8 }).map((_, index) => (
                        <TableCell key={index} height="46px">
                          <Box padding="8px">
                            <Skeleton />
                          </Box>
                        </TableCell>
                      ))}
                    </CustomRow>
                    <EmptyRow>
                      <TableCell colSpan={8} height="16px" />
                    </EmptyRow>
                  </React.Fragment>
                ))}
              </>
            ) : workflows?.totalItems === 0 ? (
              <>
                <EmptyRow id="first-empty-row">
                  <TableCell colSpan={8} height="16px" />
                </EmptyRow>
                <NoDataRow>
                  <TableCell align="center" colSpan={8}>
                    <Typography>{t("noData")}</Typography>
                  </TableCell>
                </NoDataRow>
              </>
            ) : (
              <>
                <EmptyRow id="first-empty-row">
                  <TableCell colSpan={8} height="16px" />
                </EmptyRow>
                {workflows?.items?.map((workflow: any, index) => {
                  return (
                    <React.Fragment key={index}>
                      <CustomRow
                        open={expandedWorkflow === workflow.id}
                        onClick={() => onRowClick(workflow)}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>
                          <Box
                            padding="8px 6px"
                            display="flex"
                            alignItems="center"
                          >
                            <IconButton>
                              <CalendarMonth />
                            </IconButton>
                            {workflow.createTime
                              ? dayjs(workflow.createTime).format("DD.MM.YYYY")
                              : "-"}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 10px">
                            {workflow.createTime
                              ? dayjs(workflow.createTime).format("HH:mm:ss")
                              : "-"}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 10px">
                            {workflow.data?.CaseNumber || "--"}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 10px">
                            {workflow.workflowDefinitionId}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 15px">{workflow.version}</Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 15px">
                            {t(getDefinitionTypeName(workflow.definitionType))}
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 0px" width="fit-content">
                            <WorkflowStatusChip status={workflow.status} />
                          </Box>
                        </TableCell>
                        <TableCell>
                          <Box padding="8px 10px">
                            {workflow.completeTime
                              ? `${
                                  (new Date(workflow.completeTime).getTime() -
                                    new Date(workflow.createTime).getTime()) /
                                  1000
                                } sec`
                              : "--"}
                          </Box>
                        </TableCell>
                      </CustomRow>
                      <CollapsibleRow open={expandedWorkflow === workflow.id}>
                        <TableCell
                          colSpan={8}
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                        >
                          <Collapse
                            in={expandedWorkflow === workflow.id}
                            timeout="auto"
                            unmountOnExit
                          >
                            <Box>
                              <Box
                                marginBottom="24px"
                                paddingBottom="16px"
                                display="flex"
                                flexDirection="row"
                                alignItems="center"
                                justifyContent="flex-end"
                                borderBottom={`1px solid ${colors.gray4}`}
                              >
                                <Typography
                                  flex={1}
                                  paddingRight="24px"
                                  variant="regularSemiBold"
                                  color={colors.black4}
                                >
                                  {selectedTab === "execution_logs"
                                    ? t("executionHistory")
                                    : t("stepsPreview")}
                                </Typography>

                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="12px"
                                >
                                  <IconButton
                                    style={{ padding: "4px" }}
                                    onClick={() =>
                                      onTabClick("workflow_preview")
                                    }
                                  >
                                    {selectedTab === "workflow_preview" ? (
                                      <AccountTreeOutlinedIcon color="primary" />
                                    ) : (
                                      <AccountTreeOutlinedIcon />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    style={{ padding: "4px" }}
                                    onClick={() => onTabClick("execution_logs")}
                                  >
                                    {selectedTab === "execution_logs" ? (
                                      <ListAltOutlinedIcon color="primary" />
                                    ) : (
                                      <ListAltOutlinedIcon />
                                    )}
                                  </IconButton>
                                  <IconButton
                                    onClick={() =>
                                      navigate(
                                        `/workflow-preview/${workflow.id}/${workflow.workflowDefinitionId}/${definition.version}`,
                                      )
                                    }
                                  >
                                    <LaunchOutlinedIcon />
                                  </IconButton>

                                  <Box marginLeft="24px" />
                                </Box>
                                <Button onClick={collapseRow} size="small">
                                  {t("close")}
                                </Button>
                              </Box>

                              {selectedTab === "execution_logs" && (
                                <ExecutionLogsTable
                                  executionHistory={workflow.executionHistory}
                                />
                              )}

                              {selectedTab === "workflow_preview" && (
                                <Box width="100%" height="400px">
                                  <CanvasMap
                                    nodeOptions={{
                                      nodes: nodes.map((node) => ({
                                        ...node,
                                        data: {
                                          ...node.data,
                                          workflowId: workflow.id,
                                        },
                                      })),
                                      onNodesChange,
                                      setNodes,
                                    }}
                                    edgeOptions={{
                                      edges,
                                      onEdgesChange,
                                      setEdges,
                                    }}
                                  />
                                </Box>
                              )}
                            </Box>
                          </Collapse>
                        </TableCell>
                      </CollapsibleRow>
                      <EmptyRow
                        id={`ste-${
                          index > 0 ? workflows?.items?.[index + 1]?.id : ""
                        }`}
                      >
                        <TableCell colSpan={8} height="16px" />
                      </EmptyRow>
                    </React.Fragment>
                  )
                })}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                onClick={collapseRow}
                count={workflows?.totalItems || 0}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableRow>
          </TableFooter>
        </EnhancedTable>
      </EnhancedTableContainer>
    </Box>
  )
}

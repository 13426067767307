import { Box, MenuItem } from "@mui/material"
import { ItemBox, SelectDropdown } from "../../styled"
import { useTranslation } from "react-i18next"
import { Check, East, Remove } from "@mui/icons-material"
import { useCallback } from "react"

interface IProps {
  value: number[]
  onChange: (event: any) => void
}

export const ExecutionTypeDropdown = (props: IProps) => {
  const { value, onChange } = props
  const { t } = useTranslation()

  const getOptionItem = useCallback(
    (option: number) => {
      switch (option) {
        case 1:
          return (
            <>
              <Box display="flex" alignItems="center" gap="8px">
                <b>A</b> <East fontSize="inherit" />{" "}
                {t("automaticallyProcessed")}
              </Box>
              {value?.includes(option) && <Check color="primary" />}
            </>
          )
        case 2:
          return (
            <>
              <Box display="flex" alignItems="center" gap="8px">
                <b>M</b> <East fontSize="inherit" />{" "}
                {t("manualInteractionNeeded")}
              </Box>
              {value?.includes(option) && <Check color="primary" />}
            </>
          )
        case 3:
          return (
            <>
              <Box display="flex" alignItems="center" gap="8px">
                <Remove fontSize="inherit" /> <East fontSize="inherit" />{" "}
                {t("noAutomationRunning")}
              </Box>
              {value?.includes(option) && <Check color="primary" />}
            </>
          )
        default:
          return <></>
      }
    },
    [value],
  )

  return (
    <SelectDropdown
      value={value}
      multiple
      onChange={onChange}
      renderValue={(selected: any) => {
        return selected
          ?.map((value: number) => {
            switch (value) {
              case 1:
                return "A"
              case 2:
                return "M"
              case 3:
                return "-"
              default:
                return ""
            }
          })
          ?.join(", ")
      }}
      label={t("executionType")}
    >
      <MenuItem value={undefined}>
        <i>{t("all")}</i>
      </MenuItem>
      {[1, 2, 3].map((i) => (
        <MenuItem value={i} key={i}>
          <ItemBox>{getOptionItem(i)}</ItemBox>
        </MenuItem>
      ))}
    </SelectDropdown>
  )
}

import { Box, Grid, Typography } from "@mui/material"
import { type ISectionItemProps } from "../../types"
import { checkAllConditionsVisibility, colors } from "../../../../utils"
import { TextWithLink } from "../../../../components"
import WarningIcon from "@mui/icons-material/Warning"
import { FormField } from "../FormField"
import { memo } from "react"

export const FormFieldsList = memo((props: ISectionItemProps) => {
  const { claim, subsection, formMethods } = props

  return (
    <Box display="flex">
      <Grid container spacing="16px">
        {subsection?.metadatas?.map((e) => {
          if (
            e.fieldType === "FileDownload" &&
            e.documentType &&
            claim?.hiddenDocumentSections?.length &&
            claim?.hiddenDocumentSections?.includes(e.documentType)
          ) {
            return null
          }

          const shouldBeVisible = checkAllConditionsVisibility(
            e.conditions,
            formMethods.watch,
          )

          if (!shouldBeVisible) {
            return null
          }

          return (
            <>
              {!!e.description && (
                <Grid
                  item
                  marginBottom="-8px"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <TextWithLink text={e.description} />
                </Grid>
              )}
              {!!e.warning && (
                <Grid
                  item
                  marginBottom="-8px"
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      paddingRight="8px"
                      color={colors.red}
                      variant="small"
                    >
                      {e.warning}
                    </Typography>
                    <WarningIcon htmlColor={colors.red} fontSize="small" />
                  </Box>
                </Grid>
              )}
              <FormField {...props} field={e} />
            </>
          )
        })}
      </Grid>
    </Box>
  )
})

import { Alert } from "@mui/material"
import { memo } from "react"
import dayjs from "dayjs"
import { createSearchParams, Link } from "react-router-dom"
import { Trans } from "react-i18next"

interface IProps {
  type: "Email" | "SMS"
  claim: IClaim
  disabled: boolean
  subsection: IClaimTemplateSection
  magicLinkInfo: IMagicLinkInformation
}

export const MagicLinkAlert = memo((props: IProps) => {
  const { type, claim, disabled, subsection, magicLinkInfo } = props

  return (
    <Alert
      severity={
        dayjs(new Date()).isBefore(new Date(magicLinkInfo.expiresOn))
          ? "info"
          : "warning"
      }
    >
      <Trans
        i18nKey={
          type === "Email" ? "emailLinkDescription" : "smsLinkDescription"
        }
        values={{
          expiresOn: dayjs(magicLinkInfo.expiresOn).format("DD.MM.YYYY HH:mm"),
          createdOn: dayjs(magicLinkInfo.createdOn).format("DD.MM.YYYY HH:mm"),
          customer: magicLinkInfo.sentTo,
        }}
        components={[
          <Link
            key="1"
            className={disabled ? "disabled" : ""}
            to={{
              pathname: "../document-preview",
              search: createSearchParams({
                claimId: claim.id,
                documentType: subsection?.sectionType,
              }).toString(),
            }}
            state={{
              isFromLink: true,
            }}
          ></Link>,
        ]}
      />
    </Alert>
  )
})

import {
  type Dispatch,
  type SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react"
import {
  type IQuickFilters,
  type IInboxFilters,
} from "../../../../utils/constants"
import { useQuery } from "@tanstack/react-query"
import { getGlobalInboxDeletedAPI } from "../../../../../../services"
import { upperCaseFirstLetter } from "../../../../../../utils"
import { EmailDataTable } from "../EmailDataTable"
import { getChannelType } from "../../utils"

type Order = "asc" | "desc"

interface IProps {
  tablePage: number
  tableFilters: { filters: IInboxFilters; quickFilters: IQuickFilters }
  onChangeTablePage: Dispatch<SetStateAction<number>>
}

export const BlacklistedPanel = (props: IProps) => {
  const {
    tablePage,
    tableFilters: { filters, quickFilters },
    onChangeTablePage,
  } = props

  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [order, setOrder] = useState<Order>("desc")
  const [orderBy, setOrderBy] = useState<
    keyof IGlobalInboxItemDetail | keyof IGlobalInboxItemEmail
  >("createdAt")

  const {
    data: globalInboxData,
    isPending: isGlobalInboxDataPending,
    isRefetching: isGlobalInboxDataRefetching,
    refetch: refetchGlobalInboxData,
  } = useQuery({
    queryKey: ["global-inbox-blacklisted"],
    queryFn: () =>
      getGlobalInboxDeletedAPI({
        pageNumber: tablePage + 1,
        pageSize: rowsPerPage,
        orderBy: upperCaseFirstLetter(orderBy),
        order,
        searchTerm: quickFilters.search,
        caseNumber: filters.caseNumber,
        dateFrom: filters.startDate,
        dateTo: filters.endDate,
        channelTypes: getChannelType(quickFilters?.channelType),
        channelIds: quickFilters?.channelIds,
      }),
  })

  const isInitializing = useRef(true)

  useEffect(() => {
    if (isInitializing.current) {
      isInitializing.current = false
      return
    }

    const handler = setTimeout(
      () => {
        localStorage.setItem(
          "globalInboxFilters",
          JSON.stringify({
            filters,
            quickFilters,
          }),
        )
        void refetchGlobalInboxData()
      },
      quickFilters.search ? 500 : 150,
    )

    return () => {
      clearTimeout(handler)
    }
  }, [tablePage, rowsPerPage, order, orderBy, filters, quickFilters])

  return (
    <EmailDataTable
      data={globalInboxData}
      isPending={isGlobalInboxDataPending}
      isRefetching={isGlobalInboxDataRefetching}
      page={tablePage}
      rowsPerPage={rowsPerPage}
      order={order}
      orderBy={orderBy}
      disableEditing
      updatePage={onChangeTablePage}
      updateRowsPerPage={setRowsPerPage}
      updateOrder={setOrder}
      updateOrderBy={setOrderBy}
      i18nIsDynamicList
      key={1}
    />
  )
}

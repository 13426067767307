import {
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  CircularProgress,
  AccordionDetails,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useQuery } from "@tanstack/react-query"
import { type UseFormReturn, type FieldValues } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useState, useEffect } from "react"
import { getChannelConfigurationsAPI } from "../../../../../../services"
import {
  CustomTypography,
  CustomizedAccordion,
  CustomizedAccordionSummary,
  CustomizedCard,
  CustomizedCardContent,
  LoadingBox,
  SelectedBox,
  StyledBox,
  StyledLink,
  SummaryWrapper,
  TitleBox,
  Wrapper,
  controllLabelStyle,
} from "./styled"
import { isArray } from "lodash"

interface IProps {
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: string,
    key: string,
    onChange?: (input: any) => void,
  ) => void
  methods: UseFormReturn<FieldValues, any, undefined>
  selectedAction: INodeItem
  setIds: React.Dispatch<React.SetStateAction<Record<string, string>>>
  setDummyFields: React.Dispatch<React.SetStateAction<Record<string, any>>>
}

const SELECTED_FIELDS = "SelectedFields"
const API_SETUP_ID = "ApiSetupId"

const JSON_RESPONSE_SCHEMA = "JSONResponseSchema"

export const GetDataConfig = (props: IProps) => {
  const { handleChange, methods, selectedAction, setIds, setDummyFields } =
    props
  const { t } = useTranslation()

  const { getValues } = methods

  const [_, setSelectedDetails] = useState<IAPISetupDetails>()

  const [jsonData, setJsonData] = useState<Record<string, any>>({})
  const [__, setSelectedFields] = useState<Record<string, any>>({})
  const [currentExpanded, setCurrentExpanded] = useState<string>("")
  const [channelConfigurations, setChannelConfigurations] =
    useState<IChannelConfiguration[]>()

  const {
    isPending: isPendingConfigurations,
    isRefetching: isRefetchingConfigurations,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: async () => {
      const response = await getChannelConfigurationsAPI("Interface")
      setChannelConfigurations(response)

      const controlValues = getValues()

      if (controlValues[JSON_RESPONSE_SCHEMA] !== "null") {
        setJsonData(controlValues[JSON_RESPONSE_SCHEMA])
      }

      setSelectedFields(selectedAction?.inputs?.SelectedFields || {})
    },
    refetchOnMount: true,
  })

  useEffect(() => {
    const allFields =
      channelConfigurations?.[0]?.apiSetup?.apiSetupDetails?.map((url) =>
        JSON.parse(url.jsonResponseSchema),
      )

    setDummyFields(
      allFields?.reduce((acc, field) => {
        return { ...acc, ...field }
      }),
    )
  }, [channelConfigurations])

  const onSelectConfigurationHandler = (config: IChannelConfiguration) => {
    if (config.apiSetup?.id) {
      handleChange(
        selectedAction,
        "input",
        config.apiSetup?.id,
        API_SETUP_ID,
        (data) => {
          methods.setValue(API_SETUP_ID, data)
        },
      )
    }
  }

  const onSelectDetailsHandler = (details: IAPISetupDetails) => {
    const json = JSON.parse(details.jsonResponseSchema)
    setJsonData(json)
    setSelectedDetails(details)
  }

  const isLoadingChannelConfigs =
    isPendingConfigurations || isRefetchingConfigurations

  const handleCheckboxChange = (
    field: Record<string, string | boolean>,
    value: string,
    id: string,
  ) => {
    setSelectedFields((prev) => {
      const newFieldsData: any = {
        ...prev,
        [id]: {
          ...prev?.[id],
          [value]: {
            ...field,
            selected: !prev?.[id]?.[value]?.selected,
          },
        },
      }
      handleChange(
        selectedAction,
        "input",
        newFieldsData,
        SELECTED_FIELDS,
        (data) => {
          methods.setValue(SELECTED_FIELDS, data)
        },
      )
      return newFieldsData
    })
  }

  const renderFieldSelection = (id: string) => (
    <FormGroup sx={{ marginBottom: 5 }}>
      <Grid container spacing={3}>
        {Object.keys(jsonData).map((key) => (
          <Grid item xs={12} sm={4} md={4} key={key}>
            <CustomizedCard
              selected={
                selectedAction?.inputs?.SelectedFields?.[id]?.[key]?.selected
              }
              key={key}
              id={id}
              onClick={(e) => {
                e.stopPropagation()
                handleCheckboxChange(jsonData[key], key, id)
              }}
            >
              <CustomizedCardContent>
                <FormControlLabel
                  sx={{ ...controllLabelStyle }}
                  onClick={() => handleCheckboxChange(jsonData[key], key, id)}
                  control={
                    <Checkbox
                      sx={{ margin: 0, mr: 2, ml: 3 }}
                      checked={
                        !!selectedAction?.inputs?.SelectedFields?.[id]?.[key]
                          ?.selected
                      }
                      onChange={() =>
                        handleCheckboxChange(jsonData[key], key, id)
                      }
                    />
                  }
                  label={
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: "17.5px" }}>
                        {jsonData[key].title}
                      </Typography>
                      <Typography
                        style={{ fontSize: "13px", color: "lightgrey" }}
                      >
                        (
                        {isArray(jsonData[key])
                          ? "Array"
                          : jsonData[key].example}
                        )
                      </Typography>
                    </Box>
                  }
                />
              </CustomizedCardContent>
            </CustomizedCard>
          </Grid>
        ))}
      </Grid>
    </FormGroup>
  )

  return (
    <Box>
      {isLoadingChannelConfigs ? (
        <LoadingBox>
          <CircularProgress />
        </LoadingBox>
      ) : (
        <Box>
          <Typography variant="largeMedium">Channels</Typography>
          <Wrapper>
            {channelConfigurations?.map(
              (ch, i) =>
                ch.apiSetup?.apiSetupDetails?.map((setApiSetupDetail, j) => {
                  const allFields = Object.keys(
                    JSON.parse(setApiSetupDetail.jsonResponseSchema),
                  )
                  const numOfSelectedFields = allFields.filter(
                    (field) =>
                      selectedAction?.inputs?.SelectedFields?.[
                        setApiSetupDetail.id
                      ]?.[field]?.selected === true,
                  ).length

                  return (
                    setApiSetupDetail.methodType === "GET" && (
                      <CustomizedAccordion
                        expanded={currentExpanded === `${i}-${j}`}
                        key={`${i}-${j}`}
                        onClick={() => {
                          setIds({
                            ChannelId: `'${ch.id}'`,
                            ApiSetupId: `'${ch.apiSetup?.id}'`,
                            ApiSetupDetailsId: `'${setApiSetupDetail.id}'`,
                          })
                          onSelectConfigurationHandler(ch)
                          onSelectDetailsHandler(setApiSetupDetail)
                        }}
                      >
                        <CustomizedAccordionSummary
                          onClick={() =>
                            setCurrentExpanded((prev) =>
                              prev !== `${i}-${j}` ? `${i}-${j}` : "",
                            )
                          }
                          expanded={currentExpanded === `${i}-${j}`}
                          numOfSelectedFields={numOfSelectedFields}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id={`channel-${i}-setup-${j}`}
                        >
                          <SummaryWrapper>
                            <StyledLink />
                            <Typography sx={{ fontWeight: 700 }}>
                              {ch.channelName}
                            </Typography>
                          </SummaryWrapper>
                          <TitleBox>Setup: {setApiSetupDetail.title}</TitleBox>
                          <SelectedBox>
                            <Typography sx={{ fontSize: 15, mr: 3 }}>
                              Selected: {numOfSelectedFields || 0}/
                              {allFields.length}
                            </Typography>
                          </SelectedBox>
                        </CustomizedAccordionSummary>
                        <AccordionDetails
                          sx={{ maxHeight: "360px", overflow: "auto" }}
                        >
                          {jsonData && Object.keys(jsonData).length > 0 && (
                            <StyledBox>
                              <CustomTypography>
                                {t("selectFields")}:
                              </CustomTypography>
                              {renderFieldSelection(setApiSetupDetail.id)}
                            </StyledBox>
                          )}
                        </AccordionDetails>
                      </CustomizedAccordion>
                    )
                  )
                }),
            )}
          </Wrapper>
        </Box>
      )}
    </Box>
  )
}

import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Skeleton,
  Typography,
} from "@mui/material"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { useEffect, useState, useCallback } from "react"
import { camelCaseStringToSentence, colors } from "../../../../../../utils"
import { useQuery } from "@tanstack/react-query"
import {
  getCrashmateEventListAPI,
  getChannelConfigurationsAPI,
} from "../../../../../../services"
import ArticleIcon from "@mui/icons-material/Article"
import {
  CustomizedAccordion,
  CustomizedAccordionDetails,
  CustomizedAccordionSummary,
  CustomizedCheckbox,
  ChannelButton,
} from "./styled"
import { useTranslation } from "react-i18next"

interface IProps {
  selectedAction: INodeItem
  handleChange: (
    selectedAction: INodeItem,
    type: "input" | "output" | "selectNext",
    input: any,
    key: string,
    onChange?: (input: any) => void,
  ) => void
}

export const CrashmateTriggerConfig = (props: IProps) => {
  const { selectedAction, handleChange } = props
  const [selectedEvents, setSelectedEvents] = useState<ICrashmateEvent[]>(
    selectedAction?.inputs?.Configuration?.Events || [],
  )
  const [selectAllEvents, setSelectAllEvents] = useState(false)
  const [accExpanded, setExpanded] = useState(true)
  const { t } = useTranslation()

  const {
    data: channelConfigurations,
    isPending: isPendingConfigurations,
    isRefetching: isChannelConfigRefetching,
  } = useQuery({
    queryKey: ["channel-configurations"],
    queryFn: () => getChannelConfigurationsAPI("Interface"),
    refetchOnMount: true,
  })

  const {
    data: crashmateEvents,
    isPending: isPendingEvents,
    isRefetching: isEventsListRefetching,
  } = useQuery({
    queryKey: ["crashmate-events"],
    queryFn: () => getCrashmateEventListAPI(),
    refetchOnMount: true,
  })

  const isLoading =
    isPendingConfigurations ||
    isChannelConfigRefetching ||
    isPendingEvents ||
    isEventsListRefetching

  useEffect(() => {
    if (
      selectedAction?.inputs?.Configuration?.ChannelId &&
      !selectedAction?.inputs.Configuration?.ChannelType
    ) {
      handleChange(selectedAction, "input", "Interface", "ChannelType")
    }
  }, [selectedAction.inputs])

  useEffect(() => {
    if (channelConfigurations && channelConfigurations?.length > 0) {
      const channelId = channelConfigurations.find(
        (ch) => ch.channelName === "West API Channel",
      )?.id
      if (selectedEvents.length === crashmateEvents?.length) {
        setSelectAllEvents(true)
      }
      handleChange(selectedAction, "input", channelId, "ChannelId")
    }
  }, [channelConfigurations, selectedEvents])

  const handleSelectedEventsChange = useCallback(
    (event: ICrashmateEvent, isSelected: boolean) => {
      setSelectedEvents((prev) => {
        let updatedSelectedEvents

        if (isSelected) {
          updatedSelectedEvents = prev.filter((ev) => ev.id !== event?.id)
        } else {
          updatedSelectedEvents = [...prev, event]
        }

        handleChange(selectedAction, "input", updatedSelectedEvents, "Events")
        return updatedSelectedEvents
      })
    },
    [selectedAction, handleChange],
  )

  const handleSelectAllEvents = useCallback(() => {
    setSelectAllEvents((prev) => {
      const newState = !prev
      if (newState && crashmateEvents) {
        setSelectedEvents(crashmateEvents)
      } else {
        setSelectedEvents([])
      }
      handleChange(
        selectedAction,
        "input",
        newState ? crashmateEvents : [],
        "Events",
      )
      return newState
    })
  }, [crashmateEvents, selectedAction, handleChange])

  return (
    <Box>
      {isLoading ? (
        Array.from({ length: 1 }).map((_, index) => (
          <Grid item xs={6} sm={3} md={2} lg={1.5} key={index}>
            <Box display="flex" flexDirection="column" gap="2px">
              <Skeleton variant="rounded" height="80px" />
            </Box>
          </Grid>
        ))
      ) : (
        <Box
          width="100%"
          display="flex"
          mt={2}
          flexWrap="wrap"
          justifyContent="center"
        >
          <CustomizedAccordion expanded={accExpanded}>
            <CustomizedAccordionSummary
              onClick={() => setExpanded((prev) => !prev)}
              accExpanded={accExpanded}
              selectedEvents={selectedEvents}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <Box width="100%" display="flex" justifyContent="space-between">
                <Typography ml={3} variant="largeMedium">
                  {t("crashmateEvents")}
                </Typography>
                {accExpanded && (
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onClick={(e) => e.stopPropagation()}
                          sx={{ mr: 1 }}
                          checked={selectAllEvents}
                          onChange={handleSelectAllEvents}
                        />
                      }
                      label={t("selectAll")}
                    />
                  </FormGroup>
                )}
                {accExpanded && (
                  <Typography mr={2}>
                    {t("selected")}: {selectedEvents.length}/
                    {crashmateEvents?.length}
                  </Typography>
                )}
              </Box>
            </CustomizedAccordionSummary>
            <CustomizedAccordionDetails>
              <Box
                ml={1}
                display="flex"
                flexWrap="wrap"
                justifyContent="space-between"
              >
                {crashmateEvents && crashmateEvents?.length > 0 ? (
                  crashmateEvents?.map((event) => {
                    const isEventSelected = selectedEvents.some(
                      (selectedEvent) => selectedEvent.id === event.id,
                    )
                    return (
                      <Box
                        width="48%"
                        mb={1}
                        key={`${event.entity}-${event.property}`}
                      >
                        <Box
                          height="100%"
                          display="flex"
                          flexDirection="column"
                          gap="2px"
                        >
                          <ChannelButton
                            isSelected={isEventSelected}
                            justifyContent="flex-start"
                            alignItems="center"
                            onClick={() =>
                              handleSelectedEventsChange(event, isEventSelected)
                            }
                          >
                            <CustomizedCheckbox checked={!!isEventSelected} />
                            <Box ml={1}>
                              <Typography
                                variant="regular"
                                className="line-clamp-2"
                                color={colors.black}
                              >
                                {camelCaseStringToSentence(event.entity)}
                              </Typography>
                              <Typography
                                variant="regular"
                                color={colors.gray3}
                              >
                                {camelCaseStringToSentence(event.property)}
                              </Typography>
                            </Box>
                          </ChannelButton>
                        </Box>
                      </Box>
                    )
                  })
                ) : (
                  <Box display="flex" alignItems="center">
                    <ArticleIcon sx={{ mr: 1 }} />
                    {t("noCrashmateEventsFound")}
                  </Box>
                )}
              </Box>
            </CustomizedAccordionDetails>
          </CustomizedAccordion>
        </Box>
      )}
    </Box>
  )
}

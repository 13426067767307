/* eslint-disable no-useless-escape */
import { colors } from "./colors"

// Regular expressions
export const EMAIL_REGEX_VALIDATION =
  /^[a-zA-Z0-9ÄäÖöÜüß._%+-]+@[a-zA-Z0-9ÄäÖöÜüß.-]+\.[a-zA-Z]{2,}$/

export const PASSWORD_REGEX_VALIDATION =
  /^(?=.*\d)(?=.*[!@#$%^&*?[\]=.])(?=.*[a-z])(?=.*[A-Z]).{8,}$/

export const EDIT_CLAIM_PATHNAME_REGEX = /^\/claims\/([a-fA-F0-9-]+)$/

export const WORKFLOW_CONFIGURATOR_PATHNAME_REGEX =
  /^\/workflow-configurator\/([a-zA-Z0-9-]+)$/

export const WORKFLOW_PREVIEW_PATHNAME_REGEX =
  /^\/workflow-preview\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-]+)$/

export const SIMPLE_URL_REGEX = /(https?:\/\/[^\s]+)/g

export const URL_REGEX =
  /^(https?|ftp):\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

export const URL_REGEX_WITH_PARAMS =
  /^(https?|ftp):\/\/((([a-z\d]([a-z\d-]*[a-z\d])*)\.)+[a-z]{2,}|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/[-a-z\d%_.~+{}]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i

export const NUMERIC_REGEX_VALIDATION = /^\d+$/

export const PHONE_NUMBER_REGEX_VALIDATION = /^(\+\d{1,})?\d+$/

export const CITY_REGEX_VALIDATION = /^[a-zA-ZäöüÄÖÜß\s-]*$/

// Global styles
export const AUTH_FORM_MAX_WIDTH = "452px"

export const AUTHORIZED_CONTENT_MAX_WIDTH = "2400px"

// Configs
export const INACTIVITY_THRESHOLD = 900000

export const AUTOSAVE_CLAIM_FORM_INTERVAL = 20000

export const REFETCH_DASHBOARD_INTERVAL = 30000

export const DEFAULT_REFETCH_INTERVAL = 30000

export const CLAIM_FORM_EDITING_INACTIVITY_THRESHOLD = 900000

export const DEFAULT_PHONE_COUNTRY = "DE"

export const EURO_SIGN = "€"

export const CLAIM_LIABILITY_STATES: TLiability[] = [
  "Unknown",
  "Agreed",
  "Denied",
]

export const CLAIM_STATUSES: TStatus[] = [
  "InCreation",
  "Active",
  "Duplicate",
  "RequestedToClose",
  "Closed",
  "WaitingForQuantification",
  "PaymentRequested",
  "PaymentReminded",
  "PaymentReceived",
  "TechnicalError",
  "Submitted",
  "WaitingForRepairInvoice",
  "WaitingForMandantFeedback",
  "WaitingForCaseFileAccess",
  "WaitingForOtherFeedback",
]

export const SECRET_KEY = "X9uY7w6B4m3Z0v2H8c1Q5tR9pL7dF2n"

export const COST_STATUSES: TCostPositionStatus[] = [
  "Settled",
  "InProgress",
  "UnderNegotiation",
]

export const COST_STATUS_COLOR: Record<TCostPositionStatus, string> = {
  Settled: colors.green,
  InProgress: colors.yellow,
  UnderNegotiation: colors.red,
}

export const TYPE_OF_COSTS_TRANSLATIONS: Record<string, string> = {
  Abschleppkosten: "abschleppkosten",
  Mietwagenkosten: "mietwagenkosten",
  Nutzungsausfall: "nutzungsausfall",
  ReparaturkostenFiktiv: "reparaturkostenFiktiv",
  ReparaturkostenKonkret: "reparaturkostenKonkret",
  Sachverständigenkosten: "sachverständigenkosten",
  Wertminderung: "wertminderung",
  ZusatzkostenPauschaleNebenkosten: "zusatzkostenPauschaleNebenkosten",
  Totalschaden: "totalschaden",
}

export const IF_ELSE_CONFIG_INITIAL_DATA = {
  data: [{ prop: "", condition: "", value: "" }],
  logic: "any",
}

export const VEHICLE_TYPE_OPTIONS: Array<{
  label: string
  value: TVehicleType
}> = [
  {
    label: "car",
    value: "Pkw",
  },
  {
    label: "motorBike",
    value: "Motorrad",
  },
  {
    label: "truck",
    value: "Lkw",
  },
  {
    label: "other",
    value: "Andere",
  },
]

export const DOCUMENT_TYPES = [
  "Expertise",
  "Invoice",
  "Email",
  "Statement",
  "AccompanyingLetter",
  "Correspondence",
  "GDPR",
  "PowerOfAttorney",
  "CommissioningOfTheExpertOpinion",
  "PoliceCaseViewingRequest",
  "OrderToAssistInTheSettlementOfClaims",
  "AssignmentPaymentOrder",
  "Other",
]

export const SENDER_TYPES = [
  "Expert",
  "RepairShop",
  "CarShop",
  "RentalCarCompany",
  "TowingCompany",
  "ThirdParty",
  "CrashMate",
]

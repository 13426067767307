import { memo, useCallback, useMemo } from "react"
import { type IFieldItemProps } from "../../types"
import { getRules, isFieldRequired } from "../../utils"
import { useTranslation } from "react-i18next"
import { Box, Grid, Tooltip } from "@mui/material"
import {
  BaseController,
  CheckboxController,
  CurrencyController,
  FileDownloadController,
  FileUploadController,
  PhoneNumberController,
  PlaceController,
  PoliceDepartmentController,
  RegistrationNumberController,
  TimeController,
  MagicLinkAlerts,
} from "./components"
import {
  AutocompleteController,
  DateController,
  DocumentPreviewDescription,
} from "../../../../components"
import { useQuery } from "@tanstack/react-query"

export const FormField = memo((props: IFieldItemProps) => {
  const {
    claim,
    section,
    subsection,
    field,
    formMethods,
    disabled,
    claimDocuments,
    isClaimAutoSaving,
    isUploading,
    liabilityInsuranceCompanyOptions,
    areLiabilityInsuranceCompanyOptionsLoading,
    legalExpensesInsuranceCompanyOptions,
    areLegalExpensesInsuranceCompanyOptionsLoading,
    motorVehicleExpertOptions,
    areMotorVehicleExpertOptionsLoading,
    manufacturersOptions,
    areManufacturersOptionsLoading,
    lessorsLeasingOptions,
    areLessorsLeasingOptionsLoading,
    lessorsFundingOptions,
    areLessorsFundingOptionsLoading,
    isEditModeEnabled,
    isComparisonPreview,
    hasPermissionToEditSection,
    subsectionsAdded,
    templateSubsections,
    onFieldFocus,
    onFieldBlur,
    onAddSubsectionClick,
  } = props
  const { t } = useTranslation()
  const { data: user } = useQuery<IUser>({
    queryKey: ["user"],
  })

  const { control } = formMethods

  const disabledField = useMemo(
    () =>
      !isComparisonPreview
        ? !isEditModeEnabled
          ? t("editModeNotEnabledTooltip")
          : claim?.disabledValues?.find((val) => val.key === field.controlName)
              ?.value
        : undefined,
    [isComparisonPreview, isEditModeEnabled, t, claim, field],
  )

  const conditionRequiredFieldValue =
    !!field?.rules?.isRequired && !!field?.requiredConditions?.field
      ? formMethods.watch(field.requiredConditions.field)
      : undefined

  const isConditionallyRequired = useMemo(
    () =>
      !!field?.rules?.isRequired &&
      !!field?.requiredConditions?.field &&
      isFieldRequired(field, conditionRequiredFieldValue),
    [field, conditionRequiredFieldValue],
  )

  const isRequired = useMemo(
    () =>
      (field?.rules?.isRequired && !field?.requiredConditions?.field) ||
      isConditionallyRequired,
    [field?.rules?.isRequired, isConditionallyRequired],
  )

  const rules = useMemo(
    () => getRules(field, t, isConditionallyRequired),
    [field, t, isConditionallyRequired],
  )

  const label = useMemo(
    () => `${field?.label ?? ""}${isRequired ? "*" : ""}`,
    [field?.label, isRequired],
  )

  const isDisabled = disabled || !hasPermissionToEditSection || !!disabledField

  const shouldShowDocumentExplanation = useMemo(
    () =>
      !!subsection?.isDocumentSection &&
      subsection?.sectionType !== "DamageDescription" &&
      subsection?.sectionType !== "Other",
    [subsection],
  )

  const renderField = useCallback(() => {
    switch (field.fieldType) {
      case "Text":
      case "Number":
      case "Email":
      case "TextArea":
      case "IBAN": {
        return (
          <BaseController
            {...props}
            control={control}
            isDisabled={isDisabled}
            label={label}
            rules={rules}
          />
        )
      }
      case "Checkbox": {
        return (
          <CheckboxController
            {...props}
            control={control}
            isDisabled={isDisabled}
            rules={rules}
          />
        )
      }
      case "Date": {
        return (
          <DateController
            formMethods={formMethods}
            controlName={field.controlName}
            label={label}
            isDisabled={isDisabled}
            rules={rules}
            onFieldFocus={() => onFieldFocus?.(section?.sectionType)}
            onFieldBlur={(f) => onFieldBlur?.(f, subsection, section)}
          />
        )
      }
      case "Time": {
        return (
          <TimeController
            {...props}
            control={control}
            isDisabled={isDisabled}
            label={label}
            rules={rules}
          />
        )
      }
      case "Insurer": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={liabilityInsuranceCompanyOptions}
            areOptionsLoading={areLiabilityInsuranceCompanyOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "InsurerLegalExpenses": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={legalExpensesInsuranceCompanyOptions}
            areOptionsLoading={areLegalExpensesInsuranceCompanyOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "MotorVehicleExpert": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={motorVehicleExpertOptions}
            areOptionsLoading={areMotorVehicleExpertOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "Salutation": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={
              field.selectionOptions.options?.map((o) => ({
                name: o.label,
                value: o.value,
              })) ?? []
            }
            areOptionsLoading={false}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "ReceivingAuthority": {
        return (
          <PoliceDepartmentController
            formControl={control}
            field={{
              ...field,
              label,
            }}
            disabled={isDisabled}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
            setValue={formMethods.setValue}
          />
        )
      }
      case "Vehicle": {
        if (user?.companyType !== "CarPool") {
          return (
            <BaseController
              {...props}
              control={control}
              isDisabled={isDisabled}
              label={label}
              rules={rules}
            />
          )
        }

        return (
          <RegistrationNumberController
            formControl={control}
            field={{
              ...field,
              label,
            }}
            disabled={isDisabled}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
            setValue={formMethods.setValue}
            subsectionsAdded={subsectionsAdded}
            templateSubsections={templateSubsections}
            onAddSubsectionClick={onAddSubsectionClick}
          />
        )
      }
      case "Manufacturer": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={manufacturersOptions}
            areOptionsLoading={areManufacturersOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "LessorLeasing": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={lessorsLeasingOptions}
            areOptionsLoading={areLessorsLeasingOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "LessorFunding": {
        return (
          <AutocompleteController
            formControl={control}
            controlName={field.controlName}
            label={field.label}
            disabled={isDisabled}
            options={lessorsFundingOptions}
            areOptionsLoading={areLessorsFundingOptionsLoading}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "PhoneNumber": {
        return (
          <PhoneNumberController
            formControl={control}
            field={{
              ...field,
              label,
            }}
            disabled={isDisabled}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "City": {
        return (
          <PlaceController
            formControl={control}
            field={{
              ...field,
              label,
            }}
            disabled={isDisabled}
            onFieldBlur={(field) => onFieldBlur?.(field, subsection, section)}
            onFieldFocus={() => {
              onFieldFocus?.(section.sectionType)
            }}
            rules={rules}
          />
        )
      }
      case "Currency": {
        return (
          <CurrencyController
            {...props}
            control={control}
            isDisabled={isDisabled}
            label={label}
            rules={rules}
          />
        )
      }
      case "FileDownload": {
        return (
          <Box display="flex" flexDirection="column" gap="24px">
            {shouldShowDocumentExplanation && <DocumentPreviewDescription />}
            <MagicLinkAlerts
              claim={claim}
              subsection={subsection!}
              disabled={isDisabled}
            />
            <FileDownloadController
              {...props}
              isClaimAutoSaving={isClaimAutoSaving}
              isDisabled={isDisabled}
            />
          </Box>
        )
      }
      case "FileUpload": {
        return <FileUploadController {...props} isDisabled={isDisabled} />
      }
      default: {
        return null
      }
    }
  }, [
    user,
    control,
    field,
    section,
    subsection,
    claim,
    claimDocuments,
    liabilityInsuranceCompanyOptions,
    areLiabilityInsuranceCompanyOptionsLoading,
    legalExpensesInsuranceCompanyOptions,
    areLegalExpensesInsuranceCompanyOptionsLoading,
    motorVehicleExpertOptions,
    areMotorVehicleExpertOptionsLoading,
    manufacturersOptions,
    areManufacturersOptionsLoading,
    lessorsLeasingOptions,
    lessorsFundingOptions,
    areLessorsLeasingOptionsLoading,
    areLessorsFundingOptionsLoading,
    t,
    isUploading,
    isClaimAutoSaving,
    isEditModeEnabled,
    isComparisonPreview,
    rules,
    label,
    isDisabled,
    subsectionsAdded,
    onAddSubsectionClick,
    onFieldBlur,
    onFieldFocus,
  ])

  return (
    <Tooltip title={field.fieldType !== "Checkbox" && disabledField}>
      <Grid
        id={field.controlName}
        key={field.id}
        item
        xs={12}
        sm={field.sizeInScreen ?? 12}
        md={field.sizeInScreen ?? 12}
        lg={field.sizeInScreen ?? 12}
        xl={field.sizeInScreen ?? 12}
      >
        {renderField()}
      </Grid>
    </Tooltip>
  )
})

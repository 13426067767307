import { memo, type SyntheticEvent, useCallback, useState } from "react"
import {
  type Control,
  Controller,
  type FieldValues,
  type RegisterOptions,
  type UseFormSetValue,
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
  Autocomplete,
  TextField,
  debounce,
  type TextFieldProps,
} from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getVehiclesAPI } from "../../../../../../services"
import { getNestedProperty } from "../../../../../../utils"
import { useAppContext } from "../../../../../../contexts"

interface IProps {
  formControl: Control<FieldValues, any>
  field: IClaimTemplateMetadata
  disabled: boolean
  rules?: Omit<
    RegisterOptions<FieldValues, string>,
    "disabled" | "valueAsNumber" | "valueAsDate" | "setValueAs"
  >
  subsectionsAdded?: string[]
  templateSubsections?: IClaimTemplateSection[]
  onFieldBlur?: (field: IChangedField) => void
  onFieldFocus?: () => void
  setValue?: UseFormSetValue<FieldValues>
  onAddSubsectionClick?: (subsection: IClaimTemplateSection) => void
}

export const RegistrationNumberController = memo((props: IProps) => {
  const {
    formControl,
    field,
    disabled,
    rules,
    subsectionsAdded,
    templateSubsections,
    onFieldBlur,
    onFieldFocus,
    setValue,
    onAddSubsectionClick,
  } = props
  const { t } = useTranslation()
  const [search, setSearch] = useState("")

  const {
    state: { groupId },
  } = useAppContext()

  const [isFocused, setFocused] = useState(false)

  const filters = {
    search,
    sortField: "createdAt" as TGetVehiclesSort,
    isDescending: true,
    page: 1,
    pageSize: 20,
    groupId,
  }

  const { data, isLoading } = useQuery({
    queryKey: [`${field.controlName}-vehicles`, search],
    queryFn: () => getVehiclesAPI(filters),
    enabled: isFocused,
  })

  const onInputChange = useCallback(
    (_e: SyntheticEvent<Element, Event>, value: string) => {
      setSearch(value)
    },
    [],
  )

  const onInputChangedDelayed = debounce(onInputChange, 350)

  return (
    <Controller
      control={formControl}
      name={field.controlName}
      rules={rules}
      render={({
        field: { value, onChange, onBlur },
        fieldState: { error },
      }) => {
        let formattedValue: any

        if (value) {
          formattedValue =
            data?.vehicles?.find((o) => o.numberPlate === value) || value
        }

        return (
          <Autocomplete
            options={data?.vehicles ?? []}
            freeSolo
            disabled={disabled}
            filterOptions={(options) => options}
            value={formattedValue || ""}
            onInputChange={(event, nextValue) => {
              if (event?.type === "change") {
                onChange(!nextValue ? null : nextValue)
                onInputChangedDelayed(event, nextValue)
              }
            }}
            onFocus={() => {
              onFieldFocus?.()
              setFocused(true)
            }}
            onBlur={() => {
              onBlur()
              onFieldBlur?.({
                controlName: field.controlName,
                value,
              })
            }}
            onChange={(_e, data) => {
              onChange(data ? data.numberPlate : null)

              setSearch("")

              if (field?.autoPopulateFields?.length) {
                const mainControlNameSplitted = field.controlName?.split(".")
                const mainControlName = mainControlNameSplitted
                  ?.slice(0, mainControlNameSplitted?.length - 1)
                  ?.join(".")

                field.autoPopulateFields.forEach((f) => {
                  const autoPopulateValue = getNestedProperty(data, f.property)

                  const fieldControlNameSplitted = f.controlName?.split(".")
                  const fieldControlName = fieldControlNameSplitted
                    ?.slice(0, fieldControlNameSplitted.length - 1)
                    ?.join(".")

                  if (fieldControlName !== mainControlName) {
                    const section = templateSubsections?.find(
                      (s) =>
                        !!s.metadatas?.find(
                          (m) => m.controlName === f.controlName,
                        ),
                    )

                    if (section && !subsectionsAdded?.includes(section.id)) {
                      onAddSubsectionClick?.(section)
                    }
                  }

                  setValue?.(f.controlName, autoPopulateValue)
                })
              }
            }}
            loading={isLoading}
            loadingText={t("loading")}
            noOptionsText={t("noOptions")}
            isOptionEqualToValue={(option, value) =>
              option?.numberPlate === value?.numberPlate ||
              option?.numberPlate === value
            }
            getOptionLabel={(option) => option?.numberPlate ?? option}
            renderOption={(props, option: IVehicle) => (
              <li {...props}>
                {`${option.numberPlate ?? ""}${
                  option.vin ? ` - ${option.vin}` : ""
                }`}
                <br />
                {`${option?.manufacturer ?? ""}${
                  option?.manufacturer && option.manufacturerType ? " - " : ""
                }${option.manufacturerType ?? ""}`}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...(params as TextFieldProps)}
                label={field.label}
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  disableUnderline: true,
                }}
                error={!!error?.message}
                helperText={error?.message}
              />
            )}
          />
        )
      }}
    />
  )
})

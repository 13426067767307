import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  styled,
  Checkbox,
  Box,
} from "@mui/material"

import { colors } from "../../../../../../utils"

export const CustomizedAccordion = styled(Accordion)`
  width: 100%;
  box-shadow: none;
  border: ${(props) =>
    props.expanded
      ? `1.5px solid ${colors.primary}`
      : `1.5px solid ${colors.gray4}`};
  min-height: "50px";
  border-radius: 10px !important;
`

interface IAccordionSummaryProps {
  accExpanded: boolean
  selectedEvents: ICrashmateEvent[]
}

interface IButtonProps {
  isSelected: boolean
}

export const CustomizedAccordionSummary = styled(
  AccordionSummary,
)<IAccordionSummaryProps>`
  overflow: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px !important;
  border-bottom-left-radius: ${(props) =>
    props.accExpanded ? "0px !important" : ""};
  border-bottom-right-radius: ${(props) =>
    props.accExpanded ? "0px !important" : ""};
  align-items: "center";
  background-color: ${(props) =>
    props.selectedEvents.length !== 0
      ? colors.blue6
      : props.accExpanded
      ? colors.gray6
      : colors.white};
  margin-bottom: ${(props) => (props.accExpanded ? 2 : 0)};
`

export const CustomizedCheckbox = styled(Checkbox)`
  margin-left: 15px;
  margin-right: 12.5px;
`

export const CustomizedAccordionDetails = styled(AccordionDetails)`
  margin-top: 12.5px;
  max-height: 295px;
  overflow: auto;
`

export const ChannelButton = styled(Box)<IButtonProps>`
  display: flex;
  align-items: center;
  height: 100%;
  max-height: -webkit-fill-available;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props: IButtonProps) =>
    props.isSelected ? colors.blue5 : colors.white2};
  border: ${(props: IButtonProps) =>
    props.isSelected
      ? `2px solid ${colors.primary}`
      : `2px dashed ${colors.primary}`};
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: ${(props: IButtonProps) =>
      !props.isSelected && colors.blue6};
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }
`

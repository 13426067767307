import { EDefinitionType } from "./constants"

export interface IFilters {
  name: string
  version: string
  status: boolean | undefined
  createdBy: string
  createdAtStart: string
  createdAtEnd: string
  updatedBy: string
  updatedAtStart: string
  updatedAtEnd: string
}

export const getDefinitionTypeName = (type: EDefinitionType) => {
  switch (type) {
    case EDefinitionType.User:
      return "userDefinitions"
    case EDefinitionType.Custom:
      return "customDefinitions"
    case EDefinitionType.System:
      return "systemDefinitions"
    default:
      return ""
  }
}

export const getFiltersCount = (filters: IFilters): number => {
  let count = 0

  if (filters.name) count++
  if (filters.version) count++
  if (filters.status !== undefined) count++
  if (filters.createdBy) count++
  if (filters.createdAtStart || filters.createdAtEnd) count++
  if (filters.updatedBy) count++
  if (filters.updatedAtStart || filters.updatedAtEnd) count++

  return count
}

import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  Paper,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import {
  ClearFiltersButton,
  CustomDatePicker,
  ElevatedPopper,
  FilterCount,
  Select,
} from "./styled"
import { FilterAlt, FilterAltOutlined } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import dayjs from "dayjs"
import { colors } from "../../../../utils"
import { blankFilters, getFiltersCount, type IFilters } from "../../utils"

interface IProps {
  filters: IFilters
  onFilterChange: (filter: IFilters) => void
}

export const FilterPopup = (props: IProps) => {
  const { filters, onFilterChange } = props
  const { t } = useTranslation()
  const theme = useTheme()
  const [openFilterPopup, setOpenFilterPopup] = useState(false)
  const [localFilters, setLocalFilters] = useState(filters)

  const isSmallerThanMD = useMediaQuery(theme.breakpoints.down("md"))
  const isGreaterThanSM = useMediaQuery(theme.breakpoints.up("sm"))
  const isSmallerThanLg = useMediaQuery(theme.breakpoints.down("lg"))

  const anchorRef = useRef<HTMLDivElement>(null)

  const filtersCount = useMemo(() => {
    return getFiltersCount(filters)
  }, [filters])

  const handleClearFilters = useCallback(() => {
    onFilterChange(blankFilters)
    setOpenFilterPopup(false)
  }, [])

  const handleOpenPopup = useCallback(() => {
    setOpenFilterPopup(true)
  }, [])

  const handleClosePopup = useCallback(() => {
    setLocalFilters(filters)
    setOpenFilterPopup(false)
  }, [filters])

  const handleUpdateFilters = useCallback(() => {
    onFilterChange(localFilters)
    setOpenFilterPopup(false)
  }, [localFilters])

  useEffect(() => {
    if (openFilterPopup) {
      setLocalFilters(filters)
    }
  }, [openFilterPopup])

  return (
    <>
      <Box display="flex" alignItems="center">
        {filtersCount > 0 && (
          <ClearFiltersButton
            variant="text"
            color="inherit"
            onClick={handleClearFilters}
          >
            {t("clearFilters")}
            <FilterCount>{filtersCount}</FilterCount>
          </ClearFiltersButton>
        )}
        <ButtonGroup ref={anchorRef}>
          <IconButton onClick={handleOpenPopup}>
            {filtersCount > 0 ? <FilterAlt /> : <FilterAltOutlined />}
          </IconButton>
        </ButtonGroup>
      </Box>
      <ElevatedPopper
        open={openFilterPopup}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        placement={isSmallerThanMD ? "bottom-start" : "left-end"}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener
            mouseEvent="onMouseUp"
            onClickAway={handleClosePopup}
          >
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: isSmallerThanLg ? "top left" : "top right",
              }}
            >
              <Paper>
                <Box
                  display="flex"
                  flexDirection="column"
                  width={isSmallerThanMD ? "70vw" : "450px"}
                >
                  <Box
                    padding="16px"
                    marginBottom="8px"
                    bgcolor={colors.secondary}
                  >
                    <Typography variant="regularBold">
                      {t("definitionFilters")}
                    </Typography>
                  </Box>
                  <Box
                    className="scroll"
                    display="flex"
                    flexDirection="column"
                    gap="8px"
                    padding="16px"
                  >
                    <TextField
                      label={t("name")}
                      value={localFilters.name}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          name: e.target.value,
                        })
                      }
                    />
                    <TextField
                      label={t("version")}
                      value={localFilters.version}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          version: e.target.value,
                        })
                      }
                    />
                    <Typography marginTop="16px" variant="small">
                      {t("status")}:
                    </Typography>
                    <Select
                      placeholder={t("status")}
                      value={
                        localFilters.status === undefined
                          ? undefined
                          : localFilters.status
                          ? "active"
                          : "inactive"
                      }
                      displayEmpty
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          status:
                            e.target.value === "active"
                              ? true
                              : e.target.value === "inactive"
                              ? false
                              : undefined,
                        })
                      }
                      renderValue={(value) => {
                        if (value === "active" || value === "inactive") {
                          return (
                            <Box
                              display="flex"
                              alignItems="center"
                              borderRadius="24px"
                              width="fit-content"
                              paddingX="8px"
                              border={`1px solid ${
                                value === "active"
                                  ? colors.primary
                                  : colors.gray1
                              } `}
                            >
                              {" "}
                              <Box
                                width="8px"
                                height="8px"
                                borderRadius="50%"
                                mr="8px"
                                border={`1px solid ${
                                  value === "active"
                                    ? colors.primary
                                    : colors.gray1
                                } `}
                                bgcolor={
                                  value === "active"
                                    ? colors.primary
                                    : colors.white
                                }
                              />
                              <Typography>
                                {value === "active"
                                  ? t("active")
                                  : t("inactive")}
                              </Typography>
                            </Box>
                          )
                        } else {
                          return (
                            <Typography color={colors.gray2} variant="large">
                              {t("selectStatus")}
                            </Typography>
                          )
                        }
                      }}
                    >
                      <MenuItem value={undefined}>
                        <i>{t("all")}</i>
                      </MenuItem>
                      <MenuItem value="active">{t("active")}</MenuItem>
                      <MenuItem value="inactive">{t("inactive")}</MenuItem>
                    </Select>
                    <Typography marginTop="16px" variant="small">
                      {t("created")}:
                    </Typography>
                    <TextField
                      label={t("createdBy")}
                      value={localFilters.createdBy}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          createdBy: e.target.value,
                        })
                      }
                    />
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        disableFuture
                        value={
                          localFilters.createdAtStart
                            ? dayjs(localFilters?.createdAtStart)
                            : null
                        }
                        onChange={(value) =>
                          setLocalFilters({
                            ...localFilters,
                            createdAtStart: (value as dayjs.Dayjs)?.toString(),
                          })
                        }
                        maxDate={
                          localFilters.createdAtEnd
                            ? dayjs(localFilters?.createdAtEnd)
                            : undefined
                        }
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          localFilters.createdAtEnd
                            ? dayjs(localFilters?.createdAtEnd)
                            : null
                        }
                        onChange={(value) => {
                          setLocalFilters({
                            ...localFilters,
                            createdAtEnd: (value as dayjs.Dayjs)?.toString(),
                          })
                        }}
                        minDate={
                          localFilters.createdAtStart
                            ? dayjs(localFilters?.createdAtStart)
                            : undefined
                        }
                        label={t("to")}
                      />
                    </Box>
                    <Typography marginTop="16px" variant="small">
                      {t("updated")}:
                    </Typography>
                    <TextField
                      label={t("updatedBy")}
                      value={localFilters.updatedBy}
                      onChange={(e) =>
                        setLocalFilters({
                          ...localFilters,
                          updatedBy: e.target.value,
                        })
                      }
                    />
                    <Box
                      display="flex"
                      flexDirection={isGreaterThanSM ? "row" : "column"}
                      gap="4px"
                    >
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        disableFuture
                        value={
                          localFilters.updatedAtStart
                            ? dayjs(localFilters?.updatedAtStart)
                            : null
                        }
                        onChange={(value) =>
                          setLocalFilters({
                            ...localFilters,
                            updatedAtStart: (value as dayjs.Dayjs)?.toString(),
                          })
                        }
                        maxDate={
                          localFilters.updatedAtEnd
                            ? dayjs(localFilters?.updatedAtEnd)
                            : undefined
                        }
                        label={t("from")}
                      />
                      <CustomDatePicker
                        format="DD.MM.YYYY"
                        value={
                          localFilters.updatedAtEnd
                            ? dayjs(localFilters?.updatedAtEnd)
                            : null
                        }
                        onChange={(value) => {
                          setLocalFilters({
                            ...localFilters,
                            updatedAtEnd: (value as dayjs.Dayjs)?.toString(),
                          })
                        }}
                        minDate={
                          localFilters.updatedAtStart
                            ? dayjs(localFilters?.updatedAtStart)
                            : undefined
                        }
                        label={t("to")}
                      />
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    flexDirection={isSmallerThanMD ? "column" : "row"}
                    justifyContent="flex-start"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    padding="16px"
                    gap="8px"
                  >
                    <Button
                      variant="text"
                      color="error"
                      onClick={handleClearFilters}
                    >
                      {t("clearFilters")}
                    </Button>
                    <Box flex={1} />
                    <Box display="flex" gap="8px">
                      <Button variant="outlined" onClick={handleClosePopup}>
                        {t("close")}
                      </Button>
                      <Button
                        disabled={
                          localFilters === filters ||
                          localFilters === blankFilters
                        }
                        onClick={handleUpdateFilters}
                      >
                        {t("update")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grow>
          </ClickAwayListener>
        )}
      </ElevatedPopper>
    </>
  )
}

import { LoadingButton } from "@mui/lab"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { uploadClaimDocumentsAPI } from "../../../../../../services"
import { useCallback, useEffect } from "react"
import { Controller, type FieldValues, useForm } from "react-hook-form"
import { DOCUMENT_TYPES, SENDER_TYPES } from "../../../../../../utils"

interface IProps {
  claimId: string
  selectedNextFile?: File
  onClose: () => void
  onFileUploaded: (newFileId: string) => void
}

export const UploadFileDialog = (props: IProps) => {
  const { claimId, selectedNextFile, onClose, onFileUploaded } = props
  const { t } = useTranslation()

  const queryClient = useQueryClient()

  const isVisible = !!selectedNextFile

  const defaultValues = {
    documentType: null,
    sender: null,
  }

  const methods = useForm({
    defaultValues,
  })

  const { control, handleSubmit, reset } = methods

  const { mutate: uploadDocument, isPending: isDocumentUploading } =
    useMutation({
      mutationFn: (requestParams: IClaimDocumentUploadRequestParams) =>
        uploadClaimDocumentsAPI(requestParams),
      onSuccess: (response) => {
        const updatedFiles =
          response
            ?.flatMap((document) => document.files)
            ?.sort(
              (a, b) =>
                new Date(b.createdAt!).getTime() -
                new Date(a.createdAt!).getTime(),
            ) ?? []

        queryClient.setQueryData(["claim-documents", claimId], updatedFiles)

        if (updatedFiles.length > 0) {
          onFileUploaded(updatedFiles[0].id)
        }
      },
      onSettled: () => {
        onClose()
      },
    })

  useEffect(() => {
    if (!isVisible) {
      reset(defaultValues)
    }
  }, [isVisible])

  const onSubmit = useCallback(
    (data: FieldValues) => {
      const formData = new FormData()

      formData.append("files", selectedNextFile!)

      uploadDocument({
        claimId,
        params: {
          documentType: data.documentType,
          sender: data.sender,
        },
        body: formData,
      })
    },
    [selectedNextFile],
  )

  return (
    <Dialog
      component="form"
      open={isVisible}
      onClose={onClose}
      onSubmit={handleSubmit(onSubmit)}
      disableEscapeKeyDown={isDocumentUploading}
      className="upload-document-dialog"
    >
      <DialogTitle>{t("uploadFile")}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap="16px" paddingTop="8px">
          <Controller
            name="documentType"
            control={control}
            rules={{ required: t("required") }}
            render={({ field, formState }) => (
              <FormControl fullWidth disabled={isDocumentUploading}>
                <InputLabel>{t("documentType")}</InputLabel>
                <Select
                  {...field}
                  value={field.value ?? ""}
                  label={t("documentType")}
                  error={!!formState.errors.documentType?.message}
                  MenuProps={{ disablePortal: true }}
                  sx={{ cursor: "pointer" }}
                >
                  {DOCUMENT_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {t(type)}
                    </MenuItem>
                  ))}
                </Select>
                {!!formState.errors.documentType?.message && (
                  <FormHelperText error>
                    {formState.errors.documentType?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />

          <Controller
            name="sender"
            control={control}
            render={({ field, formState }) => (
              <FormControl fullWidth disabled={isDocumentUploading}>
                <InputLabel>{t("sender")}</InputLabel>
                <Select
                  {...field}
                  value={field.value ?? ""}
                  label={t("sender")}
                  error={!!formState.errors.sender?.message}
                  MenuProps={{ disablePortal: true }}
                  sx={{ cursor: "pointer" }}
                >
                  {SENDER_TYPES.map((type) => (
                    <MenuItem key={type} value={type}>
                      {t(type)}
                    </MenuItem>
                  ))}
                </Select>
                {!!formState.errors.sender?.message && (
                  <FormHelperText error>
                    {formState.errors.sender?.message}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}
          disabled={isDocumentUploading}
        >
          {t("cancel")}
        </Button>
        <LoadingButton type="submit" loading={isDocumentUploading}>
          {t("upload")}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

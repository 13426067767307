import { useCallback } from "react"
import { useForm } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"
import { Box, TextField, Typography } from "@mui/material"
import { useNavigate } from "react-router-dom"
import LoadingButton from "@mui/lab/LoadingButton"
import { EMAIL_REGEX_VALIDATION, colors } from "../../utils"
import { ClickableLabel } from "../../components"
import { useAppContext } from "../../contexts"
import { ReactComponent as CrashMateLogo } from "../../assets/icons/crashmate-logo.svg"
import { LegalFooter } from "../../components/LegalFooter/LegalFooter"

export const LoginPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
  })
  const { loginMutation } = useAppContext()

  const onSubmit = useCallback(
    (data: ILoginRequestBody) => loginMutation.mutate(data),
    [],
  )

  const onForgotPasswordClick = useCallback(
    () => navigate("../forgot-password"),
    [],
  )

  const onRegisterClick = useCallback(() => navigate("../register"), [])

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <CrashMateLogo />
      <Typography marginY="8px" variant="h3">
        {t("signIn")}
      </Typography>
      <Typography variant="large" color={colors.black2}>
        {t("welcomeBack")}
      </Typography>
      <Box marginBottom="24px" />
      <TextField
        data-cy="email"
        fullWidth
        label={t("email")}
        {...register("email", {
          required: t("required"),
          pattern: {
            value: EMAIL_REGEX_VALIDATION,
            message: t("emailNotValid"),
          },
        })}
        type="email"
        error={!!errors?.email}
        helperText={errors?.email?.message}
        disabled={loginMutation.isPending}
      />
      <Box marginBottom="12px" />
      <TextField
        data-cy="password"
        fullWidth
        label={t("password")}
        {...register("password", {
          required: t("required"),
        })}
        type="password"
        error={!!errors?.password}
        helperText={errors?.password?.message}
        disabled={loginMutation.isPending}
      />
      <Box
        marginTop="12px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography>
          <ClickableLabel onClick={onForgotPasswordClick} variant="regularBold">
            {`${t("forgotPassword")}?`}
          </ClickableLabel>
        </Typography>
      </Box>
      <Box marginTop="32px" marginBottom="16px">
        <LoadingButton
          data-cy="sign-in"
          fullWidth
          type="submit"
          loading={loginMutation?.isPending}
        >
          {t("signIn")}
        </LoadingButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Typography textAlign="center" color={colors.black2}>
          <Trans
            data-cy="sign-up"
            i18nKey="newAccountPrompt"
            components={[
              <ClickableLabel
                onClick={onRegisterClick}
                key="1"
                variant="regularBold"
              />,
            ]}
          />
        </Typography>
      </Box>
      <Box display="flex" position="fixed" bottom="10px" right="10px">
        <LegalFooter />
      </Box>
    </Box>
  )
}

import { type IFilters } from "./functions"

export enum EDefinitionType {
  User,
  Custom,
  System,
}

export const blankFilters: IFilters = {
  name: "",
  version: "",
  status: undefined,
  createdBy: "",
  createdAtStart: "",
  createdAtEnd: "",
  updatedBy: "",
  updatedAtStart: "",
  updatedAtEnd: "",
}

import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { ContentWrapper, CustomDialog, SectionWrapper } from "./styled"
import DocViewer, {
  DocViewerRenderers,
  type IDocument,
} from "@cyntler/react-doc-viewer"
import { useCallback, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getWorkflowEventAPI,
  postWorkflowEventAPI,
  putSuspendWorkflowAPI,
} from "../../../../../../../../services"
import { LoadingButton } from "@mui/lab"
import { Close } from "@mui/icons-material"

interface IProps {
  open: boolean
  data: any
  onClose: () => void
}

interface IExtractedDataItem {
  key: string
  value: string
}

export const InteractionModal = (props: IProps) => {
  const { open, data, onClose } = props
  const { workflowId } = useParams()
  const { t } = useTranslation()

  const {
    data: eventData,
    isPending: isEventDataPending,
    isRefetching: isEventDataRefetching,
    refetch,
  } = useQuery({
    queryKey: ["workflow-event"],
    queryFn: () =>
      getWorkflowEventAPI({
        eventName: "event-files",
        eventKey: workflowId!,
      }),
    enabled: false,
  })

  const { mutate: sendApproval, isPending: approvalIsPending } = useMutation({
    mutationFn: () =>
      postWorkflowEventAPI({
        eventName: "event-approve-files",
        eventKey: workflowId!,
        body: {
          DocData: eventData?.eventData,
          Approval: "'approved'",
        },
      }),
    onSuccess: () => {
      void onClose()
    },
  })

  const { mutate: suspendWorkflow, isPending: isSuspendPending } = useMutation({
    mutationFn: (id: string) => putSuspendWorkflowAPI(id),
    onSuccess: () => {
      void onClose()
    },
  })

  const documents: IDocument[] = useMemo(() => {
    if (eventData) {
      return [
        {
          fileName: "file",
          fileType: "pdf",
          uri: eventData.eventData.FileUrl,
        },
      ]
    }
    return []
  }, [eventData])

  const handleApprove = useCallback(() => {
    if (eventData) {
      sendApproval()
    }
  }, [eventData])

  const handleReject = useCallback(() => {
    if (eventData) {
      suspendWorkflow(workflowId!)
    }
  }, [eventData])

  const extractedData: IExtractedDataItem[] = useMemo(() => {
    const document = eventData?.eventData.DocTypes[0]

    if (document) {
      const segment = document.Segments.find(
        (s) => s.Detail === "invoice-segment",
      )
      if (segment) {
        const firstSubSegment = segment.SubSegments[0]
        if (firstSubSegment) {
          return firstSubSegment.Entities.map((e) => ({
            key: e.Detail,
            value: e.Content,
          }))
        }
      }
    }

    return []
  }, [eventData])

  const isDataLoading = isEventDataPending || isEventDataRefetching
  const dataExists = extractedData.length > 0 || documents.length > 0
  const actionsDisabled =
    isDataLoading ||
    approvalIsPending ||
    isSuspendPending ||
    !dataExists ||
    data.status === 1 ||
    data.status === 2

  useEffect(() => {
    if (open) {
      void refetch()
    }
  }, [open])

  return (
    <CustomDialog open={open} onClose={onClose} fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {t("stepInteraction")}
          <IconButton onClick={onClose}>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {isDataLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            paddingTop="224px"
          >
            <CircularProgress />
          </Box>
        ) : dataExists ? (
          <ContentWrapper>
            <SectionWrapper>
              <Typography variant="extraLargeMedium" marginTop="12px">
                {t("extractedData")}
              </Typography>
              <Divider />
              {extractedData?.map((i, index) => (
                <Box key={index} display="flex" alignItems="center" gap="8px">
                  <TextField disabled value={i.key} />
                  <TextField disabled value={i.value} />
                </Box>
              ))}
            </SectionWrapper>
            <SectionWrapper>
              <DocViewer
                documents={documents}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: true,
                  },
                  pdfVerticalScrollByDefault: true,
                }}
              />
            </SectionWrapper>
          </ContentWrapper>
        ) : (
          <Box>{t("noData")}</Box>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton
          size="small"
          loading={isSuspendPending}
          disabled={actionsDisabled}
          color="error"
          onClick={handleReject}
        >
          {t("reject")}
        </LoadingButton>
        <LoadingButton
          size="small"
          loading={approvalIsPending}
          disabled={actionsDisabled}
          onClick={handleApprove}
        >
          {t("approve")}
        </LoadingButton>
      </DialogActions>
    </CustomDialog>
  )
}

import { Box, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { AUTHORIZED_CONTENT_MAX_WIDTH, colors } from "../../utils"
import { TableActions, TabMenu, TabPanel } from "./components"
import { useCallback, useEffect, useState } from "react"
import { type IInboxFilters, type IQuickFilters } from "./utils"

export const GlobalInboxPage = () => {
  const { t } = useTranslation()
  const savedFilters = localStorage?.getItem("globalInboxFilters")
  const parsedFilters = savedFilters ? JSON.parse(savedFilters) : null

  const [activeTab, setActiveTab] = useState("global")
  const [tablePage, setTablePage] = useState(0)
  const [quickFilters, setQuickFilters] = useState<IQuickFilters>(
    parsedFilters
      ? parsedFilters?.quickFilters
      : {
          executionType: [],
          channelIds: [],
          channelType: "",
          showMultipleMessages: false,
          search: "",
        },
  )
  const [filters, setFilters] = useState<IInboxFilters>(
    parsedFilters
      ? parsedFilters?.filters
      : {
          source: "",
          startDate: "",
          endDate: "",
          from: "",
          caseNumber: "",
          description: "",
          reasoning: "",
          workflow: "",
        },
  )

  const handleTabChange = useCallback((newTab: string) => {
    setActiveTab(newTab)
  }, [])

  useEffect(() => {
    if (tablePage !== 0) {
      setTablePage(0)
    }
  }, [filters, quickFilters])

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      bgcolor={colors.white}
      className="scroll"
    >
      <Box
        flex={1}
        display="flex"
        flexDirection="column"
        width="100%"
        maxWidth={AUTHORIZED_CONTENT_MAX_WIDTH}
        justifySelf="center"
        gap="12px"
      >
        <Box
          display="flex"
          alignItems="center"
          padding="0 24px"
          paddingTop="24px"
          gap="12px"
        >
          <Typography variant="h4" marginRight="auto">
            {t("globalInbox")}
          </Typography>
          <Button onClick={() => {}}>{t("export")}</Button>
        </Box>
        <TabMenu activeTab={activeTab} onTabChange={handleTabChange} />
        <TableActions
          quickFilters={quickFilters}
          filters={filters}
          onQuickFiltersChange={setQuickFilters}
          onFiltersChange={setFilters}
        />
        <TabPanel
          activeTab={activeTab}
          tablePage={tablePage}
          tableFilters={{ filters, quickFilters }}
          onChangeTablePage={setTablePage}
        />
      </Box>
    </Box>
  )
}

import { useTranslation } from "react-i18next"
import {
  CustomRow,
  CustomRowHeader,
  EmptyRow,
  EnhancedTable,
  EnhancedTableContainer,
  NoDataRow,
  TableCell,
} from "./styled"
import {
  Box,
  IconButton,
  Skeleton,
  TableBody,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import { AccountTreeOutlined } from "@mui/icons-material"
import { getWorkflowDefinitionsAPI } from "../../../../../../services"
import { useQuery } from "@tanstack/react-query"

export const VersionManagementTable = () => {
  const { t } = useTranslation()
  const { id, version } = useParams()
  const navigate = useNavigate()

  const [filters, setFilters] = useState({
    page: 0,
    pageSize: 10,
  })

  const { data, isPending, isRefetching, refetch } = useQuery({
    queryKey: ["definition-versions"],
    queryFn: () =>
      getWorkflowDefinitionsAPI({
        activeVersionOnly: false,
        definitionId: id ?? "",
        searchQuery: "",
        sortDesc: true,
        sortBy: "Version",
        page: filters.page + 1,
        pageSize: filters.pageSize,
      }),
    refetchOnMount: true,
    enabled: false,
  })

  const onPageChange = useCallback(
    (_: unknown, page: number) => setFilters((prev) => ({ ...prev, page })),
    [],
  )

  const onPageSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) =>
      setFilters((prev) => ({ ...prev, pageSize: +event.target.value })),
    [],
  )

  const definitions = useMemo(() => {
    if (data) return data

    return {} as any
  }, [data])

  const isDataLoading = isPending || isRefetching

  useEffect(() => {
    void refetch()
  }, [filters])

  return (
    <Box display="grid">
      <EnhancedTableContainer>
        <EnhancedTable>
          <TableHead>
            <CustomRowHeader>
              <TableCell minWidth="200px">{t("name")}</TableCell>
              <TableCell minWidth="80px" width="10%">
                {t("version")}
              </TableCell>
              <TableCell minWidth="300px">{t("description")}</TableCell>
              <TableCell minWidth="250px">{t("createdBy")}</TableCell>
              <TableCell minWidth="50px" width="10%" />
            </CustomRowHeader>
          </TableHead>
          <TableBody>
            {isDataLoading ? (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                {Array.from({ length: filters.pageSize }).map((_, index) => (
                  <React.Fragment key={index}>
                    <CustomRow>
                      {Array.from({ length: 4 }).map((_, index) => (
                        <TableCell key={index}>
                          <Skeleton sx={{ mr: "12px" }} />
                        </TableCell>
                      ))}
                      <TableCell>
                        <Skeleton
                          variant="rounded"
                          width="14px"
                          sx={{ ml: "auto" }}
                        />
                      </TableCell>
                    </CustomRow>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                  </React.Fragment>
                ))}
              </>
            ) : definitions?.totalItems === 0 ? (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                <NoDataRow>
                  <TableCell colSpan={10}>
                    <Typography>{t("noData")}</Typography>
                  </TableCell>
                </NoDataRow>
              </>
            ) : (
              <>
                <EmptyRow>
                  <TableCell colSpan={10} height="16px" />
                </EmptyRow>
                {definitions?.items?.map((definition: any, index: number) => (
                  <React.Fragment key={index}>
                    <CustomRow
                      isSelected={definition.version === +version!}
                      isActive={definition.active}
                    >
                      <TableCell>
                        {definition.name && definition.name !== ""
                          ? definition.name
                          : definition.id}
                      </TableCell>
                      <TableCell>{definition.version}</TableCell>
                      <TableCell>
                        {definition.description !== ""
                          ? definition.description
                          : "/"}
                      </TableCell>
                      <TableCell>
                        {definition.createdBy !== null
                          ? `${definition.createdBy.name || ""} ${
                              definition.createdBy.surname || ""
                            }`.trim() || "-"
                          : "-"}
                      </TableCell>
                      <TableCell align="right">
                        <Link
                          className={
                            +version! === definition.version ? "disabled" : ""
                          }
                          to={`/workflow-configurator/${definition.id}/${definition.version}`}
                        >
                          <IconButton
                            color={definition.active ? "primary" : "default"}
                            disabled={+version! === definition.version}
                            onClick={() =>
                              navigate(
                                `/workflow-configurator/${definition.id}/${definition.version}`,
                              )
                            }
                          >
                            <AccountTreeOutlined fontSize="small" />
                          </IconButton>
                        </Link>
                      </TableCell>
                    </CustomRow>
                    <EmptyRow>
                      <TableCell colSpan={10} height="16px" />
                    </EmptyRow>
                  </React.Fragment>
                ))}
              </>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                count={definitions?.totalItems ?? 0}
                page={filters.page}
                rowsPerPage={filters.pageSize}
                onPageChange={onPageChange}
                onRowsPerPageChange={onPageSizeChange}
                labelRowsPerPage={t("rowsPerPage")}
              />
            </TableRow>
          </TableFooter>
        </EnhancedTable>
      </EnhancedTableContainer>
    </Box>
  )
}
